import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pacote } from 'src/app/models/pacote';
import { Produto } from 'src/app/models/produto';
import { HelperService, ResponseListWithPagination } from 'src/app/_services/helpers/helper.service';
import { FiltrosListagemProdutos, ProdutosService } from 'src/app/_services/produtos/produtos.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-produtos-list-edicao-massa',
  templateUrl: './produtos-list-edicao-massa.component.html',
  styleUrls: ['./produtos-list-edicao-massa.component.scss']
})
export class ProdutosListEdicaoMassaComponent implements OnInit {
  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 20;
  from: number = 0;
  to: number = this.qntPorPagina;
  ultimaPagina: number;

  qntTotalRegistros: number = this.qntPorPagina;

  // end Paginação

  pacotes: Pacote[];
  dataSourceProdutos: Array<Produto> = [];
  produtosAdicionar: Array<Produto> = [];
  idsParaAlterar: Array<number> = [];
  pesquisaGeral = '';
  categoria = '';
  filtroSituacao = 'all';
  isLoggedIn: boolean = false;
  loading = false;
  widthTela = document.body.offsetWidth;

  errorMessage: string = '';
  showErrorMessage: boolean = false;
  successMessage: string = '';
  showSuccessMessage: boolean = false;


  filtros: FiltrosListagemProdutos = {
    pesquisaGeral: this.pesquisaGeral,
    categoria: this.categoria,
  };

  tableSettings: any = {
    viewportColumnRenderingOffset: 27,
    viewportRowRenderingOffset: "auto",
    width: '100%',
    rowHeights: '50px',
    manualColumnResize: true,
    stretchH: 'all',
    // hiddenColumns: {
    //   columns: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    //   indicators: true
    // },
    columns: [
      {
        data: 'id',
        type: 'numeric',
        className: 'htMiddle',
        readOnly: true
      },
      {
        data: 'sku',
        type: 'text',
        className: 'htMiddle',
        readOnly: true
      },
      {
        data: 'titulo',
        type: 'text',
        className: 'htMiddle'
      },
      {
        data: 'peso',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'estoque',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'precoBase',
        type: 'numeric',
        className: 'htMiddle',
        numericFormat: {
          pattern: '0.00'
        }
      },
      {
        data: 'ean',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'ncm',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'categoria',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'referencia',
        type: 'text',
        className: 'htMiddle'
      },
      {
        data: 'descricao',
        type: 'text',
        className: 'htMiddle',
        width: '500px',

      },
      {
        data: 'custo',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'largura',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'altura',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'comprimento',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'composto',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'cfop',
        type: 'numeric',
        className: 'htMiddle'
      },
      {
        data: 'cest',
        type: 'text',
        className: 'htMiddle'
      },
      {
        data: 'unidadeComercial',
        type: 'text',
        className: 'htMiddle'
      },
      {
        data: 'tempoProducao',
        type: 'numeric',
        className: 'htMiddle'
      }
    ],
    colHeaders: ["Id", "SKU", "Nome", "Peso", "Estoque", "Preço", "EAN", "NCM", "Categoria", "Referência", "Descrição", "Custo", "Largura", "Altura", "Comprimento", "Composto", "CFOP", "Cest", "Unidade Comercial", "Tempo Producao"],
    // manualRowMove: true,
    // contextMenu: true,
    // filters: true,
    dropdownMenu: true,
    afterValidate: function (isValid, value, row, prop) {
      if (value == false) {
        console.log(value, row, prop)
        alert("Invalid")
      }

    }

  };

  constructor(public datepipe: DatePipe,
    private helperService: HelperService,
    private produtosService: ProdutosService,
    private router: Router,
    private tokenStorageService: TokenStorageService) { }


  ngOnChange() {
    this.escondeLongeActivePage();
  }

  ngAfterViewChecked() {
    this.escondeLongeActivePage();
  }

  ngOnInit() {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (!this.isLoggedIn) {
      this.router.navigate(['login']);
    }

    this.buscaPacotes();
  }

  fechaMensagemErro() {
    this.errorMessage = "";
    this.showErrorMessage = false;
  }

  fechaMensagemSuccess() {
    this.successMessage = "";
    this.showSuccessMessage = false;
  }
  
  // Paginação
  escondeLongeActivePage() {
    for (var i = 1; i <= this.ultimaPagina; i++) {
      var aux = document.getElementById(i.toString());
      if (aux != null) {
        if (i < this.activePage - 5 || i > this.activePage + 5) {
          aux.classList.add("hide");
        } else {
          aux.classList.add("show");
          aux.classList.remove("hide");
        }
      }
    }
  }

  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    this.buscaPacotes();

  }

  displayPedidosPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
  }
  // end Paginação

  detectChanges = (hotInstance, changes, source) => {
    if (changes == "edit" || changes == "Autofill.fill") {
      for (let i = 0; i < hotInstance.length; i++) {
        let edicao = hotInstance[i];
        let id = edicao[0];
        for (let j = 0; j < this.dataSourceProdutos.length; j++) {
          if (this.dataSourceProdutos[j].id == id) {
            console.log(this.dataSourceProdutos[j]);
            this.dataSourceProdutos[j].editado = true;

          }
        }
      }
    }
  };

  buscaComFiltros() {
    this.loading = true;
    var sku = this.filtros.pesquisaGeral;
    this.produtosService.getProdutoBySKU(sku).subscribe((produto: any) => {
      this.dataSourceProdutos.length = 0;
      this.dataSourceProdutos = produto;
     
      if(this.dataSourceProdutos.length == 0) {
        this.errorMessage = "Nenhum produto encontrado.";
        this.showErrorMessage = true;
        this.qntTotalRegistros = this.dataSourceProdutos.length;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
      } else {
        this.qntTotalRegistros = 1;
        this.from = 0;
        this.to = 1;
        this.activePage = 1;
        this.ultimaPagina = 1;
      }
      this.loading = false;
      console.log(this.dataSourceProdutos);
    }),

      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'

      }
  }

  buscaPacotes() {
    this.loading = true;
    this.produtosService.getProdutosList(this.filtros, this.qntPorPagina, this.activePage).subscribe((pacotesTotal: ResponseListWithPagination) => {
      this.dataSourceProdutos = pacotesTotal.data;
      this.qntTotalRegistros = pacotesTotal.meta.total;
      this.from = pacotesTotal.meta.from;
      this.to = pacotesTotal.meta.to;
      this.ultimaPagina = pacotesTotal.meta.last_page;
      localStorage.setItem('recordsPerPage', this.qntPorPagina.toString());
      this.loading = false;

    }),

      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.';

      }
  }

  salvarAlteracoes() {
    this.loading = true;
    
    for (let i = 0; i < this.dataSourceProdutos.length; i++) {
      if(this.dataSourceProdutos[i].editado == true){
        this.produtosService.salvaProduto(this.dataSourceProdutos[i]).subscribe((pacotesTotal: Produto) => {
          
        }),
    
          (error) => {
            this.loading = false;
            let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.';
    
          }
      }
    }
    this.loading = false;

  }

}
