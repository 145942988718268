import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {
  senhasBatem: boolean;

  Roles = ['Administrador', 'Gerenciador', 'Vendedor'];

  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  mensagemRetorno = '';
  isLoggedIn: false;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.form.senha == this.form.confirmaSenha) {
      
      this.senhasBatem = true;
      this.realizarCadastro();
    } else {
      this.senhasBatem = false;
      this.mensagemRetorno = "As senhas devem ser iguais!";
    }
    
  }

  realizarCadastro() {
    this.authService.register(this.form).subscribe(
      data => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;
      },
      err => {
        this.mensagemRetorno = err.error.message;
        this.isSignUpFailed = true;
      }
    );
  }

}
