<div class="mat-elevation-z8 boxContainer" id="containerTodo">

    <!-- Filtros Listagem -->
    <ng-container>
        <form name="filtros" class="filtros">
            <mat-card-content class="boxInputs" fxLayout="row">
                <!-- <mat-form-field appearance="outline" fxFlex="30"> -->
                <mat-form-field appearance="outline" style='max-width: 30%;'>
                    <mat-label>Sku</mat-label>
                    <input matInput type="text" class="form-control" name="pesquisa" [(ngModel)]="sku" minlength="3"
                        #pesquisa="ngModel">
                </mat-form-field>
                <mat-form-field style='padding-left: 10px;width: 10%;'>
                    <mat-select [ngModelOptions]="{standalone: true}" [(selected)]="tipo" [(ngModel)]="tipo"
                        class="mdb-select md-form colorful-select dropdown-danger">
                        <mat-option *ngFor="let tipo of tiposDisponiveis" [value]="tipo">
                            {{tipo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style='padding-left: 10px;width: 10%;'>
                    <mat-select [ngModelOptions]="{standalone: true}" [(selected)]="estado" [(ngModel)]="estado"
                        class="mdb-select md-form colorful-select dropdown-danger">
                        <mat-option *ngFor="let estado of estadosDisponiveis" [value]="estado">
                            {{estado}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="padding-left: 10px;width:10%;">
                    <input matInput [matDatepicker]="dataInicio" placeholder="Data Inicial" name="C"
                        [(ngModel)]="dataInicial" required #dataInicio="ngModel">
                    <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
                    <mat-datepicker #dataInicio></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="padding-left: 10px;width:10%;">
                    <input matInput [matDatepicker]="dataFim" placeholder="Data Final" name="dataFim"
                        [(ngModel)]="dataFinal" required #dataFim="ngModel">
                    <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
                    <mat-datepicker #dataFim></mat-datepicker>
                </mat-form-field>
                <mat-form-field style='padding-left: 10px;width: 10%;'>
                    <mat-select [ngModelOptions]="{standalone: true}" [(selected)]="ordem" [(ngModel)]="ordem"
                        class="mdb-select md-form colorful-select dropdown-danger">
                        <mat-option *ngFor="let ordem of ordensDisponiveis" [value]="ordem">
                            {{ordem}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style='padding-left: 10px;width: 10%;'>
                    <mat-label>Marketplaces</mat-label>
                    <mat-select [ngModelOptions]="{standalone: true}" [(selected)]="marketplace"
                        [(ngModel)]="marketplace">
                        <mat-option *ngFor="let mkp of marketplacesDisponiveis" [value]="mkp.id">{{mkp.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button mat-stroked-button class="btn-block btnPadrao" (click)="getQuestions(true)">Filtrar</button>
            </mat-card-content>
        </form>
    </ng-container>
    <!-- Final filtros -->

    <div *ngIf="tipoAtual == 1 && preSalequestions && preSalequestions.length == 0">
        <p style="text-align: center;">Nao encontramos nenhuma pergunta pré venda com os filtros especificados.</p>
    </div>
    <div *ngIf="tipoAtual == 2 && posSalequestions && posSalequestions.length == 0">
        <p style="text-align: center;">Nao encontramos nenhuma pergunta pós venda com os filtros especificados.</p>
    </div>

    <mat-card [attr.id]="'question_pos-' + j" *ngFor="let posQuestion of posSalequestions; let j= index"
        class="boxPedido">
        <mat-card-header class="headerPedido">
            <mat-card-title>
                <p style="margin-top: 2px;">{{posQuestion.Id}} - Pos venda</p>
            </mat-card-title>
            <span class="spacer">
            </span>
        </mat-card-header>
        <mat-card-content style="display: flex;">
            <div class="sacInternalDivContentCard" style="width:30%;">
                <p class="PdadosPergunta">{{ posQuestion.CustomerNick }} - {{posQuestion.CustomerInfo }}</p>
                <p class="PdadosPergunta">{{ posQuestion.Marketplace}}</p>
                <p class="PdadosPergunta" style="cursor: pointer;"><a href="{{ posQuestion.LinkToMarketplace}}"
                        target="_blank">
                        Venda marketplace - {{ posQuestion.SaleCode }}</a></p>
                <p class="PdadosPergunta" style="cursor: pointer;"><a href="{{ posQuestion.LinkToDetails}}"
                        target="_blank">
                        Detalhes da venda</a></p>
                <p class="PdadosPergunta">Primeiro contato - {{ posQuestion.FirstContact }}</p>
                <p class="PdadosPergunta">Ultimo contato - {{ posQuestion.LastContact }}</p>
            </div>
            <div class="sacInternalDivContentCard" style="width:70%;
            overflow-y: auto;display:flex; flex-direction:column-reverse;">
                <div class="chat">

                    <div *ngFor="let posQuestionConversation of posQuestion.Conversations;  let cIndex = index">
                        <div *ngIf="!(cIndex == posQuestion.Conversations.length - 1) && posQuestionConversation.SentByCustomer"
                            class="bubble me">
                            {{ posQuestion.CustomerNick }}: <br>
                            {{ posQuestionConversation.Text }}</div>
                        <div *ngIf="!(cIndex == posQuestion.Conversations.length - 1) && !posQuestionConversation.SentByCustomer"
                            class="bubble you">
                            {{ posQuestion.Marketplace }}:
                            <br> {{ posQuestionConversation.Text }}
                            <mat-icon *ngIf="posQuestionConversation.ConfirmedDelivery" style="float: right;"
                                title="Entrega confirmada">check_circle</mat-icon>
                            <mat-icon *ngIf="!posQuestionConversation.ConfirmedDelivery" style="float: right;"
                                title="Entrega não confirmada">check_circle_outline</mat-icon>
                        </div>

                        <div @fade
                            *ngIf="(cIndex == posQuestion.Conversations.length - 1) && posQuestionConversation.SentByCustomer"
                            class="bubble me">
                            {{ posQuestion.CustomerNick }}: <br>
                            {{ posQuestionConversation.Text }}
                        </div>
                        <div @fade
                            *ngIf="(cIndex == posQuestion.Conversations.length - 1) && !posQuestionConversation.SentByCustomer"
                            class="bubble you">
                            {{ posQuestion.Marketplace }}:
                            <br> {{ posQuestionConversation.Text }}
                            <mat-icon *ngIf="posQuestionConversation.ConfirmedDelivery" style="float: right;"
                                title="Entrega confirmada">check_circle</mat-icon>
                            <mat-icon *ngIf="!posQuestionConversation.ConfirmedDelivery" style="float: right;"
                                title="Entrega não confirmada">check_circle_outline</mat-icon>
                        </div>

                    </div>
                    <div class="bubble you" style="height: 120px">
                        <mat-form-field style="width: 99%; height: 100px;max-height: 105px;">
                            <textarea style="height: 65px;max-height: 70px;overflow: auto; margin-top: -10px;"
                                maxlength="2000" [disabled]="!posQuestion.Enabled" matInput
                                [(ngModel)]="posQuestion.V1AnsweredText" #resposta="ngModel" name="resposta"
                                (keydown.enter)="sendResponse(posQuestion)"></textarea>
                            <button mat-icon-button class="btn-block btnEnviar" [disabled]="!posQuestion.Enabled"
                                (click)="sendResponse(posQuestion)">
                                <mat-icon style="line-height: 15px; cursor: pointer;">send</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card [attr.id]="'question-' + i" *ngFor="let question of preSalequestions; let i= index" class="boxPedido">
        <mat-card-header class="headerPedido">
            <mat-card-title>
                <p style="margin-top: 2px;">{{question.Id}} - Pre venda</p>
            </mat-card-title>
            <span class="spacer">
            </span>
        </mat-card-header>
        <mat-card-content style="display: flex;">
            <div class="sacInternalDivContentCard" style="width:40%;">
                <div class="boxProduto">
                    <div data-toggle="tooltip" data-placement="top" title="Imagem">
                        <img src="{{ question.ProductImage }}" class="imgProduct">
                    </div>
                </div>
                <p class="PdadosPergunta">{{ question.Marketplace}} - {{ question.Question.DataCreated }}</p>
                <p class="PdadosPergunta" style="cursor: pointer;"><a href="{{ question.AdvertisingLink}}"
                        target="_blank">
                        {{ question.Sku }} - {{question.ProductName }}</a></p>
                <p class="PdadosPergunta">Estoque atual: {{question.ProductStock}}</p>
                <p class="PdadosPergunta">Localizacao cliente: {{question.CustomerInfo}}</p>
            </div>
            <!-- <div class="sacInternalDivContentCard" style="width:70%;"> -->
            <div class="sacInternalDivContentCard">

                <div class="chat">
                    <div class="bubble me"><button mat-icon-button class="btn-block btnEnviar" style="margin-top: 10px;"
                            [disabled]="question.Answered" (click)="deletePreSaleQuestion(question)">
                            <mat-icon style="line-height: 15px;">delete</mat-icon>
                        </button>{{ question.CustomerNick }}: <br> {{ question.Question.Text }}</div>
                    <div class="bubble you" style="height: 120px">
                        <mat-form-field style="width: 99%; height: 100px;max-height: 105px;">
                            <textarea style="height: 65px;max-height: 70px;overflow: auto; margin-top: -10px;"
                                maxlength="2000" [disabled]="question.Answered" matInput
                                [(ngModel)]="question.V1AnsweredText" #resposta="ngModel" name="resposta"
                                (keydown.enter)="sendResponse(question)"></textarea>
                            <button mat-icon-button class="btn-block btnEnviar" [disabled]="question.Answered"
                                (click)="sendResponse(question)">
                                <mat-icon style="line-height: 15px;">send</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <!-- Paginator -->
    <!-- <div *ngIf="preSalequestions.length > 0 || posSalequestions.length > 0" class="row"> -->
    <div class="row">
        <div class="paginator">
            <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
                (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPage($event)">
            </app-pagination>
        </div>
    </div>

    <!-- Loading Spinner -->
    <div class="loading" *ngIf="loading">
        <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
    </div>

</div>

<!-- Mensagem Erro -->
<mat-container style="z-index: 99999999;" *ngIf="showErrorMessage"
    class="alert alert-danger alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ errorMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemErro()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>

<!-- Mensagem Sucesso -->
<mat-container style="z-index: 99999999;" *ngIf="showSuccessMessage"
    class="alert alert-success alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ successMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>