import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './../../_helpers/format-datepicker';;
import { PacotesService, FiltrosListagemDashboard, TreeNode, PacoteProdutoTabela } from '../../_services/pedidos/pacotes.service';
import { PacoteAdmin, TotalGeral, TotalPorPagina } from '../../models/pacote-admin';
import { ResponseListWithPagination, ResponseListWithPaginationDashboard } from '../../_services/helpers/helper.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

import { PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class DashboardComponent implements OnInit {
  public loading = false;
  public isLoggedIn: boolean = false;

  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 5;
  from: number = 0;
  to: number = this.qntPorPagina;
  ultimaPagina: number;
  qntTotalRegistros: number = this.qntPorPagina;

  displayActivePage(activePageNumber: number) {
    document.getElementById("containerTodo").scroll(0, 0);
    this.activePage = activePageNumber;
    this.buscaPacotes();
  }

  displayPedidosPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.buscaPacotes();
  }
  // end Paginação

  // Filtros
  public dataFim = new Date();
  public dataInicio = new Date();
  public pesquisaGeral = '';
  public vinculoCancelamento = 'todos';
  public filtroSituacao = 'all';

  public entrega = 'todos';

  public filtros: FiltrosListagemDashboard = {
    pesquisaGeral: this.pesquisaGeral,
    situacao: this.filtroSituacao,
    vinculoCancelamento: this.vinculoCancelamento,
    entrega: this.entrega,
    mkp: [],
    periodo: [this.dataInicio, this.dataFim],
    dataDe: null,
    outros: [],
    ordenacao: null
  };

  public situacoes = [
    { nome: 'Todas as Situações', value: 'all' },
    { nome: 'Aguardando Pagamento', value: 'aguardandopagamento' },
    { nome: 'Aguardando Faturamento', value: 'pago' },
    { nome: 'Aguardando Impressão de Etiqueta', value: 'faturado' },
    { nome: 'Aguardando Postagem', value: 'expedido' },
    { nome: 'Em Trânsito', value: 'emtransito' },
    { nome: 'Entregue', value: 'entregue' },
    { nome: 'Aguardando Cancelamento', value: 'aguardandocancelamento' },
    { nome: 'Cancelado', value: 'cancelado' },
  ];

  public entregas = [
    { nome: 'Todos', value: 'todos' },
    { nome: 'Fulfillment', value: 'fulfillment' },
    { nome: 'Todos exceto fulfillment', value: 'todosexcetoful' }
  ]


  // Totais
  public totalGeral: TotalGeral;
  public totalPorPagina: TotalPorPagina;

  //Listagem
  public pacotes: PacoteAdmin[];
  public placeholders = [];
  public selecionados = 0;

  constructor(
    private pacotesService: PacotesService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    public datepipe: DatePipe

  ) {

  }

  ngOnChange() {
    this.escondeLongeActivePage();
  }

  ngAfterViewChecked() {
    this.escondeLongeActivePage();
  }

  ngOnInit() {
    let refreshPage = localStorage.getItem('refreshPage');

    if (refreshPage == null || refreshPage == "false") {
      localStorage.setItem('refreshPage', "true");
      window.location.reload();
    }


    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.dataInicio.setDate(this.dataInicio.getDate() - 7);

    if (!this.isLoggedIn) {
      this.router.navigate(['login']);
    }

  }

  buscaComFiltros() {
    this.buscaPacotes();
  }

  public buscaPacotes() {
    this.loading = true;
    this.pacotesService.getPacotesDashboard(this.filtros, this.qntPorPagina, this.activePage).subscribe((pacotesTotal: ResponseListWithPaginationDashboard) => {
      this.pacotes = pacotesTotal.data;
      this.totalGeral = pacotesTotal.totalGeral;
      this.totalPorPagina = pacotesTotal.totalPorPagina;

      this.qntTotalRegistros = pacotesTotal.meta.total;
      this.from = pacotesTotal.meta.from;
      this.to = pacotesTotal.meta.to;
      this.ultimaPagina = pacotesTotal.meta.last_page;
      localStorage.setItem('recordsPerPage', this.qntPorPagina.toString());

      this.escondeLongeActivePage();

      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'

      }
  }

  public filtrar() {
    this.loading = true;

    this.filtros.periodo[0] = this.dataInicio;
    this.filtros.periodo[1] = this.dataFim;
    //this.filtros.pesquisaGeral = this.helperService.removeAcentos(this.pesquisaGeral);
    this.filtros.situacao = this.filtroSituacao;

    this.buscaPacotes();
  }

  // Paginação
  private escondeLongeActivePage() {
    var maximo = Math.ceil(this.qntTotalRegistros / this.qntPorPagina);
    for (var i = 1; i <= maximo; i++) {
      var aux = document.getElementById(i.toString());
      if (aux != null) {
        if (i < this.activePage - 5 || i > this.activePage + 5) {
          aux.classList.add("hide");
        } else {
          aux.classList.add("show");
          aux.classList.remove("hide");
        }
      }
    }
  }
  // end Paginação

}
