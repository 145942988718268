import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigMasterEnvironment } from '../../../environments/config.master.environment';

import { Pacote, PacoteSituacaoCodigo } from '../../models/pacote';
import { PacoteProduto } from '../../models/pacote-produto';
import { Produto } from '../../models/produto';
import { ProdutosService } from '../produtos/produtos.service';
import { HelperService, ResponseListWithPagination, ResponseList, ResponseListWithPaginationDashboard } from '../helpers/helper.service';
import { PacoteAdmin } from '../../models/pacote-admin';
import { DatePipe } from '@angular/common';
import { Marketplace } from 'src/app/models/marketplace';
import { NotaFiscal } from 'src/app/models/nota-fiscal';
import { PacoteObservacao } from 'src/app/models/pacote-observacao';

export class FiltroOutros {
  atrasados: boolean;
  impressos: boolean;
  constructor() {
    this.atrasados = this.impressos = false;
  }
}

export interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

export interface PacoteProdutoTabela {
  sku: string;
  imagem: string;
  titulo: string;
  quantidade: number;
  venda: number;
}

export interface FiltrosListagemPacotes {
  pesquisaGeral: string,
  situacao: string,
  periodo: string
}

export interface FiltrosListagemDashboard {
  pesquisaGeral: string,
  situacao: string,
  vinculoCancelamento: string,
  entrega: string,
  mkp: string[],
  periodo: any[],
  dataDe: any,
  outros: any,
  ordenacao: any
}

@Injectable({
  providedIn: 'root'
})
export class PacotesService {

  public apiHost: string = ConfigMasterEnvironment.environment.baseUrlApi;
  private endpointPedidos: string = 'pedido';
  private endpointPacoteObservacao: string = 'pacoteObservacao';
  private endpointPacoteObservacaoSave: string = 'save';
  private endpointEtiquetas: string = 'etiqueta';
  private endpointEtiquetasOld: string = 'etiquetaOld';
  private endpointCancelar: string = 'cancelar';
  private endpointAlteraSituacao: string = 'alterarSituacao';
  private endpointProdutosPedido: string = 'produtos';
  private endpointMarcarImpresso: string = 'impresso';
  private endpointReimprimirEtiqueta: string = 'reimprimirEtiqueta';

  constructor(
    private http: HttpClient,
    private produtosService: ProdutosService,
    private helperService: HelperService,
    public datepipe: DatePipe
  ) {
  }

  public getPacotes(filtros: FiltrosListagemPacotes, pageSize: number, offset: number): Observable<ResponseListWithPagination> {
    let queryString = '?_offset=' + offset + '&_limit=' + pageSize;
    queryString += (filtros.pesquisaGeral !== "") ? '&coringa=' + filtros.pesquisaGeral : '';
    queryString += (filtros.periodo !== "all") ? '&periodo=' + filtros.periodo : '';
    queryString += (filtros.situacao !== "all") ? '&situacao=' + filtros.situacao : '';

    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + queryString).subscribe((pacotesArray: ResponseListWithPagination) => {
        if (pacotesArray.data.length == 0) {
          observable.next(pacotesArray);
        }

        this.convertArrayInPacoteList(pacotesArray.data).subscribe((pacotes: Pacote[]) => {
          pacotesArray.data = pacotes;
          observable.next(pacotesArray);
        });

      }), ((error) => {
        console.log(error);
      });
    });
  }

  public getPacotesDashboard(filtros: FiltrosListagemDashboard, pageSize: number, paginaAtual: number): Observable<ResponseListWithPaginationDashboard> {
    let queryString = '?limit=' + pageSize;
    queryString += '&page=' + paginaAtual;
    queryString += (filtros.pesquisaGeral !== "") ? '&todos=' + filtros.pesquisaGeral : '';

    queryString += '&dataAprovacao[start]=' + this.datepipe.transform(filtros.periodo[0], 'yyyy/MM/dd') + '&dataAprovacao[end]=' + this.datepipe.transform(filtros.periodo[1], 'yyyy/MM/dd');
    queryString += (filtros.situacao !== "all") ? '&status=' + filtros.situacao : '' + '&entrega=' + filtros.entrega;

    console.log(this.apiHost + 'admin/dashboard' + queryString);
    return new Observable((observable) => {
      this.http.get(this.apiHost + 'admin/view/dashboard' + queryString).subscribe((pacotesArray: any) => {
        let pacotesResponse = new ResponseListWithPaginationDashboard(pacotesArray['data'], pacotesArray['meta'], pacotesArray['links'], pacotesArray['totalGeral'], pacotesArray['totalPorPagina']);
        if (pacotesResponse.data.length == 0) {
          observable.next(pacotesResponse);
        }
        this.convertArrayInPacoteAdminList(pacotesResponse.data).subscribe((pacotes: PacoteAdmin[]) => {
          pacotesResponse.data = pacotes;
          observable.next(pacotesResponse);
        });

      }), ((error) => {
        console.log(error);
      });
    });
  }

  public getPacotesPedidosList(filtros: FiltrosListagemDashboard, pageSize: number, paginaAtual: number): Observable<ResponseListWithPagination> {
    //é setado como default esse valor, porem como tem os filtros salvos em cookies no navegador
    //nao quero que tranque o usuario quando ele abrir a tela depois da atualizacao
    if (!filtros.dataDe) {
      filtros.dataDe = 'dataAprovacao';
    }

    let queryString = '?limit=' + pageSize;
    queryString += '&page=' + paginaAtual;
    if (filtros.outros.observacoes == true) {
      queryString += '&pesquisaObservacoes=' + filtros.pesquisaGeral;
    } else {
      queryString += (filtros.pesquisaGeral !== "") ? '&todos=' + filtros.pesquisaGeral : '';
    }
    queryString += '&' + filtros.dataDe + '[start]=' + this.datepipe.transform(filtros.periodo[0], 'yyyy/MM/dd') + '&' + filtros.dataDe + '[end]=' + this.datepipe.transform(filtros.periodo[1], 'yyyy/MM/dd');
    queryString += (filtros.situacao !== "all") ? '&status=' + filtros.situacao : '';
    queryString += (filtros.ordenacao == "maisrecentes") ? '&orderBy' + filtros.dataDe + '=desc' : '&orderBy' + filtros.dataDe + '=asc';
    queryString += '&dataEtiquetaLiberada=' + filtros.entrega;
    queryString += '&vinculoCancelamento=' + filtros.vinculoCancelamento;

    if (filtros.mkp.length > 0) {
      let aux = '&idMarketplace=';
      for (let i = 0; i < filtros.mkp.length; i++) {
        if (i < filtros.mkp.length - 1)
          aux += filtros.mkp[i] + ",";
        else
          aux += filtros.mkp[i];
      }
      queryString += aux;
    }
    queryString += (filtros.outros.atrasados) ? '&atrasados=true' : '';
    queryString += (filtros.outros.impressos) ? '&impressos=true' : '';
    queryString += (filtros.outros.naoImpressos) ? '&impressos=false' : '';

    console.log(queryString);

    return new Observable((observable) => {
      this.http.get(this.apiHost + 'view/pedido' + queryString).subscribe((pacotesArray: any) => {
        let pacotesResponse = new ResponseListWithPagination(pacotesArray['data'], pacotesArray['meta'], pacotesArray['links']);
        if (pacotesResponse.data.length == 0) {
          observable.next(pacotesResponse);
        }
        this.convertArrayInPacoteAdminList(pacotesResponse.data).subscribe((pacotes: PacoteAdmin[]) => {
          pacotesResponse.data = pacotes;
          observable.next(pacotesResponse);
        });

      }), ((error) => {
        console.log(error);
      });
    });
  }

  public getOnePacote(idPacote: string): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/' + idPacote).subscribe((pacoteArray: any) => {
        let listaTemporariaPacotes = [pacoteArray];
        this.convertArrayInPacoteList(listaTemporariaPacotes).subscribe((pacotes: any[]) => {
          observable.next(pacotes[0]);
        });
      },
        (error: any) => {
          console.log(error);
        })
    });
  }

  public getMarketplaces(): Observable<Marketplace[]> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + '/marketplace').subscribe((marketplaces: Marketplace[]) => {
        observable.next(marketplaces);
      },
        (error: any) => {
          console.log(error);
        })
    });
  }

  public getPedidoDetalhes(idPacote: string): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/' + idPacote).subscribe((pacoteArray: any) => {
        let listaTemporariaPacotes = [pacoteArray];
        this.convertArrayInPacoteDetalhe(listaTemporariaPacotes).subscribe((pacotes: any[]) => {
          observable.next(pacotes[0]);
        });
      },
        (error: any) => {
          console.log(error);
        })
    });
  }

  public getPacoteObservacao(idPacote: string): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPacoteObservacao + '/' + idPacote).subscribe((pacoteObservacao: any) => {
        observable.next(pacoteObservacao);
      },
        (error: any) => {
          console.log(error);
        })
    });
  }

  public savePacoteObservacao(observacao: PacoteObservacao): Observable<any> {
    return new Observable((observable) => {
      // var headers = new HttpHeaders();
      // headers.append("Access-Control-Allow-Origin", "*");
      // headers.append("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS");
      // headers.append("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With");


      this.http.post(this.apiHost + this.endpointPacoteObservacao + '/' + this.endpointPacoteObservacaoSave, observacao).subscribe((result: any) => {
        observable.next(result);
      },
        (error: any) => {
          console.log(error);
        })
    });
  }

  public getProdutosPacote(idPacote: number): Observable<Produto[]> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/' + idPacote + '/' + this.endpointProdutosPedido).subscribe((produtosPacoteArray: any) => {

        if (produtosPacoteArray != null) {
          let produtos: Produto[] = [];

          produtosPacoteArray.produtos.forEach((produtoArray, posicao) => {

            if (produtoArray.sinonimos && produtoArray.sinonimos.length > 0) {
              produtoArray.sinonimos.forEach((sinonimo) => {
                let produto = this.produtosService.convertObjectSinonimoInProdutoModel(produtoArray, sinonimo);
                produtos.push(produto);
              });
            }
            else {
              let produto = produtoArray;
              produtos.push(produto);
            }

            if (this.helperService.chegouNaUltimaPosicao(produtosPacoteArray.produtos, posicao)) {
              observable.next(produtos);
            }
          });

        }
        else {
          observable.error(null);
        }
      });
    });
  }

  public getNotaPacote(idPacote: string): Observable<NotaFiscal> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/' + idPacote + '/nota').subscribe((notaArray: any) => {
        observable.next(notaArray);
      },
        (error: any) => {
          observable.next(error);
        })
    });
  }

  public GerarCodBarras(idsPacotes: number[]): any {
    return new Observable((observable) => {
      this.http.post(this.apiHost + this.endpointPedidos + '/GerarCodBarras', idsPacotes).subscribe((result: any) => {
        observable.next(result);
      },
        (error: any) => {
          console.log('error: ', error);
          observable.next('');
        })
    });
  }

  public gerarDanfeEEtiqueta(idsPacotes: string[]): Observable<any> {
    return new Observable((observable) => {
      this.http.post(this.apiHost + this.endpointPedidos + '/GerarDanfeEtiqueta', idsPacotes).subscribe((result: any) => {
        observable.next(result);
      },
        (error: any) => {
          observable.next(error);
        })
    });
  }

  public buscarInfoLote(idPacote: string): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/buscarInfoLote?IdPacote=' + idPacote).subscribe((result: any) => {
        observable.next(result);
      },
        (error: any) => {
          observable.next(error);
        })
    });
  }

  public reenviarNFMarketplace(idPacote: string): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/reenviarNFMarketplace?IdPacote=' + idPacote).subscribe((result: any) => {
        observable.next(result);
      },
        (error: any) => {
          observable.next(error);
        })
    });
  }

  // public solicitarImpressaoDanfeEtiqueta(reportRequest: any): Observable<any> {
  //   return new Observable((observable) => {

  //     //aqui precisei adicionar um header especifico para as chamadas ao interop, pois tem um interceptor de todas
  //     //as request saidas do front que adiciona um header de apitoken, que é bloqueado quando chega
  //     //na minha aplicacao
  //     this.http.post(this.apiInterop + 'GenerateBatchReportAsync', reportRequest, { headers: { 'interop': '' } }).subscribe((result: any) => {
  //       observable.next(result);
  //     },
  //       (error: any) => {
  //         console.log(error);
  //         observable.next(error);
  //       })
  //   });
  // }

  public getEtiquetasPacotes(idsPacotes: number[]): Observable<any> {
    return this.http.get(this.apiHost + this.endpointPedidos + '/' + idsPacotes.join() + '/' + this.endpointEtiquetas);
  }

  public getEtiquetasPacotesOld(idsPacotes: number[]): Observable<any> {
    return this.http.get(this.apiHost + this.endpointPedidos + '/' + idsPacotes.join() + '/' + this.endpointEtiquetasOld);
  }

  public alteraSituacaoPacote(idPacote: number, situacaoAAlterar: PacoteSituacaoCodigo): Observable<any> {
    let situacao = {
      'situacao': situacaoAAlterar
    };

    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointPedidos + '/' + idPacote + '/' + this.endpointAlteraSituacao, situacao).subscribe((responsePacoteAlterado: string) => {
        if (responsePacoteAlterado['Mensagem'].includes('com sucesso')) {
          observable.next(responsePacoteAlterado);
        }
        else {
          observable.error(responsePacoteAlterado);
        }
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public cancelaPacote(cancelamento: any, idPacote: number): Observable<any> {
    let motivo = {
      'motivoCancelamento': cancelamento.motivoCancelamento,
      'codigoCancelamento': cancelamento.codigoCancelamento
    }
    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointPedidos + '/' + idPacote + '/' + this.endpointCancelar, motivo).subscribe((responseCancelamento: any) => {
        if (responseCancelamento['Mensagem']) {
          observable.next(responseCancelamento);
        }
        else {
          observable.error(responseCancelamento);
        }
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public marcaPacotesComoImpressos(idsPacotes: number[]): Observable<any> {
    let idsPacotesString = idsPacotes.join(',');
    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointPedidos + '/' + idsPacotesString + '/' + this.endpointMarcarImpresso, null).subscribe((response: any) => {
        observable.next(response);
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public convertArrayInPacoteAdminList(pacotesArray: any[]): Observable<PacoteAdmin[]> {
    return new Observable((observable) => {
      let pacotes: PacoteAdmin[] = [];
      let count = 1;
      pacotesArray.forEach((pedido, posicao) => {
        let pacote = new PacoteAdmin(pacotesArray[posicao]);
        pacotes.push(pacote);
        //verifica se passou em todas as posições pois elas não são executadas em ordem
        if (pacotesArray.length == count) {
          observable.next(pacotes);
        }
        count++;
      });
    });

  }

  public convertArrayInPacoteList(pacotesArray: any[]): Observable<Pacote[]> {
    return new Observable((observable) => {
      let pacotes: Pacote[] = [];
      let count = 1;
      pacotesArray.forEach((venda, posicao) => {
        const pacote = new Pacote();
        pacote.id = venda.id;
        pacote.pedido = venda.pedido;
        pacote.impressao = venda.impressao;
        pacote.pacotesProduto = venda.produtos;
        pacote.situacao = venda.situacao.descricao;
        pacote.valorTotal = venda.valorTotal;
        pacote.custoTotal = venda.custoTotal;
        pacote.valorComissaoMarketplace = venda.valorComissaoMarketplace;
        pacote.valorComissaoHeimber = venda.valorComissaoHeimber;
        pacote.valorFrete = venda.valorFrete;
        pacote.dataCriacaoNota = venda.dataCriacaoNota;
        pacote.dataCriacaoEtiqueta = venda.dataCriacaoEtiqueta;
        pacote.dataEnvioNota = venda.dataEnvioNota;
        pacote.atrasado = venda.atrasado;
        pacote.trackEnviado = venda.entrega.codigoRastreio;


        pacotes.push(pacote);
        //verifica se passou em todas as posições pois elas não são executadas em ordem
        if (pacotesArray.length == count) {
          observable.next(pacotes);
        }
        count++;
      });
    })
  }

  public convertArrayInPacoteDetalhe(pacotesArray: any[]): Observable<Pacote[]> {
    return new Observable((observable) => {
      let pacotes: Pacote[] = [];
      let count = 1;
      pacotesArray.forEach((venda, posicao) => {
        const pacote = new Pacote();
        pacote.id = venda.id;
        pacote.pacotesProduto = venda.produtos;
        pacote.situacao = venda.situacao.descricao;
        pacote.valorTotal = venda.valorTotal;
        pacote.custoTotal = venda.custoTotal;
        pacote.valorComissaoMarketplace = venda.valorComissaoMarketplace;
        pacote.valorComissaoHeimber = venda.valorComissaoHeimber;
        pacote.valorFrete = venda.valorFrete;
        pacote.dataCriacaoNota = venda.dataCriacaoNota;
        pacote.dataCriacaoEtiqueta = venda.dataCriacaoEtiqueta;
        pacote.dataEnvioNota = venda.dataEnvioNota;
        pacote.trackEnviado = venda.entrega.codigoRastreio;
        pacote.dataPedido = venda.dataCompra;
        pacote.marketplace = venda.marketplace;
        pacote.cliente = venda.cliente;

        pacotes.push(pacote);

        //verifica se passou em todas as posições pois elas não são executadas em ordem
        if (pacotesArray.length == count) {
          observable.next(pacotes);
        }
        count++;
      });
    })
  }

  public convertArrayPacoteInPacoteProdutoList(pacoteArray: any): Observable<PacoteProduto[]> {
    return new Observable((observable) => {

      let pacotesProduto: PacoteProduto[] = [];

      pacoteArray.produtos.forEach((pacoteProdutoArray, posicao) => {

        let produto = new Produto();
        produto.sku = pacoteProdutoArray.skuProduto;
        produto.skuSinonimo = pacoteProdutoArray.skuSinonimo;
        produto.titulo = pacoteProdutoArray.titulo;
        produto.imagens = [pacoteProdutoArray.imagemPrincipal];

        let pacoteProduto = new PacoteProduto();
        pacoteProduto.id = pacoteProdutoArray.id;
        pacoteProduto.quantidade = pacoteProdutoArray.quantidade;
        pacoteProduto.produto = produto;
        pacoteProduto.valorTotalCustoProduto = pacoteArray.custoTotal;
        pacoteProduto.valorTotalComissao = pacoteArray.valorComissaoHeimber;
        pacoteProduto.valorTotalVendaCalculado = pacoteArray.valorTotal;
        pacoteProduto.valorTotalVendaRecebido = pacoteProdutoArray.lucroBruto;
        pacoteProduto.pesoTotal = pacoteProdutoArray.pesoTotal;

        pacotesProduto.push(pacoteProduto);

        if (this.helperService.chegouNaUltimaPosicao(pacoteArray.produtos, posicao)) {
          observable.next(pacotesProduto);
        }
      });
    });
  }

  public reimprimirEtiquetas(idsPacotes): Observable<any> {
    let urlReimprimirEtiquetas = this.apiHost + this.endpointPedidos + '/' + idsPacotes + '/' + this.endpointReimprimirEtiqueta;

    return new Observable((observable) => {
      this.http.get(urlReimprimirEtiquetas).subscribe((response) => {
        observable.next(response);
      }, error => {
        observable.error(error);
      });
    });
  }

}
