<!-- Toolbar -->
<p *ngIf="isLoggedIn">
    <mat-toolbar class="app-header">
        <div><a href="#" target="_blank"><img src="assets/img/logo-sem-fundo.png" class="logoImg"></a></div>
        <span class="spacer"></span>
        <span class="nav-tool-items" *ngIf="!isLoggedIn">
            <a mat-button routerLink="login" routerLinkActive="active">Login</a>
            <a mat-button mat-raised-button routerLink="cadastro" routerLinkActive="active">Cadastre-se</a>
        </span>
        <span class="nav-tool-items" style="display: contents;" *ngIf="isLoggedIn">
            <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/rotina/pedidosWebContinental', true)">
                Vendas Webcont.
                nao estao entrando</p>
            <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/rotina/rotinaMagalu', true)">Vendas
                Magalu
                nao estao entrando</p>
            <!-- <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/pedidos/SyncWeltPeds', true)">Vendas welt
                nao estao entrando</p> -->
            <!-- <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/runQueuesOrders', true)">Filas de
                pedidos nao esta rodando</p>
            <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/runQueuesInvoice', true)">Filas envio
                notas nao esta rodando</p> -->
            <!-- <p class="utilLinks"
                (click)="openLink('https://apiv1.webermaq.com/produtos/GerarEtiquetaProduto?sku=&qtd=1', true)">Imprimir
                etiquetas produto</p> -->
        </span>
        <span class="nav-tool-items" *ngIf="isLoggedIn">
            <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/TotaisNotas', true)">Valores totais de
                notas do mês</p>
            <p class="utilLinks" (click)="openLink('https://apiv1.webermaq.com/verificarUfNotasWelt', true)">Verificar
                UF notas welt</p>
        </span>
        <span class="nav-tool-items" *ngIf="isLoggedIn">
            <p class="username">Olá {{ username }}</p>
        </span>

        <mat-container *ngIf="isLoggedIn" class="dropdownBox">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opções de usuário">
                <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="userMenu">
                <button mat-menu-item (click)="changePassword()">
                    <mat-icon>settings</mat-icon>
                    <span>Trocar Senha</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>power_settings_new</mat-icon>
                    <span>Sair</span>
                </button>
            </mat-menu>
        </mat-container>
    </mat-toolbar>

</p>