<div class="mat-elevation-z8 boxContainer" id="containerTodo">

    <!-- Filtros Listagem -->
    <ng-container>
        <form name="filtros" (ngSubmit)="buscaComFiltros()" #f="ngForm" novalidate class="filtros">
            <mat-card-content class="boxInputs" fxLayout="row">
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>Id, Nome, Documento, Marketplace</mat-label>
                    <input matInput type="text" class="form-control" name="pesquisaGeral"
                        [(ngModel)]="filtros.pesquisaGeral" minlength="3" #pesquisaGeral="ngModel">
                </mat-form-field>
                <mat-form-field fxFlex="20" fxFlexOffset="1">
                    <mat-label>Situação</mat-label>
                    <mat-select [(selected)]="filtros.situacao" [(ngModel)]="filtros.situacao"
                        (ngModelChange)="changeFiltros()" required #situacao="ngModel" name="situacao">
                        <mat-option *ngFor="let situacao of situacoes" [value]="situacao.value">
                            {{situacao.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="20" fxFlex="15" *ngIf="filtros.situacao == 'cancelado'" fxFlexOffset="1">
                    <mat-label>Notas vinculadas</mat-label>
                    <mat-select [(selected)]="filtros.vinculoCancelamento" [(ngModel)]="filtros.vinculoCancelamento"
                        #situacao="ngModel" name="vinculoCancelamento">
                        <mat-option *ngFor="let vinculoCancelamento of vinculosCancelamento"
                            [value]="vinculoCancelamento.value">
                            {{vinculoCancelamento.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="filtros.situacao == 'cancelado'? 12 : 15" fxFlexOffset="1">
                    <mat-label>Marketplaces</mat-label>
                    <mat-select [formControl]="mkpSelecionados" multiple>
                        <mat-option *ngFor="let mkp of mkpList" [value]="mkp.id">{{mkp.nome}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="filtros.situacao == 'cancelado'? 15 : 18" fxFlexOffset="1">
                    <mat-label>Logistica</mat-label>
                    <mat-select [(selected)]="filtros.entrega" [(ngModel)]="filtros.entrega" #situacao="ngModel"
                        name="entrega">
                        <mat-option *ngFor="let entrega of entregas" [value]="entrega.value">
                            {{entrega.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
            <mat-card-content class="boxInputs" fxLayout="row">
                <mat-form-field fxFlex="14" fxFlexOffset="1">
                    <input matInput [matDatepicker]="dataInicio" placeholder="Data Inicial" name="C"
                        [(ngModel)]="filtros.periodo[0]" required #dataInicio="ngModel">
                    <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
                    <mat-datepicker #dataInicio></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex="14" fxFlexOffset="1">
                    <input matInput [matDatepicker]="dataFim" placeholder="Data Final" name="dataFim"
                        [(ngModel)]="filtros.periodo[1]" required #dataFim="ngModel">
                    <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
                    <mat-datepicker #dataFim></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex="19" fxFlexOffset="1">
                    <mat-label>Data de:</mat-label>
                    <mat-select [(selected)]="filtros.dataDe" [(ngModel)]="filtros.dataDe" name="dataDe">
                        <mat-option *ngFor="let dataDe of porDataDe" [value]="dataDe.value">
                            {{dataDe.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="16" fxFlexOffset="1">
                    <mat-label>Outros</mat-label>
                    <mat-select [formControl]="filtroOutros" multiple>
                        <mat-option *ngFor="let filtro of filtroOutrosLista" [value]="filtro">{{ filtro }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="18" fxFlexOffset="1">
                    <mat-label>Ordenar Por Data Pagamento</mat-label>
                    <mat-select [(selected)]="filtros.ordenacao" [(ngModel)]="filtros.ordenacao" required
                        #situacao="ngModel" name="ordenacao">
                        <mat-option *ngFor="let ordenacao of ordenacoes" [value]="ordenacao.value">
                            {{ordenacao.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-stroked-button class="btn-block btnPadrao">Filtrar</button>
            </mat-card-content>
        </form>
    </ng-container>

    <!-- Ações Listagem -->
    <mat-card class="boxPedido">
        <mat-card-header class="headerPedidos">
            <mat-checkbox>
                <input type="checkbox" class="cbSelect" name="cbSelectAll" [(ngModel)]="checkedAll"
                    (ngModelChange)="marcaTodosPedidos()">
            </mat-checkbox>
            <p class="numeroSelecionados">{{ selecionados }} Pedido(s) Selecionado(s)</p>
            <mat-container class="optionsBoxAll">
                <button mat-stroked-button class="btn-block btnDefault" (click)="imprimirPedidos()">Imprimir
                    Selecionados</button>
                <button mat-stroked-button class="btn-block btnDefault" (click)="abrirModalFaturaPedido()">Faturar
                    Selecionados</button>
                <button mat-stroked-button class="btn-block btnDefault" (click)="imprimirDanfeEtiquetas()">Impressão
                    zebra</button>

                <button mat-stroked-button class="btn-block btnDefault" (click)="imprimirEtiquetas()">Imprimir
                    Etiquetas</button>

                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                    class="botaoOpcoesEmMassa">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="reimprimirEtiqueta()">
                        <span>Reimprimir Etiquetas</span>
                    </button>
                    <!-- <button mat-menu-item (click)="imprimirEtiquetasMagaluB2w()">
                        <span>Imprimir etiquetas Magalu-B2w</span>
                    </button> -->
                </mat-menu>
            </mat-container>

        </mat-card-header>
    </mat-card>

    <!-- Listagem -->
    <!-- <mat-card *ngFor="let pedido of pacotes" class="boxPedido"> -->
    <mat-card *ngFor="let pedido of pacotes"
        [ngClass]="{'boxPedido': pedido.fulfillment === false, 'boxpedidofulfillment' : pedido.fulfillment === true }">
        <mat-card-header class="headerPedido">
            <mat-checkbox>
                <input type="checkbox" class="cbSelect" name="cbSelect" [(ngModel)]="pedido.checked" #checked="ngModel"
                    (change)="getPedidosSelecionados()">
            </mat-checkbox>
            <mat-card-title>
                <p class="situacaoPedido">{{ pedido.status.descricao }}</p>
                <p class="pedidoFulfillment" *ngIf="pedido.fulfillment">[Fulfillment]</p>
                <p class="pedidoAtrasado" *ngIf="pedido.atrasado">[Atrasado]</p>
            </mat-card-title>
            <span class="spacer">
            </span>
            <mat-container class="optionsBox">

                <mat-icon *ngIf="pedido.impressao != null && pedido.impressao.length != 0" class="mat-icon-print"
                    [matTooltipHideDelay]="2000" matTooltip="{{ montaTextoImpressaoPedido(pedido) }}"
                    matTooltipPosition="above">print</mat-icon>

                <button mat-stroked-button class="btn-block btnFaturar" (click)="abrirModalFaturaPedido(pedido)"
                    *ngIf='pedido.status.descricao == "Aguardando faturamento"'>Faturar</button>
                <button mat-stroked-button class="btn-block btnFaturar" (click)="imprimirEtiquetas(pedido)"
                    *ngIf='pedido.status.descricao == "Aguardando impressão de etiqueta"'>Imprimir Etiqueta</button>
                <span class="spacer"></span>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a [routerLink]="['detalhe']" [queryParams]="{ pedido: pedido.id }">
                        <button mat-menu-item>
                            <span>Detalhes</span>
                        </button>
                    </a>
                    <button mat-menu-item (click)="buscarInfoLote(pedido)"
                        *ngIf='pedido.status.descricao != "Aguardando faturamento"'>
                        <span>Informações de lote</span>
                    </button>
                    <button mat-menu-item (click)="abrirModalAdicionarNf(pedido)"
                        *ngIf='pedido.status.descricao == "Aguardando faturamento"'>
                        <span>Adicionar NF-e Existente</span>
                    </button>
                    <button mat-menu-item (click)="consultaNf(pedido)"
                        *ngIf='pedido.status.descricao != "Aguardando faturamento"'>
                        <span>Dados NF-e</span>
                    </button>
                    <button mat-menu-item (click)="reenviarNFMarketplace(pedido)"
                        *ngIf='pedido.status.descricao != "Aguardando faturamento"'>
                        <span>Reenviar NF-e ao marketplace</span>
                    </button>
                    <button mat-menu-item (click)="consultaStatusNf(pedido.id)"
                        *ngIf='pedido.status.descricao != "Aguardando faturamento"'>
                        <span>Consultar status NF-e</span>
                    </button>

                    <button mat-menu-item (click)="emitirNfeDevolucao(pedido)"
                        *ngIf="pedido.podeEmitirNFeDevolucao && (pedido.status.descricao == 'Cancelado' || pedido.status.descricao == 'Problema na entrega')">
                        <span>Emitir NF-e de devolução</span>
                    </button>

                    <button mat-menu-item (click)="cancelarPedido(pedido)" *ngIf="pedido.status.descricao != 'Cancelado' &&
                        (pedido.marketplace.grupo != 'MZL' )">
                        <span>Cancelar Pedido</span>
                    </button>
                    <button mat-menu-item (click)="abrirModalInformarIECliente(pedido.cliente.id)">
                        <span>Informar IE Cliente</span>
                    </button>
                    <button mat-menu-item (click)="reimprimirEtiqueta(pedido.id)"
                        *ngIf='pedido.status.descricao == "Aguardando postagem" && pedido.fulfillment != true'>
                        <span>Reimprimir Etiqueta</span>
                    </button>
                </mat-menu>

            </mat-container>
        </mat-card-header>

        <mat-card-content>
            <p class="nomeCliente">{{ pedido.cliente.nome }} </p>
            <p class="dadosPedido">#{{pedido.id }} - {{ pedido.marketplace.nome }} | {{ pedido.dataPedido }}</p>

            <table class="table">
                <thead>
                    <tr class="tableHeader">
                        <th>Imagem</th>
                        <th>SKU</th>
                        <th>Nome</th>
                        <th>Quantidade</th>
                        <th>Valor Total Venda</th>
                    </tr>
                </thead>
                <tbody *ngFor="let produto of pedido.produtos" class="boxProduto">
                    <tr>
                        <td data-toggle="tooltip" data-placement="top" title="Imagem"><img src="{{ produto.imagem }}"
                                class="imgProduct"></td>
                        <td data-toggle="tooltip" data-placement="top" title="SKU">{{ produto.sku }}</td>
                        <td data-toggle="tooltip" data-placement="top" title="Nome">{{ produto.titulo }}</td>
                        <td data-toggle="tooltip" data-placement="top" title="Quantidade">{{ produto.quantidadeVendida
                            }}</td>
                        <td data-toggle="tooltip" data-placement="top" title="Valor Total Venda">
                            {{ pedido.valorTotalPedido ? pedido.valorTotalPedido.toLocaleString('br-BR', { style:
                            'currency', currency: 'BRL' }) : 0 }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <!-- Paginator -->
    <div class="row">
        <div class="paginator">
            <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
                (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPedidosPage($event)">
            </app-pagination>
        </div>
    </div>


    <mat-container *ngIf="showStatusSuccess" class="alert alert-success alert-dismissible fade show mensagem-alerta">
        <mat-card-content>{{ statusNfMessage }}</mat-card-content>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaStatusNfMessage()">
            <span aria-hidden="true">&times;</span>
        </button>
    </mat-container>

</div>

<!-- Loading Spinner -->
<div class="loading" *ngIf="loading">
    <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>


<!-- Modal Emissao NFe -> Gerar Etiquetas -> Imprimir Etiquetas -->
<mat-container *ngIf="mostraFaturarPedido" class="boxModal">
    <!-- Exit Modal -->
    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;"
        (click)="closeModalPadrao(); stepper.reset()"></div>
    <button mat-icon-button (click)="closeModalPadrao(); stepper.reset()" class="buttonCloseModal">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <!-- Emissão NF -->
    <div class="modalPadrao">

        <div class="headerModalPadrao">
            <h2 mat-dialog-title>Pedido(s) Selecionado(s)</h2>

        </div>
        <div class="corpoModalPadrao">
            <table mat-table [dataSource]="pedidosSelecionados" class="mat-elevation-z8 tableGeraNf">

                <!-- Pedido Column -->
                <ng-container matColumnDef="pedido">
                    <th mat-header-cell *matHeaderCellDef style="padding-right: 15px;">Pedido</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- Mkp Column -->
                <ng-container matColumnDef="mkp">
                    <th mat-header-cell *matHeaderCellDef>Mkp </th>
                    <td mat-cell *matCellDef="let element"> {{element.marketplace.nome}} </td>
                </ng-container>

                <!-- Cliente Column -->
                <ng-container matColumnDef="cliente">
                    <th mat-header-cell *matHeaderCellDef>Cliente </th>
                    <td mat-cell *matCellDef="let element"> {{element.cliente.nome}} </td>
                </ng-container>

                <!-- Valor Total Column -->
                <ng-container matColumnDef="valorTotal">
                    <th mat-header-cell *matHeaderCellDef>Valor Total </th>
                    <td mat-cell *matCellDef="let element"> {{ element.valorTotalPedido.toLocaleString('br-BR', { style:
                        'currency', currency: 'BRL' })}} </td>
                </ng-container>

                <!-- Status NF Column -->
                <ng-container matColumnDef="geraNf">
                    <th mat-header-cell *matHeaderCellDef>NF</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.nfeGerada ? element.nfeGerada : "Não Gerada" }} </td>
                </ng-container>

                <!-- Gerar Etiquetas Column -->
                <ng-container matColumnDef="geraEtiqueta">
                    <th mat-header-cell *matHeaderCellDef>Etiqueta</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.etiquetaGerada == false || element.etiquetaGerada == null ? 'Não gerada' : 'Gerada'}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
        <div class="acoesModalPadrao">

            <mat-horizontal-stepper #stepper>

                <mat-step label="Gerar NFe" state="geraNf">
                    <p class="subTitleFatura" style="display: none;">Selecione o tipo e ambiente para emissão da NF-e:
                    </p>
                    <form name="detalhesEmissao" (ngSubmit)="submitNfe()" #fo="ngForm" novalidate
                        class="filtrosFaturamento">

                        <mat-form-field style="display: none;">
                            <mat-label>Tipo de Nota:</mat-label>
                            <mat-select [(selected)]="detalhesEmissao.modeloDocumento"
                                [(ngModel)]="detalhesEmissao.modeloDocumento" required #modeloDocumento="ngModel"
                                name="modeloDocumento">
                                <mat-option *ngFor="let item of tiposNf" [value]="item.value">
                                    {{item.nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field style="display: none;">
                            <mat-label>Ambiente:</mat-label>
                            <mat-select [(selected)]="detalhesEmissao.ambiente" [(ngModel)]="detalhesEmissao.ambiente"
                                required #ambiente="ngModel" name="ambiente">
                                <mat-option *ngFor="let item of ambientes" [value]="item.value">
                                    {{item.nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button mat-stroked-button mat-button matStepperNext class="btn-block btnPadrao"
                            style="top: 50%;" *ngIf="btnEmitirNfe">Emitir</button>

                    </form>
                    <mat-container *ngIf="showErroEmitirNfe" class="alert alert-danger alert-dismissible fade show">
                        <mat-card-content>{{ errorMessageEmitirNfe }}</mat-card-content>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </mat-container>
                    <div *ngIf="btnSairEmissaoNF">
                        <button mat-button class="btnPadrao nextBtn" (click)="closeModalPadrao()">Sair</button>
                    </div>
                </mat-step>

                <mat-step label="Gerar Etiquetas" step="geraEtiquetas">

                    <button mat-stroked-button mat-button matStepperNext class="nextBtn"
                        (click)="faturarEGerarImpressaoZebra()" style="top: 50%;max-width: 280px;float: right;">Gerar
                        impressão zebra</button>
                    <button mat-stroked-button mat-button matStepperNext class="nextBtn"
                        style="top: 50%;margin-right: 20px;" (click)="geraEtiquetas()" *ngIf="btnGerarEtiqueta">Gerar
                        Etiquetas</button>
                </mat-step>

                <mat-step label="Imprimir Etiquetas" step="imprimirEtiquetas">

                    <button mat-stroked-button class="nextBtn" style="top: 50%;" (click)="imprimirEtiquetas()"
                        *ngIf="btnImprimirEtiqueta">Imprimir Etiquetas Geradas</button>

                </mat-step>

                <ng-template matStepperIcon="geraNf">
                    <mat-icon class="md-light">request_page</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="geraEtiquetas">
                    <mat-icon class="md-light">receipt</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="imprimirEtiquetas">
                    <mat-icon class="md-light">print</mat-icon>
                </ng-template>
            </mat-horizontal-stepper>

        </div>

    </div>

</mat-container>

<!-- Modal info lote -->
<mat-container *ngIf="mostraInfoImpressao" class="boxModal">
    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;" (click)="closeModalPadrao()"></div>
    <button mat-icon-button (click)="closeModalPadrao()" class="buttonCloseModal">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <div class="modalPadrao" style="padding: 15px;">
        <div class="headerModalPadrao">
            <h2 mat-dialog-title>Informacoes de lote de trabalho</h2>
        </div>
        <div class="corpoModalPadrao">
            <mat-card-content>
                <table class="table">
                    <thead>
                        <tr class="tableHeader">
                            <th>Lote trabalho</th>
                            <th>Pedidos</th>
                            <th>Data impressao</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let loteTrabalho of lotesTrabalho" class="boxProduto">
                        <tr>
                            <td data-toggle="tooltip" data-placement="top" title="Lote">{{ loteTrabalho.id }}</td>
                            <td data-toggle="tooltip" data-placement="top" title="Pedidos">{{ loteTrabalho.idsPacotes }}
                            </td>
                            <td data-toggle="tooltip" data-placement="top" title="Data impressao">{{
                                loteTrabalho.created_at
                                }}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </div>
    </div>
</mat-container>

<!-- Modal Cancelamento Pedido -->
<mat-container *ngIf="mostraCancelarPedido" class="boxModal">
    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;" (click)="closeModalPadrao()"></div>
    <button mat-icon-button (click)="closeModalPadrao()" class="buttonCloseModal">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <div class="modalPadrao">
        <div class="headerModalPadrao">
            <h2 mat-dialog-title>Cancelar Pedido</h2>
        </div>
        <div class="corpoModalPadrao">
        </div>
        <div class="acoesModalPadrao">
            <form name="detalhesCancelamento" (ngSubmit)="submitCancelamento()" #for="ngForm" novalidate
                class="filtrosCancelamento">
                <mat-form-field>
                    <mat-label>Tipo de Cancelamento:</mat-label>
                    <mat-select [(selected)]="cancelamento.codigoCancelamento"
                        [(ngModel)]="cancelamento.codigoCancelamento" required #codigoCancelamento="ngModel"
                        name="cancelamento" class="mdb-select md-form colorful-select dropdown-danger">
                        <mat-option *ngFor="let item of codigosCancelamento" [value]="item.value">
                            {{item.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 80%;">
                    <mat-label>Observação: (opcional)</mat-label>
                    <textarea matInput [(ngModel)]="cancelamento.motivoCancelamento" #motivoCancelamento="ngModel"
                        name="motivoDoCancelamento"></textarea>
                </mat-form-field>
                <button mat-stroked-button class="btn-block btnPadrao" style="top: 50%;">Cancelar</button>
            </form>
        </div>
    </div>
</mat-container>

<!-- Modal Adiciona Nf Existente -->
<mat-container *ngIf="mostraAdicionarNf" class="boxModal">
    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;" (click)="closeModalPadrao()"></div>
    <button mat-icon-button (click)="closeModalPadrao()" class="buttonCloseModal">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <div class="modalPadrao">

        <div class="headerModalPadrao">
            <h2 *ngIf="!devolucao" mat-dialog-title>Adicionar NF-e existente ao pedido</h2>
            <h2 *ngIf="devolucao" mat-dialog-title>Adicione a NF-e existente ao pedido para a devolução</h2>
        </div>
        <div class="corpoModalPadrao">
        </div>
        <div class="acoesModalPadrao">
            <form name="adicionarNfExistente" (ngSubmit)="submitAdicionaNfeExistente()" #form="ngForm" novalidate
                class="filtrosCancelamento">
                <mat-form-field>
                    <mat-label>Número:</mat-label>
                    <input matInput type="number" name="numero" [(ngModel)]="nfeExistente.numero" required
                        #numero="ngModel">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Série:</mat-label>
                    <input matInput type="number" name="serie" [(ngModel)]="nfeExistente.serie" required
                        #serie="ngModel">
                </mat-form-field>
                <mat-form-field>
                    <input matInput [matDatepicker]="dataEmissao" placeholder="Data Emissão" name="dataEmissao"
                        [(ngModel)]="nfeExistente.dataEmissao" required #dataEmissao="ngModel">
                    <mat-datepicker-toggle matSuffix [for]="dataEmissao"></mat-datepicker-toggle>
                    <mat-datepicker #dataEmissao></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="width: 50%;">
                    <mat-label>Chave de Acesso:</mat-label>
                    <input matInput type="text" name="chaveAcesso" [(ngModel)]="nfeExistente.chaveAcesso" maxlength="44"
                        required #chaveAcesso="ngModel">
                </mat-form-field>
                <button mat-stroked-button class="btn-block btnPadrao" style="top: 60%; transform: inherit;">Adicionar
                    NF</button>
            </form>
        </div>
    </div>



</mat-container>

<!-- Modal NF Emitida -->
<mat-container *ngIf="mostraNfEmitida" class="boxModal">
    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;" (click)="closeModalPadrao()"></div>
    <button mat-icon-button (click)="closeModalPadrao()" class="buttonCloseModal">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <div class="modalPadrao modalStatusNf">

        <div class="headerModalPadrao">
            <h2 mat-dialog-title>NF-e Existente do Pedido #{{ nfPedidoSelecionado.idPacote != null ?
                nfPedidoSelecionado.idPacote : "" }}</h2>
        </div>
        <div class="corpoModalPadrao d-flex justify-content-between">
            <mat-form-field class="dadosNf">
                <mat-label>Número:</mat-label>
                <input matInput type="number" name="numero" [(ngModel)]="nfPedidoSelecionado.numero" disabled
                    style="color: black;" #numero="ngModel">
            </mat-form-field>

            <mat-form-field class="dadosNf">
                <mat-label>Série:</mat-label>
                <input matInput type="number" name="serie" [(ngModel)]="nfPedidoSelecionado.serie" disabled
                    style="color: black;" #serie="ngModel">
            </mat-form-field>

            <mat-form-field style="width: 60%;">
                <mat-label>Chave de Acesso:</mat-label>
                <input matInput type="text" name="chaveAcesso" [(ngModel)]="nfPedidoSelecionado.chave" maxlength="44"
                    disabled style="color: black;" #chaveAcesso="ngModel">
            </mat-form-field>

        </div>

        <mat-container *ngIf="showStatusSuccess" class="alert alert-success alert-dismissible fade show mensagemStatus">
            <mat-card-content>{{ statusNfMessage }}</mat-card-content>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                (click)="fechaStatusNfMessage()">
                <span aria-hidden="true">&times;</span>
            </button>
        </mat-container>

        <mat-container *ngIf="showStatusErro" class="alert alert-danger alert-dismissible fade show mensagemStatus">
            <mat-card-content>{{ statusNfMessage }}</mat-card-content>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                (click)="fechaStatusNfMessage()">
                <span aria-hidden="true">&times;</span>
            </button>
        </mat-container>

        <div class="acoesModalPadrao">
            <mat-card class="boxPedido">
                <mat-card-header class="headerPedidos">
                    <mat-container class="btnsStatusNf">
                        <button mat-stroked-button class="btn-block btnStatusNf" (click)="consultaStatusNf()">Consulta
                            Status</button>
                        <button mat-stroked-button class="btn-block btnStatusNf" (click)="baixarDanfeNf()">Baixar
                            DANFE</button>
                        <button mat-stroked-button class="btn-block btnStatusNf" (click)="baixarXmlNf()">Baixar
                            XML</button>
                        <button *ngIf="mostraEstornoNf" mat-stroked-button class="btn-block btnStatusNfCancelar"
                            (click)="estornarNf(nfPedidoSelecionado.idPacote)">Estornar NF</button>
                        <!-- <button mat-stroked-button class="btn-block btnStatusNfCancelar"
                            (click)="emitirNfValorIntegral(nfPedidoSelecionado.pedido)">Emitir valor
                            integral</button> -->
                        <button *ngIf="mostraCancelamentoNf" mat-stroked-button class="btn-block btnStatusNfCancelar"
                            (click)="mostraMotivoCancelaNf = !mostraMotivoCancelaNf">Cancelar NF</button>
                        <button mat-stroked-button class="btn-block btnStatusNf"
                            (click)="closeModalNf()">Fechar</button>
                    </mat-container>

                </mat-card-header>
            </mat-card>
        </div>

        <div *ngIf="mostraMotivoCancelaNf" class="motivoCancelamentoNf">
            <form name="detalhesCancelamentoNf" (ngSubmit)="submitCancelamentoNf()" #for="ngForm" novalidate
                class="formCancelamentoNf">

                <mat-form-field class="example-full-width">
                    <mat-label>Motivo:</mat-label>
                    <textarea matInput required placeholder="" [(ngModel)]="motivoCancelamentoNf"
                        #motivosCancelamentoNf="ngModel" name="cancelamentoNf"></textarea>
                </mat-form-field>

                <button mat-stroked-button class="btn-block btnStatusNf" style="top: 50%;">Cancelar NF</button>
            </form>
        </div>
    </div>
</mat-container>

<!-- Mensagem Erro -->
<mat-container *ngIf="showErrorMessage" class="alert alert-danger alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ errorMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemErro()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>

<!-- Mensagem Sucesso -->
<mat-container *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ successMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>