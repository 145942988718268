import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-recuperacao-senha',
  templateUrl: './recuperacao-senha.component.html',
  styleUrls: ['./recuperacao-senha.component.scss']
})
export class RecuperacaoSenhaComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  codigoCorreto: boolean;
  mensagemRetorno: string = '';
  emailToReset: string;
  novasSenhasBatem: boolean = false;
  novaSenha = 1;
  senhaRedefinida: boolean = false;

  constructor(private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    protected router: Router) { }

  ngOnInit() {
    this.form.emailCripto = this.activatedRoute.snapshot.queryParamMap.get('t');
  }


  verificaCodigo() {

    this.authService.verificaCodigo(this.form).subscribe(
      data => {
        if (data.Status == "ok") {
          this.codigoCorreto = true;
          this.novaSenha = 2;
        } else if (data.Status == "erro") {
          this.codigoCorreto = false;
          this.mensagemRetorno = "Código inválido!";
        }
      },
      err => {
        this.codigoCorreto = false;
        this.mensagemRetorno = "Código inválido!";
      }
    );
  }

  verificaSenhas() {
    if (this.form.novaSenha == this.form.novaSenhaConfirma) {
      
      this.novasSenhasBatem = true;
      this.salvaNovaSenha();
    } else {
      this.novasSenhasBatem = false;
      this.mensagemRetorno = "As senhas devem ser iguais!";
    }
  }

  salvaNovaSenha() {
    this.authService.salvaNovaSenha(this.form).subscribe(
      data => {
        this.senhaRedefinida = true;
        this.mensagemRetorno = "Senha alterada com sucesso!";

        setTimeout (() => {
          this.router.navigate(['login']);
       }, 3000);

      },
      err => {
        this.senhaRedefinida = false;
        this.mensagemRetorno = "A redefinição de senha falhou, tente novamente mais tarde ou contate o suporte!";
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }

  limpaMensagens() {
    this.mensagemRetorno = '';
  }

}
