<div class="mat-elevation-z8 boxContainer" id="containerTodo">

    <!-- Filtros Listagem -->
    <ng-container>
        <form name="filtros" (ngSubmit)="buscaComFiltros()" #f="ngForm" novalidate class="filtros">
            <mat-card-content class="boxInputs" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field fxLayout="column" appearance="outline" fxFlex="35">
                    <mat-label>SKU</mat-label>
                    <input matInput type="text" class="form-control" name="pesquisaGeral"
                        [(ngModel)]="filtros.pesquisaGeral" minlength="3" #pesquisaGeral="ngModel">
                </mat-form-field>
            </mat-card-content>
            <button mat-stroked-button class="btn-block btnPadrao">Filtrar</button>
        </form>
    </ng-container>

    <!-- Listagem -->
    <mat-card class="boxPedido">
        <mat-card-content class="headerPedido">

            <table class="table">
                <thead>
                    <tr class="tableHeader">
                        <th>SKU</th>
                        <th>Título</th>
                        <th>Estoque</th>
                        <th>Custo</th>
                        <th>Preço</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngFor="let produto of dataSourceProdutos" class="boxProduto">
                    <tr>
                        <td data-toggle="tooltip" data-placement="top" title="SKU">
                            <a [routerLink]="['detalhe']" [queryParams]="{ produto: produto.sku }">
                                {{ produto.skuSinonimo ? '[' + produto.sku + '] ' + produto.skuSinonimo : produto.sku }}</a>
                        </td>
                        <td data-toggle="tooltip" data-placement="top" title="Titulo">{{ produto.titulo }}</td>
                        <td data-toggle="tooltip" data-placement="top" title="Estoque">{{ produto.estoque }}</td>
                        <td data-toggle="tooltip" data-placement="top" title="Custo">
                            {{ produto.custo ? produto.custo.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</td>
                        <td data-toggle="tooltip" data-placement="top" title="Preço">
                            {{ produto.precoBase ? produto.precoBase.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</td>
                        <td>
                            <mat-container class="optionsBox">
                                <span class="spacer"></span>
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                </mat-menu>

                            </mat-container>
                        </td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>

    <!-- Paginator -->
    <div class="row">
        <div class="paginator">
            <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
                (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPedidosPage($event)">
            </app-pagination>
        </div>
    </div>


</div>

<div class="loading" *ngIf="loading">
    <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>

<mat-container *ngIf="showErrorMessage" class="alert alert-danger alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ errorMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemErro()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>

<mat-container *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ successMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>