// Angular
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';


/* Routing */
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

/* Angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatMenuModule,
  MatDialogModule
} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';


/* FormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HotTableModule } from '@handsontable/angular';

/* Angular Flex Layout */
import { FlexLayoutModule } from '@angular/flex-layout';

/* Components */
import { LoginComponent } from './pages/login/login.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { HomeComponent } from './pages/home/home.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RecuperacaoSenhaComponent } from './pages/recuperacao-senha/recuperacao-senha.component';
import { DialogOverviewIECliente, PedidosListComponent } from './pages/pedidos/pedidos-list/pedidos-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

/* Auth */
import { authInterceptorProviders } from './_helpers/auth.interceptor';

// Shared
import { TopbarComponent } from './shared/topbar/topbar.component';
import { SideMenuComponent } from './shared/side-menu/side-menu.component';
import { PaginationModule } from './shared/pagination/pagination.module';
import { PedidosDetalheComponent } from './pages/pedidos/pedidos-detalhe/pedidos-detalhe.component';
import { PoliticaPrivacidadeTermosComponent } from './pages/politica-privacidade-termos/politica-privacidade-termos.component';
import { ProdutosListEdicaoMassaComponent } from './pages/produtos/produtos-list-edicao-massa/produtos-list-edicao-massa.component';
import { ProdutosDetalheComponent } from './pages/produtos/produtos-detalhe/produtos-detalhe.component';
import { ProdutosListComponent } from './pages/produtos/produtos-list/produtos-list.component';

import { NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import * as Sentry from '@sentry/browser';
import { ProdutoAtributoComponent } from './pages/produto-atributo/produto-atributo.component';
import { ProdutosCategoriasMlComponent } from './pages/produtos-categorias-ml/produtos-categorias-ml.component';
import { SacComponent } from './pages/sac/sac.component';

// Sentry.init({
//   dsn: 'https://c0da0d6ee08a49d0b12d26772c1f8a04@sentry.retta.com.br/125'
// });

@Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() { }
//   handleError(error) {
//     Sentry.captureException(error.originalError || error);
//     console.log(error);
//   }
// }

@NgModule({
  entryComponents: [
    DialogOverviewIECliente
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    CadastroComponent,
    TopbarComponent,
    UsuarioComponent,
    HomeComponent,
    ResetPasswordComponent,
    RecuperacaoSenhaComponent,
    DashboardComponent,
    SideMenuComponent,
    PedidosListComponent,
    PedidosDetalheComponent,
    PoliticaPrivacidadeTermosComponent,
    ProdutosListEdicaoMassaComponent,
    ProdutosDetalheComponent,
    ProdutosListComponent,
    ProdutoAtributoComponent,
    DialogOverviewIECliente,
    ProdutoAtributoComponent,
    ProdutosCategoriasMlComponent,
    SacComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatProgressSpinnerModule,
    PaginationModule,
    MatStepperModule,
    MatMenuModule,
    HotTableModule.forRoot(),
    MatDialogModule
  ],
  exports: [
    MatCardModule
  ],
  providers: [authInterceptorProviders, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  // tslint:disable-next-line:contextual-lifecycle use-lifecycle-interface
  ngOnInit(): void {
    // throw new Error('Sentry Test Error');
  }


}
