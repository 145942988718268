import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { HomeComponent } from './pages/home/home.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RecuperacaoSenhaComponent } from './pages/recuperacao-senha/recuperacao-senha.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PoliticaPrivacidadeTermosComponent } from './pages/politica-privacidade-termos/politica-privacidade-termos.component';
import { PedidosListComponent } from './pages/pedidos/pedidos-list/pedidos-list.component';
import { PedidosDetalheComponent } from './pages/pedidos/pedidos-detalhe/pedidos-detalhe.component';
import { ProdutosListEdicaoMassaComponent } from './pages/produtos/produtos-list-edicao-massa/produtos-list-edicao-massa.component';
import { ProdutosListComponent } from './pages/produtos/produtos-list/produtos-list.component';
import { ProdutoAtributoComponent } from './pages/produto-atributo/produto-atributo.component';
import { ProdutosDetalheComponent } from './pages/produtos/produtos-detalhe/produtos-detalhe.component';
import { ProdutosCategoriasMlComponent } from './pages/produtos-categorias-ml/produtos-categorias-ml.component';
import { SacComponent } from './pages/sac/sac.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: 'profile', component: UsuarioComponent },
  { path: 'login/reset-password', component: ResetPasswordComponent },
  { path: 'request-password', component: RecuperacaoSenhaComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'politica-privacidade-termos', component: PoliticaPrivacidadeTermosComponent },
  { path: 'pedidos', component: PedidosListComponent },
  { path: 'pedidos/detalhe', component: PedidosDetalheComponent },
  { path: 'produtos-edicao-massa', component: ProdutosListEdicaoMassaComponent },
  { path: 'produtos', component: ProdutosListComponent },
  { path: 'categoriasProdutos', component: ProdutosCategoriasMlComponent },
  { path: 'sac', component: SacComponent },
  { path: 'produtosAtributo', component: ProdutoAtributoComponent },
  { path: 'produtos/detalhe', component: ProdutosDetalheComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }