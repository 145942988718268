<!-- Toolbar -->
<p style="position: absolute; top: 0;">
    <mat-toolbar class="app-header">
        <div><a href="#" target="_blank"><img src="assets/img/logo-sem-fundo.png" class="logoImg"></a></div>
        <span class="spacer"></span>
        <span class="nav-tool-items">
            <a mat-button href="/login">Login</a>
            <a mat-button mat-raised-button href="/cadastro">Cadastre-se</a>
        </span>
    </mat-toolbar>
</p>
<div class="mat-elevation-z8 boxContainer">

    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Privacidade">
            <div class="tabInfo">
                <h2>Política de Privacidade</h2>
                <h5>SEÇÃO 1 - O QUE FAZEMOS COM AS SUAS INFORMAÇÕES?</h5>
                <p>Como parte do processo de compra e venda, coletamos as informações pessoais que você nos fornece,
                    tais como seu nome, documento, endereço e e-mail.<br><br></p>
                <h5>SEÇÃO 2 - DIVULGAÇÃO</h5>
                <p>Podemos divulgar suas informações pessoais se formos obrigados por lei a fazê-lo ou se você violar
                    nossos Termos de serviço.<br><br></p>
                <h5>SEÇÃO 3 - HEIMBER</h5>
                <p>Seus dados estão armazenados através do armazenamento de dados do Heimber, de bancos de dados e do
                    aplicativo geral do Heimber. Eles armazenam dados em um servidor seguro protegido por firewall,
                    tendo dados sensíveis, como os de seus documentos e endereço, criptografados pela tecnologia
                    AES-256.<br><br></p>
                <p>Pagamento:<br>
                    Se você escolher um gateway de pagamento direto para completar a sua compra, o Heimber armazena seus
                    dados de cartão de crédito. Eles são criptografados através do Padrão de segurança de dados do setor
                    de pagamento com cartão (PCI-DSS). Seus dados de transação de compra são armazenados apenas pelo
                    período necessário para completar sua transação de compra. Depois de finalizar a compra, suas
                    informações de transação de compra são apagadas.
                    <br><br>
                    Todos os gateways de pagamento direto aderem aos padrões definidos pela PCI-DSS, que são gerenciados
                    pelo Conselho de normas de segurança PCI. Ele é um esforço conjunto de marcas como Visa, MasterCard,
                    American Express e Discover.
                    <br><br>
                    Os requisitos da PCI-DSS ajudam a garantir a utilização segura de informações de cartão de crédito
                    pela nossa loja e seus provedores de serviço.<br><br>
                </p>
                <h5>SEÇÃO 4 - SERVIÇOS DE TERCEIROS</h5>
                <p>No geral, nossos fornecedores terceirizados irão coletar, usar e divulgar suas informações apenas na
                    medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.
                    <br><br>
                    Uma vez que você sair do site da nossa loja ou for redirecionado para um aplicativo ou site de
                    terceiros, você não será mais regido por essa Política de privacidade ou pelos Termos de serviço do
                    nosso site.<br><br>
                </p>
                <p>Links:
                    <br>
                    Quando você clicar em links na nossa loja, eles podem lhe direcionar para fora do nosso site. Não
                    somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as
                    políticas de privacidade deles.<br><br>
                </p>
                <h5>SEÇÃO 5 - SEGURANÇA</h5>
                <p>Para proteger suas informações pessoais, tomamos precauções e seguimos as melhores práticas
                    da
                    indústria para nos certificar que elas não sejam perdidas, usurpadas, acessadas, divulgadas,
                    alteradas ou destruídas.
                    <br><br>
                    Se você nos fornecer as suas informações de cartão de crédito, elas serão criptografadas usando a
                    tecnologia "secure socket layer" (SSL) e armazenadas com criptografia AES-256. Embora nenhum método
                    de transmissão pela Internet ou armazenamento eletrônico seja 100% seguro, nós seguimos todos os
                    requisitos da PCI-DSS e implementamos medidas adicionais aceitas pelos padrões da indústria.
                    <br><br>
                </p>
                <h5>SEÇÃO 6 - IDADE DE CONSENTIMENTO</h5>
                <p>Ao usar esse site, você confirma que você é maior de idade em seu estado ou província de
                    residência e
                    que você nos deu seu consentimento para permitir que qualquer um dos seus dependentes menores de
                    idade usem esse site.<br><br>
                </p>
                <h5>SEÇÃO 7 - ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h5>
                <p>Reservamos o direito de modificar essa política de privacidade a qualquer momento. Portanto, por
                    favor, leia-a com frequência. As alterações e esclarecimentos surtem efeito imediatamente após serem
                    publicadas no site. Caso façamos alterações na política de privacidade, iremos notificá-lo sobre a
                    atualização. Assim, você saberá quais informações coletamos, como as usamos, e sob que
                    circunstâncias, caso aplicável, as utilizaremos e/ou divulgaremos.
                    <br><br>
                    Caso ocorra a fusão da nossa loja com outra empresa, suas informações podem ser transferidas para os
                    novos proprietários para que possamos continuar vendendo produtos para você.<br><br>
                </p>
                <h5>PERGUNTAS E INFORMAÇÕES DE CONTATO</h5>
                <p>Se você gostaria de acessar, corrigir, alterar ou excluir quaisquer informações pessoais que
                    temos
                    sobre você, registre uma queixa, ou simplesmente peça mais informações de contato a(o) nosso(a)
                    Diretor(a) de privacidade através do sac@heimber.com.br ou pelo correio: Heimber
                    <br><br>
                    [Re: Diretor(a) de privacidade]
                    <br><br>
                    [Av. Bruno Cassel 405, São Sebastião do Caí, RS, 95760000, Brasil]
                    <br><br></p>
                <h3>Mais informações</h3>
                <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não
                    tem
                    certeza se
                    precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos
                    recursos
                    que
                    você usa em nosso site.</p>
                <br>
                <p>Esta política é efetiva a partir da sua publicação no site.<br><br></p>
            </div>
        </mat-tab>
        <mat-tab label="Termos de Uso">
            <div class="tabInfo">
                <h2>Termos de Uso</h2>
                <h5>VISÃO GERAL</h5>
                <p>Esse site é operado pelo Heimber. Em todo o site, os termos “nós”, “nos” e “nosso” se referem ao
                    Heimber. O Heimber proporciona esse site, incluindo todas as informações, ferramentas e serviços
                    disponíveis deste site para você, o usuário, com a condição da sua aceitação de todos os termos,
                    condições, políticas e avisos declarados aqui.
                    <br><br>
                    Ao visitar nosso site e/ou comprar alguma coisa no nosso site, você está utilizando nossos
                    “Serviços”. Consequentemente, você concorda com os seguintes termos e condições (“Termos de
                    serviço”, “Termos”), incluindo os termos e condições e políticas adicionais mencionados neste
                    documento e/ou disponíveis por hyperlink. Esses Termos de serviço se aplicam a todos os usuários do
                    site, incluindo, sem limitação, os usuários que são navegadores, fornecedores, clientes, lojistas
                    e/ou contribuidores de conteúdo.
                    <br><br>
                    Por favor, leia esses Termos de serviço cuidadosamente antes de acessar ou utilizar o nosso site. Ao
                    acessar ou usar qualquer parte do site, você concorda com os Termos de serviço. Se você não concorda
                    com todos os termos e condições desse acordo, então você não pode acessar o site ou usar quaisquer
                    serviços. Se esses Termos de serviço são considerados uma oferta, a aceitação é expressamente
                    limitada a esses Termos de serviço.
                    <br><br>
                    Quaisquer novos recursos ou ferramentas que forem adicionados também devem estar sujeitos aos Termos
                    de serviço. Você pode revisar a versão mais atual dos Termos de serviço quando quiser nesta página.
                    Reservamos o direito de atualizar, alterar ou trocar qualquer parte desses Termos de serviço ao
                    publicar atualizações e/ou alterações no nosso site. É sua responsabilidade verificar as alterações
                    feitas nesta página periodicamente. Seu uso contínuo ou acesso ao site após a publicação de
                    quaisquer alterações constitui aceitação de tais alterações.
                    <br><br></p>
                <h5>SEÇÃO 1 - TERMOS DA PLATAFORMA</h5>
                <p>Ao concordar com os Termos de serviço, você confirma que você é maior de idade em seu estado ou
                    província de residência e que você nos deu seu consentimento para permitir que qualquer um dos seus
                    dependentes menores de idade usem esse site.
                    <br><br>
                    Você não deve usar nossos produtos para qualquer fim ilegal ou não autorizado. Você também não pode,
                    ao usufruir deste Serviço, violar quaisquer leis em sua jurisdição (incluindo, mas não limitado, a
                    leis de direitos autorais).
                    <br><br>
                    Você não deve transmitir nenhum vírus ou qualquer código de natureza destrutiva.
                    <br><br>
                    Violar qualquer um dos Termos tem como consequência a rescisão imediata dos seus Serviços.<br><br>
                </p>
                <h5>SEÇÃO 2 - CONDIÇÕES GERAIS</h5>
                <p>Reservamos o direito de recusar o serviço a qualquer pessoa por qualquer motivo a qualquer momento.
                    <br><br>
                    Você entende que o seu conteúdo (não incluindo informações de cartão de crédito), pode ser
                    transferido sem criptografia e pode: (a) ser transmitido por várias redes; e (b) sofrer alterações
                    para se adaptar e se adequar às exigências técnicas de conexão de redes ou dispositivos. As
                    informações de cartão de crédito sempre são criptografadas durante a transferência entre redes.
                    <br><br>
                    Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do
                    Serviço, uso do Serviço, acesso ao Serviço, ou qualquer contato no site através do qual o serviço é
                    fornecido, sem nossa permissão expressa por escrito.
                    <br><br>
                    Os títulos usados nesse acordo são incluídos apenas por conveniência e não limitam ou afetam os
                    Termos.<br><br></p>

                <h5>SEÇÃO 3 - PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES</h5>
                <p>Não somos responsáveis por informações disponibilizadas nesse site que não sejam precisas, completas
                    ou atuais. O material desse site é fornecido apenas para fins informativos e não deve ser usado como
                    a única base para tomar decisões sem consultar fontes de informações primárias, mais precisas, mais
                    completas ou mais atuais. Qualquer utilização do material desse site é por sua conta e risco.
                    <br><br>
                    Esse site pode conter certas informações históricas. As informações históricas podem não ser atuais
                    e são fornecidas apenas para sua referência. Reservamos o direito de modificar o conteúdo desse site
                    a qualquer momento, mas nós não temos obrigação de atualizar nenhuma informação em nosso site. Você
                    concorda que é de sua responsabilidade monitorar alterações no nosso site.
                    <br><br>
                </p>

                <h5>SEÇÃO 4 - MODIFICAÇÕES DO SERVIÇO</h5>
                <p>Reservamos o direito de, a qualquer momento, modificar ou descontinuar o Serviço (ou qualquer parte
                    ou conteúdo do mesmo) sem notificação em qualquer momento.
                    <br><br>
                    Não nos responsabilizados por você ou por qualquer terceiro por qualquer modificação, suspensão ou
                    descontinuação do Serviço.
                    <br><br>
                </p>
                <h5>SEÇÃO 5 - PRECISÃO DE INFORMAÇÕES DE FATURAMENTO E CONTA</h5>
                <p>Reservamos o direito de recusar qualquer pedido que você nos fizer. Podemos, a nosso próprio
                    critério, limitar ou cancelar o número de produtos por pessoa, por domicílio ou por pedido. Tais
                    restrições podem incluir pedidos feitos na mesma conta de cliente, no mesmo cartão de crédito, e/ou
                    pedidos que usam a mesma fatura e/ou endereço de envio. Caso façamos alterações ou cancelemos um
                    pedido, pode ser que o notifiquemos por e-mail e/ou endereço/número de telefone de faturamento
                    fornecidos no momento que o pedido foi feito. Reservamos o direito de limitar ou proibir pedidos
                    que, a nosso critério exclusivo, parecem ser feitos por comerciantes, revendedores ou
                    distribuidores.
                    <br><br>
                    Você concorda em fornecer suas informações de conta e compra completas para todas as compras feitas
                    em nossa loja. Você concorda em atualizar prontamente sua conta e outras informações, incluindo seu
                    e-mail, números de cartão de crédito e datas de validade, para que possamos completar suas
                    transações e contatar você quando preciso.
                    <br><br>
                </p>
                <h5>SEÇÃO 6 - FERRAMENTAS OPCIONAIS</h5>
                <p>Podemos te dar acesso a ferramentas de terceiros que não monitoramos e nem temos qualquer controle.
                    <br><br>
                    Você reconhece e concorda que nós fornecemos acesso a tais ferramentas ”como elas são” e “conforme a
                    disponibilidade” sem quaisquer garantias, representações ou condições de qualquer tipo e sem
                    qualquer endosso. Não nos responsabilizamos de forma alguma pelo seu uso de ferramentas opcionais de
                    terceiros.
                    <br><br>
                    Qualquer uso de ferramentas opcionais oferecidas através do site é inteiramente por sua conta e
                    risco e você se familiarizar e aprovar os termos das ferramentas que são fornecidas por
                    fornecedor(es) terceiro(s).
                    <br><br>
                    Também podemos, futuramente, oferecer novos serviços e/ou recursos através do site (incluindo o
                    lançamento de novas ferramentas e recursos). Tais recursos e/ou serviços novos também devem estar
                    sujeitos a esses Termos de serviço.
                    <br><br>
                </p>
                <h5>SEÇÃO 7 - LINKS DE TERCEIROS</h5>
                <p>Certos produtos, conteúdos e serviços disponíveis pelo nosso Serviço podem incluir materiais de
                    terceiros.
                    <br><br>
                    Os links de terceiros nesse site podem te direcionar para sites de terceiros que não são afiliados a
                    nós. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou precisão. Não garantimos e nem
                    temos obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer
                    outros materiais, produtos ou serviços de terceiros.
                    <br><br>
                    Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de
                    mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com
                    quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas de terceiros e
                    certifique-se que você as entende antes de efetuar qualquer transação. As queixas, reclamações,
                    preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro.

                    <br><br></p>
                <h5>SEÇÃO 8 - COMENTÁRIOS, FEEDBACK, ETC. DO USUÁRIO</h5>
                <p>Se, a nosso pedido, você enviar certos itens específicos (por exemplo, participação em um concurso),
                    ou sem um pedido nosso, você enviar ideias criativas, sugestões, propostas, planos, ou outros
                    materiais, seja online, por e-mail, pelo correio, ou de outra forma (em conjunto chamados de
                    'comentários'), você concorda que podemos, a qualquer momento, sem restrição, editar, copiar,
                    publicar, distribuir, traduzir e de outra forma usar quaisquer comentários que você encaminhar para
                    nós. Não nos responsabilizamos por: (1) manter quaisquer comentários em sigilo; (2) indenizar por
                    quaisquer comentários; ou (3) responder quaisquer comentários.
                    <br><br>
                    Podemos, mas não temos a obrigação, de monitorar, editar ou remover conteúdo que nós determinamos a
                    nosso próprio critério ser contra a lei, ofensivo, ameaçador, calunioso, difamatório, pornográfico,
                    obsceno ou censurável ou que viole a propriedade intelectual de terceiros ou estes Termos de
                    serviço.
                    <br><br>
                    Você concorda que seus comentários não violarão qualquer direito de terceiros, incluindo direitos
                    autorais, marcas registradas, privacidade, personalidade ou outro direito pessoal ou de propriedade.
                    Você concorda que os seus comentários não vão conter material difamatório, ilegal, abusivo ou
                    obsceno. Eles também não conterão nenhum vírus de computador ou outro malware que possa afetar a
                    operação do Serviço ou qualquer site relacionado. Você não pode usar um endereço de e-mail falso,
                    fingir ser alguém diferente de si mesmo, ou de outra forma enganar a nós ou terceiros quanto à
                    origem de quaisquer comentários. Você é o único responsável por quaisquer comentários que você faz e
                    pela veracidade deles. Nós não assumimos qualquer responsabilidade ou obrigação por quaisquer
                    comentários publicados por você ou por qualquer terceiro.

                    <br><br></p>
                <h5>SEÇÃO 9 - INFORMAÇÕES PESSOAIS</h5>
                <p>O envio de suas informações pessoais através da loja é regido pela nossa Política de privacidade. Ver
                    nossa Política de privacidade.

                    <br><br></p>
                <h5>SEÇÃO 10 - ERROS, IMPRECISÕES E OMISSÕES</h5>
                <p>Ocasionalmente, pode haver informações no nosso site ou no Serviço que contém erros tipográficos,
                    imprecisões ou omissões que possam relacionar-se a descrições de produtos, preços, promoções,
                    ofertas, taxas de envio do produto, o prazo de envio e disponibilidade. Reservamos o direito de
                    corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou atualizar informações ou cancelar
                    encomendas caso qualquer informação no Serviço ou em qualquer site relacionado seja imprecisa, a
                    qualquer momento e sem aviso prévio (até mesmo depois de você ter enviado o seu pedido).
                    <br><br>
                    Não assumimos nenhuma obrigação de atualizar, alterar ou esclarecer informações no Serviço ou em
                    qualquer site relacionado, incluindo, sem limitação, a informações sobre preços, exceto conforme
                    exigido por lei. Nenhuma atualização específica ou data de atualização no Serviço ou em qualquer
                    site relacionado, deve ser utilizada para indicar que todas as informações do Serviço ou em qualquer
                    site relacionado tenham sido modificadas ou atualizadas.

                    <br><br></p>
                <h5>SEÇÃO 11 - USOS PROIBIDOS</h5>
                <p>Nós não garantimos, representamos ou justificamos que o seu uso do nosso serviço será pontual,
                    seguro, sem erros ou interrupções.
                    <br><br>
                    Não garantimos que os resultados que possam ser obtidos pelo uso do serviço serão precisos ou
                    confiáveis.
                    <br><br>
                    Você concorda que de tempos em tempos, podemos remover o serviço por períodos indefinidos de tempo
                    ou cancelar a qualquer momento, sem te notificar.
                    <br><br>
                    Você concorda que o seu uso ou incapacidade de usar o serviço é por sua conta e risco. O serviço e
                    todos os produtos e serviços entregues através do serviço são, exceto conforme declarado por nós)
                    fornecidos sem garantia e conforme a disponibilidade para seu uso, sem qualquer representação,
                    garantias ou condições de qualquer tipo, expressas ou implícitas, incluindo todas as garantias
                    implícitas ou condições de comercialização, quantidade, adequação a uma finalidade específica,
                    durabilidade, título, e não violação.
                    <br><br>
                    Em nenhuma circunstância o Heimber, nossos diretores, oficiais, funcionários, afiliados, agentes,
                    contratantes, estagiários, fornecedores, prestadores de serviços ou licenciadores serão responsáveis
                    por qualquer prejuízo, perda, reclamação ou danos diretos, indiretos, incidentais, punitivos,
                    especiais ou consequentes de qualquer tipo, incluindo, sem limitação, lucros cessantes, perda de
                    receita, poupanças perdidas, perda de dados, custos de reposição, ou quaisquer danos semelhantes,
                    seja com base em contrato, ato ilícito (incluindo negligência), responsabilidade objetiva ou de
                    outra forma, decorrentes do seu uso de qualquer um dos serviços ou quaisquer produtos adquiridos
                    usando o serviço, ou para qualquer outra reclamação relacionada de alguma forma ao seu uso do
                    serviço ou qualquer produto, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer
                    conteúdo, ou qualquer perda ou dano de qualquer tipo como resultado do uso do serviço ou qualquer
                    conteúdo (ou produto) publicado, transmitido ou de outra forma disponível através do serviço, mesmo
                    se alertado ​​de tal possibilidade. Como alguns estados ou jurisdições não permitem a exclusão ou a
                    limitação de responsabilidade por danos consequentes ou incidentais, em tais estados ou jurisdições,
                    a nossa responsabilidade será limitada à extensão máxima permitida por lei.

                    <br><br></p>
                <h5>SEÇÃO 12 - ISENÇÃO DE RESPONSABILIDADE DE GARANTIAS; LIMITAÇÃO DE RESPONSABILIDADE</h5>
                <p>Nós não garantimos, representamos ou justificamos que o seu uso do nosso serviço será pontual,
                    seguro, sem erros ou interrupções.
                    <br><br>
                    Não garantimos que os resultados que possam ser obtidos pelo uso do serviço serão precisos ou
                    confiáveis.
                    <br><br>
                    Você concorda que de tempos em tempos, podemos remover o serviço por períodos indefinidos de tempo
                    ou cancelar a qualquer momento, sem te notificar.
                    <br><br>
                    Você concorda que o seu uso ou incapacidade de usar o serviço é por sua conta e risco. O serviço e
                    todos os produtos e serviços entregues através do serviço são, exceto conforme declarado por nós)
                    fornecidos sem garantia e conforme a disponibilidade para seu uso, sem qualquer representação,
                    garantias ou condições de qualquer tipo, expressas ou implícitas, incluindo todas as garantias
                    implícitas ou condições de comercialização, quantidade, adequação a uma finalidade específica,
                    durabilidade, título, e não violação.
                    <br><br>
                    Em nenhuma circunstância o Heimber, nossos diretores, oficiais, funcionários, afiliados, agentes,
                    contratantes, estagiários, fornecedores, prestadores de serviços ou licenciadores serão responsáveis
                    por qualquer prejuízo, perda, reclamação ou danos diretos, indiretos, incidentais, punitivos,
                    especiais ou consequentes de qualquer tipo, incluindo, sem limitação, lucros cessantes, perda de
                    receita, poupanças perdidas, perda de dados, custos de reposição, ou quaisquer danos semelhantes,
                    seja com base em contrato, ato ilícito (incluindo negligência), responsabilidade objetiva ou de
                    outra forma, decorrentes do seu uso de qualquer um dos serviços ou quaisquer produtos adquiridos
                    usando o serviço, ou para qualquer outra reclamação relacionada de alguma forma ao seu uso do
                    serviço ou qualquer produto, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer
                    conteúdo, ou qualquer perda ou dano de qualquer tipo como resultado do uso do serviço ou qualquer
                    conteúdo (ou produto) publicado, transmitido ou de outra forma disponível através do serviço, mesmo
                    se alertado ​​de tal possibilidade. Como alguns estados ou jurisdições não permitem a exclusão ou a
                    limitação de responsabilidade por danos consequentes ou incidentais, em tais estados ou jurisdições,
                    a nossa responsabilidade será limitada à extensão máxima permitida por lei.

                    <br><br></p>
                <h5>SEÇÃO 13 - INDENIZAÇÃO</h5>
                <p>Você concorda em indenizar, defender e isentar Heimber e nossos subsidiários, afiliados, parceiros,
                    funcionários, diretores, agentes, contratados, licenciantes, prestadores de serviços,
                    subcontratados, fornecedores, estagiários e funcionários, de qualquer reclamação ou demanda,
                    incluindo honorários de advogados, por quaisquer terceiros devido à violação destes Termos de
                    serviço ou aos documentos que incorporam por referência, ou à violação de qualquer lei ou os
                    direitos de um terceiro.

                    <br><br></p>
                <h5>SEÇÃO 14 - INDEPENDÊNCIA</h5>
                <p>No caso de qualquer disposição destes Termos de serviço ser considerada ilegal, nula ou ineficaz, tal
                    disposição deve, contudo, ser aplicável até ao limite máximo permitido pela lei aplicável, e a
                    porção inexequível será considerada separada desses Termos de serviço. Tal determinação não
                    prejudica a validade e aplicabilidade de quaisquer outras disposições restantes.

                    <br><br></p>
                <h5>SEÇÃO 15 - RESCISÃO</h5>
                <p>As obrigações e responsabilidades das partes incorridas antes da data de rescisão devem continuar
                    após a rescisão deste acordo para todos os efeitos.
                    <br><br>
                    Estes Termos de Serviço estão em vigor, a menos que seja rescindido por você ou por nós. Você pode
                    rescindir estes Termos de serviço a qualquer momento, notificando-nos que já não deseja utilizar os
                    nossos serviços, ou quando você deixar de usar o nosso site.
                    <br><br>
                    Se em nosso critério exclusivo você não cumprir com qualquer termo ou disposição destes Termos de
                    serviço, nós também podemos rescindir este contrato a qualquer momento sem aviso prévio e você
                    ficará responsável por todas as quantias devidas até a data da rescisão; também podemos lhe negar
                    acesso a nossos Serviços (ou qualquer parte deles).

                    <br><br></p>
                <h5>SEÇÃO 16 - ACORDO INTEGRAL</h5>
                <p>Caso não exerçamos ou executemos qualquer direito ou disposição destes Termos de serviço, isso não
                    constituirá uma renúncia de tal direito ou disposição.
                    <br><br>
                    Estes Termos de serviço e quaisquer políticas ou normas operacionais postadas por nós neste site ou
                    no que diz respeito ao serviço constituem a totalidade do acordo entre nós. Estes termos regem o seu
                    uso do Serviço, substituindo quaisquer acordos anteriores ou contemporâneos, comunicações e
                    propostas, sejam verbais ou escritos, entre você e nós (incluindo, mas não limitado a quaisquer
                    versões anteriores dos Termos de serviço).
                    <br><br>
                    Quaisquer ambiguidades na interpretação destes Termos de serviço não devem ser interpretadas contra
                    a parte que os redigiu.

                    <br><br></p>
                <h5>SEÇÃO 17 - LEGISLAÇÃO APLICÁVEL</h5>
                <p>Esses Termos de serviço e quaisquer acordos separados em que nós lhe fornecemos os Serviços devem ser
                    regidos e interpretados de acordo com as leis de Av Bruno Cassel, 405, São Sebastião do Caí, RS,
                    95760000, Brasil.

                    <br><br></p>
                <h5>SEÇÃO 18 - ALTERAÇÕES DOS TERMOS DE SERVIÇO</h5>
                <p>Você pode rever a versão mais atual dos Termos de serviço a qualquer momento nessa página.
                    <br><br>
                    Reservamos o direito, a nosso critério, de atualizar, modificar ou substituir qualquer parte destes
                    Termos de serviço ao publicar atualizações e alterações no nosso site. É sua responsabilidade
                    verificar nosso site periodicamente. Seu uso contínuo ou acesso ao nosso site ou ao Serviço após a
                    publicação de quaisquer alterações a estes Termos de serviço constitui aceitação dessas alterações.

                    <br><br></p>
                <h5>SEÇÃO 19 - INFORMAÇÕES DE CONTATO</h5>
                <p>As perguntas sobre os Termos de serviço devem ser enviadas para nós através do sac@heimber.com.br.
                    <br><br></p>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>