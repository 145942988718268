import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { Router } from '@angular/router';
import { EmailValidator } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: string;
  roles: string[] = [];
  loading: boolean = false;

  constructor(private authService: AuthService,
     private tokenStorage: TokenStorageService,
     protected router: Router) { }

  ngOnInit() {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    if(localStorage.length > 0){
      this.form.email = localStorage.getItem('email');
      this.form.lembreMe = true;
    }

    if(this.isLoggedIn) {
      this.router.navigate(['dashboard']);
    }
  }

  onSubmit() {
    this.loading = true;
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.data.apitoken);
        this.tokenStorage.saveUser(data.data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().roles;

        if(this.form.lembreMe) {
          localStorage.setItem('email', this.form.email);
        } else {
          localStorage.removeItem('email');
        }
        this.loading = false;
        this.router.navigate(['dashboard']);

      },
      err => {
        this.loading = false;
        this.errorMessage = err.error.erro;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }
  

}