import { Produto } from './produto';
import { Pedido } from './pedido';
import { Pacote } from './pacote';

export class PacoteProduto {

  public id: number;
  public produto: Produto;
  public quantidade: number;
  public valorTotalCustoProduto: number;
  public valorTotalVendaRecebido: number;
  public valorTotalVendaCalculado: number;
  public valorTotalComissao: number;
  public pesoTotal: number;

}
