import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Produto, ProdutoPorSku } from 'src/app/models/produto';
import { ProdutosService } from 'src/app/_services/produtos/produtos.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-produtos-detalhe',
  templateUrl: './produtos-detalhe.component.html',
  styleUrls: ['./produtos-detalhe.component.scss']
})
export class ProdutosDetalheComponent implements OnInit {

  idProduto: string = "";
  loading: boolean = false;
  pacote: ProdutoPorSku;

  formProduto: FormGroup;

  constructor(
    private produtosService: ProdutosService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.idProduto = this.route.snapshot.queryParamMap.get('produto').toString();
    this.getDetalhesPacote();
  };

  createForm(produto: ProdutoPorSku) {
    this.formProduto = new FormGroup({
      titulo: new FormControl(produto.titulo),
      sku: new FormControl(produto.sku),
      custo: new FormControl(produto.precos.custo),
      altura: new FormControl(produto.altura),
      cest: new FormControl(produto.cest),
      cfop: new FormControl(produto.cfop),
      composto: new FormControl(produto.composto),
      comprimento: new FormControl(produto.comprimento),
      descricao: new FormControl(produto.descricao),
      ean: new FormControl(produto.ean),
      estoque: new FormControl(produto.estoque),
      largura: new FormControl(produto.largura),
      cncmest: new FormControl(produto.ncm),
      peso: new FormControl(produto.peso),
      precoBase: new FormControl(produto.precos.precoBase),
      unidadeComercial: new FormControl(produto.unidadeComercial),
      atributos: new FormControl(produto.atributos),
      precosMarketplaces: new FormControl(produto.precosMarketplaces),
      sinonimos: new FormControl(produto.sinonimos),
      imagens: new FormControl(produto.imagens)
    })
  }

  public getDetalhesPacote() {
    this.loading = true;

    this.produtosService.getProdutoBySKU(this.idProduto).subscribe((pacote: ProdutoPorSku) => {
      this.createForm(pacote);
      console.log(this.pacote);
      this.loading = false;
    },
      (error: any) => {
        this.loading = false;
        console.log(error);
      });
  }
}
