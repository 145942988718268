import { Pedido } from './pedido';
import { Loja } from './loja';
import { Impressao } from './impressao';
export class PacoteAdmin {
  public id: number;
  public pedido: Pedido;
  public loja: Loja;
  //public pacotesProduto: PacoteProduto[];
  public situacao: Situacao;
  public valorTotal: number;
  public impressao: Impressao;
  public valorTotalEstimado: number;
  public custoTotal: number;
  public valorComissaoMarketplace: number;
  public valorComissaoMarketplaceEstimado: number;
  public valorComissaoHeimber: number;
  public valorComissaoHeimberEstimado: number;
  public valorFrete: number;
  public valorFreteEstimado: number;
  public valorFretePagoPorCliente: number;
  public valorFretePagoPorVendedor: number;
  public valorDesconto: number;
  public dataCriacaoNota: Date;
  public dataCriacaoEtiqueta: Date;
  public dataEnvioNota: Date;

  constructor(original: Object) {
    Object.assign(this, original);
  }
}

export class TotalGeral {
  public efetivo: Totais;
  public calculado: Totais;
}

export class TotalPorPagina {
  public efetivo: Totais;
  public calculado: Totais;
}

export enum PacoteSituacaoCodigo {
  Pago = 'pago',
  Faturado = 'faturado',
  Expedido = 'expedido',
  EmTransito = 'emtransito',
  Entregue = 'entregue',
  ProblemaEntrega = 'problemanaentrega',
  AguardandoCancelamento = 'aguardandocancelamento',
  AguardandoPagamento = 'aguardandopagamento',
  Cancelado = 'cancelado'
}

export interface Situacao {
  codigo: string;
  descricao: string;
}

export interface Totais {
  comissaoHeimber: number;
  comissaoMarketplace: number;
  custo: number;
  frete: number;
  produtos: number;
  total: number;
}