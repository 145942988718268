import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigMasterEnvironment } from 'src/environments/config.master.environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  public apiHost: string = ConfigMasterEnvironment.environment.baseUrlApi;
  public endpointCliente: string = 'cliente';
  public endpointIECliente: string = 'informarInscricaoEstadual';

  constructor(
    private http: HttpClient
  ) { }

  public informaInscricaoEstadualCliente(idCliente, inscricaoEstadual): Observable<any>
  {
    return new Observable((observable) => {
      
      let bodyComInscricaoEstadual = {
        'inscricaoEstadual': inscricaoEstadual 
      };
      let url =this.apiHost + this.endpointCliente + '/' + idCliente + '/' + this.endpointIECliente;

      this.http.post(url, bodyComInscricaoEstadual).subscribe((response: Response) => {

        console.log(response);
        observable.next(response);

      }, ((error) => {
        console.error(error);
        observable.error(error);
      }));
    });
    
  }
}
