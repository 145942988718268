<div class="mat-elevation-z8 boxContainer">

    <!-- Filtros Listagem -->
    <ng-container>
        <form name="filtros" (ngSubmit)="buscaComFiltros()" #f="ngForm" novalidate class="filtros">
            <mat-card-content class="boxInputs" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field fxLayout="column" appearance="outline" fxFlex="35">
                    <mat-label>SKU</mat-label>
                    <input matInput type="text" class="form-control" name="pesquisaGeral"
                        [(ngModel)]="filtros.pesquisaGeral" minlength="3" #pesquisaGeral="ngModel">
                </mat-form-field>
            </mat-card-content>
            <button mat-stroked-button class="btn-block btnPadrao">Filtrar</button>
        </form>
        <div class="boxBtnSalvar">
            <button (click)="salvarAlteracoes()" mat-stroked-button class="btn-block btnSalvar">Salvar</button>
        </div>
    </ng-container>

    <div class="hot handsontable htRowHeaders htColumnHeaders"
        style="height: calc(100vh - 235px); overflow: hidden; width: 100%;"
        data-originalstyle="height: calc(100vh - 235px); overflow: hidden; width: 100%;">
        <hot-table [afterChange]="detectChanges" [settings]="tableSettings" [data]="dataSourceProdutos" id="dataSource"
            licenseKey="non-commercial-and-evaluation"></hot-table>

    </div>

    <!-- Paginator -->
    <div class="row">
        <div class="paginator">
            <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
                (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPedidosPage($event)">
            </app-pagination>
        </div>
    </div>


</div>

<div class="loading" *ngIf="loading">
    <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>

<mat-container *ngIf="showErrorMessage" class="alert alert-danger alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ errorMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemErro()">
      <span aria-hidden="true">&times;</span>
    </button>
  </mat-container>
  
  <mat-container *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ successMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
      <span aria-hidden="true">&times;</span>
    </button>
  </mat-container>