<div class="mat-elevation-z8 boxContainer">
  <ng-container>
    <div class="boxDetalhesPedido">
      <div>
        <h3>Detalhes Pedido #{{ pacote.id }}</h3>
        <a [routerLink]="['/pedidos']" class="btnBackListagem" title="Voltar">
          <button mat-icon-button class="buttonCloseModal">
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
        </a>
      </div>
      <p class="nomeCampo" style="width: 100%!important;"><b>{{this.pacoteObservacao.infoLote}}</b></p>

      <div class="halfBoxDetalhes" style="margin-right: 2%;">
        <p class="nomeCampo"><b>Data do Pedido:</b> {{ pacote.dataPedido | date:"dd/MM/yyyy" }}</p>
        <p class="nomeCampo"><b>Status do Pedido:</b> {{ pacote.situacao }}</p>
        <p class="nomeCampo"><b>Marketplace:</b> {{ pacote.marketplace.nome }}</p>
        <p class="nomeCampo"><b>Frete Grátis:</b> {{ pacote.valorFrete > 0 ? 'Não' : 'Sim' }}</p>
        <p class="nomeCampo"><b>Rastreio:</b> {{ pacote.trackEnviado }}</p>
        <p class="nomeCampo"><b>Custo Produtos:</b> {{ pacote.custoTotal | currency:'BRL':true }}</p>
        <p class="nomeCampo"><b>Valor Produtos:</b> {{ pacote.valorTotal | currency:'BRL':true }}</p>
        <p class="nomeCampo"><b>Valor Frete:</b> {{ pacote.valorFrete | currency:'BRL':true }}</p>
        <p class="nomeCampo"><b>Comissão Marketplace:</b> {{ pacote.valorComissaoMarketplace | currency:'BRL':true }}
        </p>
        <p class="nomeCampo"><b>Comissão Heimber:</b> {{ pacote.valorComissaoHeimber | currency:'BRL':true }}</p>
        <p class="nomeCampo"><b>Valor Total:</b> {{ pacote.valorTotal | currency:'BRL':true }}</p>
        <p class="nomeCampo"><b>Desconto:</b> {{ pacote.dataCompra | currency:'BRL':true }}</p>
        <p class="nomeCampo"><b>Lucro:</b> {{ pacote.valorTotal - pacote.custoTotal | currency:'BRL':true }}</p>
      </div>

      <div class="halfBoxDetalhes">
        <p class="nomeCampo"><b>Nome:</b> {{ pacote.cliente.nome }}</p>
        <p class="nomeCampo"><b>Email:</b> {{ pacote.cliente.email }}</p>
        <p class="nomeCampo"><b>CPF:</b> {{ pacote.cliente.documento.cpf }}</p>
        <p class="nomeCampo"><b>RG:</b> {{ pacote.cliente.documento.rg }}</p>
        <p class="nomeCampo"><b>Nascimento:</b> {{ pacote.cliente.nascimento | date:"dd/MM/yyyy" }}</p>
        <p class="nomeCampo"><b>Telefone:</b> {{ pacote.cliente.telefone1 }}</p>
        <p class="nomeCampo"><b>CEP:</b> {{ pacote.cliente.endereco.cep }}</p>
        <p class="nomeCampo"><b>Estado:</b> {{ pacote.cliente.endereco.uf }}</p>
        <p class="nomeCampo"><b>Cidade:</b> {{ pacote.cliente.endereco.cidade }}</p>
        <p class="nomeCampo"><b>Bairro:</b> {{ pacote.cliente.endereco.bairro }}</p>
        <p class="nomeCampo"><b>Logradouro:</b> {{ pacote.cliente.endereco.logradouro }}</p>
        <p class="nomeCampo"><b>Número:</b> {{ pacote.cliente.endereco.numero }}</p>
        <p class="nomeCampo"><b>Complemento:</b> {{ pacote.cliente.endereco.complemento }}</p>
      </div>
      <div class="halfBoxDetalhes">
        <mat-form-field style="width: 95%;">
          <mat-label>Observação:</mat-label>
          <textarea matInput [(ngModel)]="pacoteObservacao.observacao" #observacao="ngModel"
            name="observacao"></textarea>
        </mat-form-field>
        <button mat-icon-button (click)="salvaObservacoes()">
          <mat-icon>save</mat-icon>
        </button>
        <p><b>Motivo cancelamento marketplace:</b> {{ pacoteObservacao.motivoCanMkt }}</p>
        <p><b>Codigo rastreio reverso:</b> {{ pacoteObservacao.codigoRastreioReverso }}</p>
      </div>

      <div class="boxDetalhesProduto">
        <table class="table">
          <thead>
            <tr class="tableHeader">
              <th>Imagem</th>
              <th>SKU</th>
              <th>Nome</th>
              <th>Quantidade</th>
              <th>Valor Total Venda</th>
              <th>Valor Total Venda</th>
            </tr>
          </thead>
          <tbody *ngFor="let produto of pacote.pacotesProduto" class="boxProduto">
            <tr>
              <td data-toggle="tooltip" data-placement="top" title="Imagem"><img src="{{ produto.imagemPrincipal }}"
                  class="imgProduct"></td>
              <td data-toggle="tooltip" data-placement="top" title="SKU">
                {{ produto.skuSinonimo ? '[' + produto.skuProduto + '] ' + produto.skuSinonimo : produto.skuProduto}}
              </td>
              <td data-toggle="tooltip" data-placement="top" title="Nome">{{ produto.titulo }}</td>
              <td data-toggle="tooltip" data-placement="top" title="Quantidade">{{ produto.quantidade }}</td>
              <td data-toggle="tooltip" data-placement="top" title="Valor Total Venda">
                {{ produto.lucroBruto | currency:'BRL':true }}</td>
              <td data-toggle="tooltip" data-placement="top" title="Valor Total Venda">
                {{ produto.lucroBruto | currency:'BRL':true }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>

<div class="loading" *ngIf="loading">
  <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>

<!-- Mensagem Sucesso -->
<mat-container *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible fade show mensagemPadrao">
  <mat-card-content>{{ successMessage }}</mat-card-content>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
    <span aria-hidden="true">&times;</span>
  </button>
</mat-container>