<div class="mat-elevation-z8 boxContainer">

    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" id="pills-principal-tab" data-toggle="pill" href="#pills-principal" role="tab"
                aria-controls="pills-principal" aria-selected="true">Principal</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="pills-fical-tab" data-toggle="pill" href="#pills-fical" role="tab"
                aria-controls="pills-fical" aria-selected="false">Fiscal</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="pills-precos-tab" data-toggle="pill" href="#pills-precos" role="tab"
                aria-controls="pills-precos" aria-selected="false">Preços</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="pills-atributos-tab" data-toggle="pill" href="#pills-atributos" role="tab"
                aria-controls="pills-atributos" aria-selected="false">Atributos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="pills-sinonimos-tab" data-toggle="pill" href="#pills-sinonimos" role="tab"
                aria-controls="pills-sinonimos" aria-selected="false">Sinonimos</a>
        </li>
    </ul>

    <form [formGroup]="formProduto">


        <div class="tab-content" id="pills-tabContent">

            <!-- PRINCIPAL -->
            <div class="tab-pane fade show active" id="pills-principal" role="tabpanel"
                aria-labelledby="pills-principal-tab">
                <div class="form-group">
                    <label for="titulo">Nome</label>
                    <input type="text" class="form-control" name="titulo" id="titulo" formControlName="titulo">
                </div>

                <div class="form-group">
                    <label for="sku">SKU</label>
                    <input type="text" class="form-control" name="sku" id="sku" formControlName="sku">
                </div>

                <div class="form-group">
                    <label for="custo">Custo</label>
                    <input type="text" class="form-control" name="custo" id="custo" formControlName="custo">
                </div>


                <div class="form-group">
                    <label for="descricao">Descrição</label>
                    <input type="text" class="form-control" name="descricao" id="descricao" formControlName="descricao">
                </div>

                <div class="form-group">
                    <label for="estoque">Estoque</label>
                    <input type="text" class="form-control" name="estoque" id="estoque" formControlName="estoque">
                </div>

                <div class="form-group">
                    <label for="altura">Altura</label>
                    <input type="text" class="form-control" name="altura" id="altura" formControlName="altura">
                </div>

                <div class="form-group">
                    <label for="comprimento">Comprimento</label>
                    <input type="text" class="form-control" name="comprimento" id="comprimento"
                        formControlName="comprimento">
                </div>

                <div class="form-group">
                    <label for="largura">Largura</label>
                    <input type="text" class="form-control" name="largura" id="largura" formControlName="largura">
                </div>
            </div>

            <!-- FISCAL -->
            <div class="tab-pane fade" id="pills-fical" role="tabpanel" aria-labelledby="pills-fical-tab">
                <div class="form-group">
                    <label for="cest">CEST</label>
                    <input type="text" class="form-control" name="cest" id="cest" formControlName="cest">
                </div>

                <div class="form-group">
                    <label for="cfop">CFOP</label>
                    <input type="text" class="form-control" name="cfop" id="cfop" formControlName="cfop">
                </div>
                <div class="form-group">
                    <label for="ean">EAN</label>
                    <input type="text" class="form-control" name="ean" id="ean" formControlName="ean">
                </div>

            </div>

            <!-- PREÇOS -->
            <div class="tab-pane fade" id="pills-precos" role="tabpanel" aria-labelledby="pills-precos-tab">
                Preços
            </div>

            <!-- ATRIBUTOS -->
            <div class="tab-pane fade" id="pills-atributos" role="tabpanel" aria-labelledby="pills-atributos-tab">
                Atributos
            </div>

            <!-- SINONIMOS -->
            <div class="tab-pane fade" id="pills-sinonimos" role="tabpanel" aria-labelledby="pills-sinonimos-tab">
                Sinonimos
            </div>
        </div>










        <!-- <div class="form-group">
          <label for="tipo">Tipo</label>
          <select class="form-control" name="tipo" id="tipo" formControlName="tipo">
            <option value="1">Pessoa física</option>
            <option value="2">Pessoa jurídica</option>
          </select>
        </div>
      
        <div class="form-group">
          <label>Gênero</label>
      
          <div class="form-check">
            <input class="form-check-input" type="radio" name="genero" id="masculino" [value]="1" formControlName="genero">
            <label class="form-check-label" for="masculino">Masculino</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="genero" id="feminino" [value]="2" formControlName="genero">
            <label class="form-check-label" for="feminino">Feminino</label>
          </div>
        </div>
      
        <div class="form-group">
          <label for="dataNascimento">Data de nascimento</label>
          <input type="date" class="form-control" name="dataNascimento" id="dataNascimento" formControlName="dataNascimento">
        </div>
      
        <div class="form-group">
          <label for="observacao">Observação</label>
          <textarea class="form-control" rows="3" name="observacao" id="observacao" formControlName="observacao"></textarea>
        </div>
      
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="inativo" id="inativo" formControlName="inativo">
          <label class="form-check-label" for="inativo">Inativo</label>
        </div> -->

        <input type="submit" class="btn mt-4 btnPadrao" value="Salvar">
        <a href="#" class="btn mt-4 ml-2 btnPadrao">Cancelar</a>

    </form>

</div>