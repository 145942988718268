<h1 mat-dialog-title>Inserção de Inscrição Estadual</h1>
<div mat-dialog-content>
    <mat-form-field style="display: block;">
        <mat-label>Inscrição Estadual</mat-label>
        <input matInput [(ngModel)]="data.inscricaoEstadual">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button style="width: 30%; background-color: #0b7c6c; color: white;" (click)="onNoClick()"
        cdkFocusInitial>Cancelar</button>
    <button mat-stroked-button class="btn-block btnDefault" style="width: 30%; background-color: #0b7c6c; color: white;"
        [mat-dialog-close]="data.inscricaoEstadual" cdkFocusInitial>Inserir</button>
    <button mat-stroked-button style="width: 30%; background-color: #1d92e0; color: white;" (click)="onIsento(data)"
        cdkFocusInitial>Isento</button>
</div>