import { Component, OnInit } from '@angular/core';
import { Pacote } from 'src/app/models/pacote';
import { Produto, ProdutoPorSku } from 'src/app/models/produto';
import { ResponseListWithPagination } from 'src/app/_services/helpers/helper.service';
import { FiltrosListagemProdutos, ProdutosService } from 'src/app/_services/produtos/produtos.service';

@Component({
  selector: 'app-produtos-list',
  templateUrl: './produtos-list.component.html',
  styleUrls: ['./produtos-list.component.scss']
})
export class ProdutosListComponent implements OnInit {
  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 5;
  from: number = 0;
  to: number = this.qntPorPagina;
  qntTotalRegistros: number = this.qntPorPagina;
  ultimaPagina: number;

  // end Paginação

  // Gerais
  loading = false;
  categoria = '';
  pesquisaGeral = '';
  filtros: FiltrosListagemProdutos = {
    pesquisaGeral: this.pesquisaGeral as string,
    categoria: this.categoria,
  };

  errorMessage: string = '';
  showErrorMessage: boolean = false;
  successMessage: string = '';
  showSuccessMessage: boolean = false;
  // end Gerais

  dataSourceProdutos: Array<Produto> = [];
  dataSourceProdutosPorSku: Array<ProdutoPorSku> = [];


  constructor(
    private produtosService: ProdutosService
  ) { }

  ngOnChange() {
    this.escondeLongeActivePage();
  }

  ngAfterViewChecked() {
    this.escondeLongeActivePage();
  }

  ngOnInit() {
  }

  buscaComFiltros() {
    // this.loading = true;
    // var sku = this.filtros.pesquisaGeral;
    // this.produtosService.getProdutoBySKU(sku).subscribe((produto: ProdutoPorSku) => {
      
    //   this.dataSourceProdutos.length = 0;
    //   if(produto != null){
    //     this.dataSourceProdutosPorSku.push(produto);
    //   }

    //   if (this.dataSourceProdutos.length == 0) {
    //     this.errorMessage = "Nenhum produto encontrado.";
    //     this.showErrorMessage = true;
    //     this.qntTotalRegistros = this.dataSourceProdutos.length;
    //     this.from = 0;
    //     this.to = 0;
    //     this.ultimaPagina = 0;
    //   } else {
    //     this.qntTotalRegistros = 1;
    //     this.from = 1;
    //     this.to = 1;
    //     this.activePage = 1;
    //     this.ultimaPagina = 1;
    //   }
    //   this.loading = false;
    //   console.log(this.dataSourceProdutos);
    // }),

    //   (error) => {
    //     this.loading = false;
    //     let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'

    //   }
  }

  buscaPacotes() {
    this.loading = true;
    this.produtosService.getProdutosList(this.filtros, this.qntPorPagina, this.activePage).subscribe((pacotesTotal: ResponseListWithPagination) => {
      this.dataSourceProdutos = pacotesTotal.data;
      this.qntTotalRegistros = pacotesTotal.meta.total;
      this.from = pacotesTotal.meta.from;
      this.to = pacotesTotal.meta.to;
      this.ultimaPagina = pacotesTotal.meta.last_page;
      localStorage.setItem('recordsPerPage', this.qntPorPagina.toString());
      this.loading = false;

    }),

      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.';

      }
  }


  // Paginação
  escondeLongeActivePage() {
    for (var i = 1; i <= this.ultimaPagina; i++) {
      var aux = document.getElementById(i.toString());
      if (aux != null) {
        if (i < this.activePage - 5 || i > this.activePage + 5) {
          aux.classList.add("hide");
        } else {
          aux.classList.add("show");
          aux.classList.remove("hide");
        }
      }
    }
  }

  displayActivePage(activePageNumber: number) {
    document.getElementById("containerTodo").scroll(0,0);
    this.activePage = activePageNumber;
    this.buscaPacotes();
  }

  displayPedidosPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
    this.buscaPacotes();
  }
  // end Paginação

  fechaMensagemErro() {
    this.errorMessage = "";
    this.showErrorMessage = false;
  }

  fechaMensagemSuccess() {
    this.successMessage = "";
    this.showSuccessMessage = false;
  }
  

}
