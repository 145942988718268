import { Cliente } from './cliente';
import { Marketplace } from './marketplace';
import { DatePipe } from '@angular/common';
import { Situacao } from './pacote';

export class Pedido {

  public id: number;
  public codigo: number;
  public situacao: Situacao;
  public marketplace: Marketplace;
  public cliente: Cliente;
  public valorTotalFrete: number;
  public valorTotalProdutos: number;
  public valorTotalPedido: number;
  public metodoPagamento: string;
  public trackEnviado: string;
  public totalParcelas: number;
  public dataAprovacao: Date;
  public dataCompra: Date;
  public possuiNota: Boolean;
}
