<div class="mat-elevation-z8 boxContainer" id="containerTodo">

    <!-- Filtros Listagem -->
    <ng-container>
        <form name="filtros" class="filtros">
            <mat-card-content class="boxInputs" fxLayout="row">
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>Sku</mat-label>
                    <input matInput type="text" class="form-control" name="pesquisa" [(ngModel)]="sku" minlength="3"
                        #pesquisa="ngModel">
                </mat-form-field>
                <mat-form-field style='padding-left: 10px;'>
                    <mat-select [(selected)]="filtro" [(ngModel)]="filtro" required #codigoCancelamento="ngModel"
                        name="cancelamento" class="mdb-select md-form colorful-select dropdown-danger">
                        <mat-option *ngFor="let item of filtrosDisponiveis" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-stroked-button class="btn-block btnPadrao" (click)="getProdutosAtributos()">Filtrar</button>
                <button mat-stroked-button class="btn-block btnPadrao" style="background-color: #426481;"
                    (click)="salvar()">Salvar</button>
            </mat-card-content>
        </form>


    </ng-container>
    <!-- Final filtros -->

    <!-- Listagem -->
    <mat-card *ngFor="let produto of produtos" class="boxPedido">

        <mat-card-content>
            <table class="table">
                <thead>
                    <tr class="tableHeader">
                        <th>Imagem</th>
                        <th>SKU</th>
                        <th>Nome</th>
                        <th>Atributos opcionais</th>
                        <th>Valor</th>
                        <th>Atributos obrigatorios</th>
                        <th>Valor</th>
                    </tr>
                </thead>

                <td data-toggle="tooltip" data-placement="top" title="Imagem">
                    <img src="{{ produto.imagem }}" class="imgProduct">
                </td>
                <td data-toggle="tooltip" data-placement="top" title="SKU">{{ produto.sku }}</td>
                <td data-toggle="tooltip" data-placement="top" title="Nome">{{ produto.nome }}</td>

                <td data-toggle="tooltip" data-placement="top">
                    <li style="list-style: none;" *ngFor="let atributo of filtrar(produto.atributos,1)">
                        {{atributo.atributo }}
                    </li>
                </td>
                <td data-toggle="tooltip" data-placement="top" title="Valor">
                    <li style="list-style: none;" *ngFor="let atributo of filtrar(produto.atributos,1)">
                        <mat-select [(selected)]="atributo.atributoValorIdMl" [(ngModel)]="atributo.atributoValorIdMl"
                            required #situacao="ngModel" name="atributo">
                            <mat-option *ngFor="let valores of atributo.atributosValoresDisponiveis"
                                [value]="valores.id">
                                {{valores.name}}
                            </mat-option>
                        </mat-select>
                    </li>
                </td>

                <td data-toggle="tooltip" data-placement="top">
                    <li style="list-style: none;" *ngFor="let atributo of filtrar(produto.atributos,0)">
                        {{atributo.atributo }}
                    </li>
                </td>
                <td data-toggle="tooltip" data-placement="top" title="Valor">
                    <li style="list-style: none;" *ngFor="let atributo of filtrar(produto.atributos,0)">
                        <mat-select [(selected)]="atributo.atributoValorIdMl" [(ngModel)]="atributo.atributoValorIdMl"
                            required #situacao="ngModel" name="atributo">
                            <mat-option *ngFor="let valores of atributo.atributosValoresDisponiveis"
                                [value]="valores.id">
                                {{valores.name}}
                            </mat-option>
                        </mat-select>
                    </li>
                </td>

            </table>
        </mat-card-content>
    </mat-card>

    <!-- Paginator -->
    <div class="row">
        <div class="paginator">
            <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
                (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPage($event)">
            </app-pagination>
        </div>
    </div>

</div>

<!-- Loading Spinner -->
<div class="loading" *ngIf="loading">
    <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>

<!-- Mensagem Erro -->
<mat-container *ngIf="showErrorMessage" class="alert alert-danger alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ errorMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemErro()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>

<!-- Mensagem Sucesso -->
<mat-container *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ successMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>