<div style="height: 100vh;" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="boxContainer" *ngIf="novaSenha == 1">
        <mat-card-header>
            <mat-card-title class="tituloRecuperarSenha">Código de verificação</mat-card-title>
        </mat-card-header>
        <mat-card-header>
            <mat-card-title class="subTituloRecuperarSenha">Insira o código que foi enviado para seu email para resetar a senha.</mat-card-title>
        </mat-card-header>

        <form fxLayout="column" fxLayoutAlign="space-around center" name="form" (ngSubmit)="verificaCodigo()" #f="ngForm"
            novalidate>
            <mat-card-content class="boxInputs">
                <mat-form-field fxLayout="column" appearance="outline">
                    <mat-label>Código</mat-label>
                    <input matInput placeholder="email@exemplo.com" type="text" class="form-control" name="codigo"
                        [(ngModel)]="form.codigo" required #codigo="ngModel">
                </mat-form-field>

            </mat-card-content>

            <mat-container *ngIf="!codigoCorreto && mensagemRetorno != ''"
                class="alert alert-danger alert-dismissible fade show">
                <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="limpaMensagens()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </mat-container>

            <button mat-stroked-button class="btn-block btnPadrao">Seguinte</button>
        </form>

    </mat-card>

    <mat-card class="boxContainer" *ngIf="novaSenha == 2">
        <mat-card-header>
            <mat-card-title class="tituloRecuperarSenha">Redefinir Senha</mat-card-title>
        </mat-card-header>

        <form fxLayout="column" fxLayoutAlign="space-around center" name="form" (ngSubmit)="verificaSenhas()" #f="ngForm"
            novalidate>
            <mat-card-content class="boxInputs">
        
                <mat-form-field fxLayout="column" appearance="outline">
                    <mat-label>Nova Senha</mat-label>
                    <input matInput type="password" class="form-control" name="novaSenha"
                        [(ngModel)]="form.novaSenha" required #novaSenha="ngModel">
                </mat-form-field>

                <mat-form-field fxLayout="column" appearance="outline">
                    <mat-label>Confirmar Nova Senha</mat-label>
                    <input matInput type="password" class="form-control" name="novaSenhaConfirma"
                        [(ngModel)]="form.novaSenhaConfirma" required #novaSenhaConfirma="ngModel">
                </mat-form-field>

            </mat-card-content>

            <mat-container *ngIf="!novasSenhasBatem && mensagemRetorno != ''"
                class="alert alert-danger alert-dismissible fade show">
                <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="limpaMensagens()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </mat-container>

            <mat-container *ngIf="!senhaRedefinida && mensagemRetorno != '' && novasSenhasBatem"
                class="alert alert-danger alert-dismissible fade show">
                <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="limpaMensagens()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </mat-container>

            <mat-container *ngIf="senhaRedefinida && mensagemRetorno != ''"
                class="alert alert-success alert-dismissible fade show">
                <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="limpaMensagens()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </mat-container>

            <button mat-stroked-button class="btn-block btnPadrao">Redefinir</button>
        </form>

    </mat-card>

</div>