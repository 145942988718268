import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from './../../_services/token-storage.service';
import { Router } from '@angular/router';
import { PedidosService } from '../../_services/pedidos/pedidos.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  private roles: string[];
  modalImpressaoProduto = false;
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string;
  ocultaHeader: boolean = false;

  constructor(
    private tokenStorage: TokenStorageService,
    protected router: Router,
    protected pedidosService: PedidosService) { }

  ngOnInit() {
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.roles = user.roles;
      if (localStorage.length > 0) {
        this.username = this.tokenStorage.getUser().nome;
      }
    }
    if (Notification.permission === 'default') {
      // console.log('Notification default');
      //   Notification.requestPermission();
    }

    if (Notification.permission === 'denied') {
      // console.log('Notification denied');
      //   return;
    }

    if (Notification.permission === 'granted') {
      // console.log('Notification granted');
      // this.ativarNotificacoes();
    }
  }

  public openLink(link, newTab) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      window.open(link);
    }
  }

  public ativarNotificacoes() {
    var self = this;
    setInterval(function () {
      self.getNotificacoes();
    }, 10000);
  }

  getNotificacoes() {
    this.pedidosService.getPerguntas().subscribe((perguntas: any[]) => {
      console.log(perguntas)
      perguntas.forEach(pergunta => {
        this.notificar(pergunta);
      });
    },
      (error) => {
      }
    );
  }

  notificar(pergunta) {
    var notification = new Notification('Mercado livre', {
      icon: pergunta.linkImagem,
      body: pergunta.pergunta,
    });
    notification.onclick = function () {
      window.open(pergunta.linkAnuncio);
    };
  }


  logout() {
    this.tokenStorage.signOut();
    this.isLoggedIn = false;
    this.router.navigate(['login']);
  }

  changePassword() {
    this.router.navigate(['login/reset-password']);
  }
}