import { Pedido } from './pedido';
import { PacoteProduto } from './pacote-produto';
import { Produto } from './produto';
import { Cliente } from './cliente';
import { Loja } from './loja';
import { Marketplace } from './marketplace';
import { Impressao } from './impressao';
// import { OptionsContextMenu } from '../services/helpers/helper.service';
// import { PacoteProdutoTabela } from '../services/pedido/pacotes.service';

export class Pacote {

  // public id: number;
  public pedido: Pedido;
  public pacotesProduto: PacoteProduto[];
  public situacao: Situacao;
  public impressao: Impressao;
  public valorTotal: number;
  public custoTotal: number;
  public valorComissaoMarketplace: number;
  public valorComissaoHeimber: number;
  public valorFrete: number;
  public valorFreteRecebido: number;
  public dataCriacaoNota: Date;
  public dataCriacaoEtiqueta: Date;
  public dataEnvioNota: Date;
  public atrasado: Date;
  public fulfillment: boolean;
  public trackEnviado: string;
  public nomeRecebedor: string;
  //atributos montados no front
  // public pacotesProdutoTable: List<PacoteProdutoTabela>;
  // public opcoesGerais: OptionsContextMenu[];

  public checked: boolean = false;
  public cliente: Cliente;
  public dataPedido: Date;
  public id: number;
  public loja: Loja;
  public marketplace: Marketplace;
  public produtos: Produto[];
  public status: Situacao;
  public valorTotalPedido: number;
  public nfeGerada: string = "Não Gerada";
  public etiquetaGerada: boolean = false;


}


export enum PacoteSituacaoCodigo {
  Pago = 'pago',
  Faturado = 'faturado',
  Expedido = 'expedido',
  EmTransito = 'emtransito',
  Entregue = 'entregue',
  ProblemaEntrega = 'problemanaentrega',
  AguardandoCancelamento = 'aguardandocancelamento',
  AguardandoPagamento = 'aguardandopagamento',
  Cancelado = 'cancelado'
}

export interface Situacao {
  codigo: string;
  descricao: string;
}
