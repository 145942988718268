export class NotaFiscal {

  public id: number;
  public idPacote: number;
  public numero: number;
  public serie: number;
  public chave: string;
  public dataEmissao: string;
  public situacao: SituacaoNota;
  public xmlAutorizado: string;
  public ambiente: string;
  public modeloDocumento: string;
  public viaDevolucao: boolean;
}

export enum SituacaoNota {
  Autorizada = 'Autorizada',
  Rejeitada = 'Rejeitada',
  EmProcessamento = 'Lote em Processamento',
  Cancelada = 'notacancelada'
}
