import { Component, OnInit } from '@angular/core';
import { SacService } from '../../_services/sac/sac.service';
import { SACPreSaleQuestionDTO, SACPostSaleQuestionHeaderDTO } from '../../models/question-sac.model';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/_helpers/format-datepicker';;
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { trigger, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-sac',
  templateUrl: './sac.component.html',
  styleUrls: ['./sac.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    }
  ],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 }))
      ])
    ])
  ]
})

export class SacComponent implements OnInit {

  constructor(protected sacService: SacService) { }
  //filtros
  sku: string = '';
  tipo: string = 'Pre venda';
  estado: string = 'Nao respondidas';
  ordem: string = 'Ascendente';
  marketplace: string = '-1';
  dataInicial = new Date();
  dataFinal = new Date();
  //Controles de tela
  versaoFiltrosCashe: 'filtros-sac-2707';
  tiposDisponiveis = ['Pre venda', 'Pos venda'];
  ordensDisponiveis = ['Ascendente', 'Descendente'];
  estadosDisponiveis = ['Nao respondidas', 'Respondidas', 'Todas'];
  marketplacesDisponiveis = [];
  loading: boolean = false;
  successMessage: string = '';
  showSuccessMessage: boolean = false;
  errorMessage: string = '';
  showErrorMessage: boolean = false;
  preSalequestions: SACPreSaleQuestionDTO[];
  posSalequestions: SACPostSaleQuestionHeaderDTO[];
  finishConversationLastRefresh: boolean = true;
  respostaPergunta: string = '';
  //identifica qual o tipo de questao que estou trabalhando no momento
  // 1 - pre
  // 2 - pos
  tipoAtual: number = 1;
  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 40;
  from: number = 0;
  to: number = this.qntPorPagina;
  ultimaPagina: number;
  qntTotalRegistros: number = this.qntPorPagina;

  ngOnInit() {
    this.getMarketplacesSac();
    this.carregaFiltro();
    this.getQuestions();
  }

  getMarketplacesSac() {
    this.sacService.getMarketplacesSac().subscribe((resultado: any) => {
      this.marketplacesDisponiveis = resultado;
    }),
      (error) => {
        this.loading = false;
        this.qntTotalRegistros = 0;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }

  getQuestions(salvaFiltro = false) {
    if (salvaFiltro) {
      this.salvaFilto();
    }
    this.loading = true;
    var filters = this.sacService.buildFiltersQueryString(this.estado, this.sku, this.dataInicial, this.dataFinal, this.marketplace, this.ordem, this.qntPorPagina, this.activePage);
    if (this.tipo == 'Pre venda') {
      this.sacService.getPreSaleQuestion(filters).subscribe((resultado: any) => {
        this.tipoAtual = 1;
        this.posSalequestions = [];
        this.preSalequestions = resultado.resultado;
        resultado.paginacao.info.actualPage++;
        this.qntTotalRegistros = resultado.paginacao.total;
        this.from = resultado.paginacao.info.skip;
        this.to = resultado.paginacao.info.skip + resultado.resultado.length;
        this.loading = false;
        this.finishConversationLastRefresh = false;
      }),
        (error) => {
          this.loading = false;
          this.qntTotalRegistros = 0;
          this.from = 0;
          this.to = 0;
          this.ultimaPagina = 0;
          let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
        }
    } else {
      this.posSalequestions = [];
      this.sacService.getPostSaleQuestion(filters).subscribe((resultado: any) => {
        this.tipoAtual = 2;
        this.preSalequestions = [];
        this.posSalequestions = resultado.resultado;
        resultado.paginacao.info.actualPage++;
        this.qntTotalRegistros = resultado.paginacao.total;
        this.from = resultado.paginacao.info.skip;
        this.to = resultado.paginacao.info.skip + resultado.resultado.length;
        this.refreshPostSaleConversations();
        this.loading = false;
      }),
        (error) => {
          this.loading = false;
          this.qntTotalRegistros = 0;
          this.from = 0;
          this.to = 0;
          this.ultimaPagina = 0;
          let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
        }
    }

  }
  // Mensagens
  fechaMensagemErro() {
    this.errorMessage = "";
    this.showErrorMessage = false;
  }

  refreshPostSaleConversations() {
    var self = this;
    setInterval(function () {
      if (self.posSalequestions != null && self.posSalequestions.length > 0) {
        if (self.finishConversationLastRefresh) {
          // console.log("REFRESH");
          self.finishConversationLastRefresh = false;
          self.posSalequestions.forEach(question => {
            self.refreshConversation(question);
          });
          // console.log("FINALIZOU REFRESH");
          self.finishConversationLastRefresh = true;
        }
      }
    }, 5000);
  }

  refreshConversation(question) {
    this.sacService.RefreshConversation(question.HeaderId).subscribe((retorno: any) => {
      // console.log(retorno);
      this.loading = false;
      if (retorno) {
        if (retorno.length > question.Conversations.length) {
          for (let i = question.Conversations.length; i < retorno.length; i++) {
            question.Conversations.push(retorno[i]);
          }
        }

        for (let i = 0; i < retorno.length; i++) {
          for (let j = 0; j < question.Conversations.length; j++) {
            if (retorno[i].id == question.Conversations[j].id) {
              question.Conversations[j].ConfirmedDelivery = retorno[i].ConfirmedDelivery;
              break;
            }
          }
        }
        
      }
    }),
      (error) => {
        this.loading = false;
        this.qntTotalRegistros = 0;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }

  deletePreSaleQuestion(question) {
    this.loading = true;
    this.sacService.DeletePreSaleQuestion(question.QuestionId).subscribe((retorno: any) => {
      this.sucesso("Enviado para o servico de SAC para ser deletado.");
      var indexOf = this.preSalequestions.indexOf(question);
      if (indexOf > -1) {
        this.preSalequestions.splice(indexOf, 1);
      }

      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        this.qntTotalRegistros = 0;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }

  }

  sendResponse(question) {
    if (question.V1AnsweredText == null || question.V1AnsweredText == '' || question.V1AnsweredText.length < 5) {
      this.erro("A resposta deve possuir ao menos 5 caracteres.");
      return;
    }

    this.loading = true;
    //Pre Venda
    if (question.Type == 1) {
      this.sacService.AnswerQuestion(question.QuestionId, question.V1AnsweredText).subscribe((retorno: any) => {
        console.log(retorno);
        this.loading = false;
        if (retorno.resultado) {
          if (retorno.Mensagem) {
            this.erro(retorno.Mensagem);
          } else {
            question.Answered = true;
            var indexOf = this.preSalequestions.indexOf(question);
            if (indexOf > -1) {
              this.preSalequestions.splice(indexOf, 1);
            }
            this.sucesso("Enviado para o servico de SAC com sucesso!");
          }
        } else {
          this.erro("Ocorreu um erro ao responder a mensagem.");
        }
      }),
        (error) => {
          this.loading = false;
          this.qntTotalRegistros = 0;
          this.from = 0;
          this.to = 0;
          this.ultimaPagina = 0;
          let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
        }
      //POS VENDA
    } else {
      this.sacService.AnswerPostSaleQuestion(question.HeaderId, question.V1AnsweredText).subscribe((retorno: any) => {
        // console.log(retorno);
        if (retorno.resultado) {
          if (retorno.Mensagem) {
            this.erro(retorno.Mensagem);
          } else {
            this.sucesso("Enviado para o servico de SAC com sucesso!");
            question.V1AnsweredText = '';
            // this.refreshConversation(question);
          }
        } else {
          this.erro("Ocorreu um erro ao responder a mensagem.");
        }

        this.loading = false;
      }),
        (error) => {
          this.loading = false;
          this.qntTotalRegistros = 0;
          this.from = 0;
          this.to = 0;
          this.ultimaPagina = 0;
          let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
        }
    }
  }

  carregaFiltro() {
    var filtro = JSON.parse(window.localStorage.getItem(this.versaoFiltrosCashe));
    if (filtro != null) {
      if (filtro.sku) {
        this.sku = filtro.sku;
      }
      if (filtro.tipo) {
        this.tipo = filtro.tipo;
      }
      if (filtro.estado) {
        this.estado = filtro.estado;
      }
      if (filtro.dataInicial) {
        this.dataInicial = filtro.dataInicial;
      }
      if (filtro.dataFinal) {
        this.dataFinal = filtro.dataFinal;
      }
      if (filtro.ordem) {
        this.ordem = filtro.ordem;
      }
      if (filtro.marketplace) {
        this.marketplace = filtro.marketplace;
      }
    }
  }

  salvaFilto() {
    window.localStorage.removeItem(this.versaoFiltrosCashe);
    var filtros = new FiltrosSAC;
    filtros.dataInicial = this.dataInicial;
    filtros.dataFinal = this.dataFinal;
    filtros.estado = this.estado;
    filtros.tipo = this.tipo;
    filtros.sku = this.sku;
    filtros.ordem = this.ordem;
    filtros.marketplace = this.marketplace;
    window.localStorage.setItem(this.versaoFiltrosCashe, JSON.stringify(filtros));
  }

  fechaMensagemSuccess() {
    this.successMessage = "";
    this.showSuccessMessage = false;
  }

  public sucesso(msg) {
    this.showSuccessMessage = true;
    this.successMessage = msg;
    setTimeout(() => {
      this.showSuccessMessage = false;
      this.successMessage = '';
    }, 5000);
  }

  public erro(msg) {
    this.showErrorMessage = true;
    this.errorMessage = msg;
  }

  displayActivePage(activePageNumber: number) {
    document.getElementById("containerTodo").scroll(0, 0);
    this.activePage = activePageNumber;
    this.getQuestions();
  }

  displayPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
    this.getQuestions();
  }


}

class FiltrosSAC {
  sku: string = '';
  tipo: string = 'Pre venda';
  estado: string = 'Nao respondidas';
  ordem: string = '';
  marketplace: string = '';
  dataInicial = new Date();
  dataFinal = new Date();
}