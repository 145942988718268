import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }
  public chegouNaUltimaPosicao(array: any[], posicao: number): boolean {
    return (array.length === (posicao + 1)) ? true : false;
  }

  public removeAcentos(text: string) {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    return text;
  }

  public downloadPDF(pdfBase64, filename) {
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;

    if (navigator.userAgent.includes('Firefox')) {
      window.open(linkSource);
    }
    else {
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    }
  }

  public base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  public downloadXMLFile(xml, filename) {
    let element = document.createElement('a');
    let blob = new Blob([xml], { type: 'text/plain' });
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    element.dataset.downloadurl = ['text/plain', element.download, element.href].join(':');
    element.draggable = true;
    element.classList.add('dragout');
    element.click();
  }

  public downloadHtml(htmlBlob, filename) {
    let url = window.URL.createObjectURL(htmlBlob);

    if (navigator.userAgent.includes('Firefox')) {
      window.open(url);
    }
    else {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = '_blank';
      downloadLink.download = filename;
      downloadLink.click();
    }
  }

  public geraTabelaHtml(lista: any[], barcode: any) {
    let styleTagsComBorda = 'style="text-align:center; border: 1px solid;height: 70px;width:100%';
    let styleTagsComBordaSemTop = 'style="text-align:center; border-left: 1px solid;border-right: 1px solid;border-bottom: 1px solid;height: 70px;width:100%';
    let styleTagsComBordaSemBottom = 'style="text-align:center; border-left: 1px solid;border-right: 1px solid;border-top: 1px solid;;height: 70px;width:100%';


    let styleTagsLinha = 'style="text-align:center;border-collapse: collapse;height: 70px;width:100%';
    let styleTagsTitulo = 'style="text-align:center; border: 1px solid;';
    let styleTags = 'style="text-align:center;border-collapse: collapse;height: 70px;width:11%';
    let styleTagsComBordaRightPontilhada = 'style="text-align:center;border-collapse: collapse;height: 70px;width:11%;border-right: 1px dotted';

    let tableHtml = '<table style="text-align:center;border-collapse: collapse;width:100%;-webkit-print-color-adjust: exact;">';
    let lineTitles = '';
    let linesContent = '';

    let idsPacotesAgrupados = [];
    let temCor = false;

    let nomesColunas: string[] = [];
    let linhasIndexComComposicoes: number[] = [];
    lista.forEach((linha, index) => {
      let compProperty = linha["Composicoes"];
      if (compProperty != null && Array.isArray(compProperty) && compProperty.length > 0) {
        linhasIndexComComposicoes.push(index);
      }
    });

    lista.forEach((linha, index) => {
      let produtoComComposicao = false;
      //é um produto com composicao
      if (linhasIndexComComposicoes.includes(index)) {
        produtoComComposicao = true;
      }

      let idPacoteAtual = linha['ID Pedido'];
      //borda separando cada Pedido
      if (!idsPacotesAgrupados.includes(idPacoteAtual)) {
        idsPacotesAgrupados.push(idPacoteAtual);
        if ((idsPacotesAgrupados.indexOf(idPacoteAtual) % 2) == 0) {
          temCor = true;
        }
        else {
          temCor = false;
        }
      }

      if ((index % 2) == 0) {
        linesContent += '<tr ' + styleTagsLinha;
      }
      else if (produtoComComposicao == false) {
        //se o anterior é composicao
        if (linhasIndexComComposicoes.includes(index - 1)) {
          linesContent += '<tr ' + styleTagsComBordaSemTop;
          //se o proximo é composicao
        } else if (linhasIndexComComposicoes.includes(index + 1)) {
          linesContent += '<tr ' + styleTagsComBordaSemBottom;
        } else {
          linesContent += '<tr ' + styleTagsComBorda;
        }
      } else {
        linesContent += '<tr style="text-align:center;';
      }

      if (temCor) {
        linesContent += 'background-color: #8080805c;';
      }
      if (produtoComComposicao) {
        linesContent += 'border-left:1px dotted;border-top:1px dotted; border-collapse: collapse;height: 70px;width:100%;';
      }

      linesContent += '">';

      for (let propriedade in linha) if (linha.hasOwnProperty(propriedade)) {
        const coluna = linha[propriedade];

        if (index === 0 && propriedade.toLowerCase().includes('composicoes') == false) {
          nomesColunas.push(propriedade);
        }

        //se for produto com composicao só quero mostrar a composicao, nome do cliente e nmero do pedido
        if (produtoComComposicao) {
          if (propriedade.toLowerCase().includes('sku') ||
            propriedade.toLowerCase().includes('foto') ||
            propriedade.toLowerCase().includes('titulo') ||
            propriedade.toLowerCase().includes('qtd') ||
            propriedade.toLowerCase().includes('imagem') ||
            propriedade.toLowerCase().includes('kg total')) {
            // linesContent += '<td></td>';
            continue;
          }
        }

        if (propriedade.toLowerCase().includes('foto') || propriedade.toLowerCase().includes('imagem')) {
          let styleImageLine = 'style="text-align:center;width:6%;"';
          linesContent += '<td ' + styleImageLine + '>';
          linesContent += '<img src="' + coluna + '" style="width: 60px;">';
        }

        //COMPOSICOES
        else if (propriedade.toLowerCase().includes('composicoes') && coluna.length > 0) {

          for (let i = 0; i < coluna.length; i++) {
            //Quando for o utlimo coloco linha pontilhada na bottom
            if (i == coluna.length - 1) {
              linesContent += '<tr style="border-left: 1px dotted;border-bottom: 1px dotted;border-collapse: collapse;height: 70px;width:100%"><td></td><td></td>';
            } else if (i > 0) {
              linesContent += '<tr style="border-left: 1px dotted;border-collapse: collapse;height: 70px;width:100%"><td></td><td></td>';
              // linesContent += '<td></td><td></td>';
            }
            for (let propriedadeComposicao in coluna[i]) {
              if (coluna[i].hasOwnProperty(propriedadeComposicao)) {
                const colunaCom = coluna[i][propriedadeComposicao];
                if (propriedadeComposicao.toLowerCase().includes('foto') || propriedadeComposicao.toLowerCase().includes('imagem')) {
                  let styleImageLine = 'style="text-align:center;width:6%;"';
                  linesContent += '<td ' + styleImageLine + '>';
                  linesContent += '<img src="' + colunaCom + '" style="width: 60px;">';
                } else {
                  switch (propriedadeComposicao) {
                    case 'ID Pedido':
                      linesContent += '<td style="text-align:center;width:6%;">';
                      break;
                    case 'Cliente':
                      linesContent += '<td style="text-align:center;width:11%;">';
                      break;
                    case 'SKU':
                      linesContent += '<td style="text-align:center;width:8%;">';
                      break;
                    case 'Titulo':
                      linesContent += '<td style="text-align:center;width:47%;">';
                      break;
                    case 'Qtd':
                      linesContent += '<td ' + styleTags + ';font-size: 35px;font-weight: bold;">';
                      break;
                    default:
                      linesContent += '<td ' + styleTags + '">';
                      break;
                  }
                  linesContent += colunaCom;
                }
                linesContent += '</td>';
              }
            }
            linesContent += '<td style="border-right: 1px dotted;"></td>'
            linesContent += '</tr>';

          }
        }
        else {
          switch (propriedade) {
            case 'ID Pedido':
              linesContent += '<td style="text-align:center;width:6%;">';
              break;
            case 'Cliente':
              linesContent += '<td style="text-align:center;width:11%;">';
              break;
            case 'SKU':
              linesContent += '<td style="text-align:center;width:8%;">';
              break;
            case 'Titulo':
              linesContent += '<td style="text-align:center;width:47%;">';
              break;
            case 'Qtd':
              linesContent += '<td ' + styleTags + ';font-size: 30px;font-weight: bold;">';
              break;
            default:
              if (produtoComComposicao) {
                linesContent += '<td ' + styleTagsComBordaRightPontilhada + '">';
              } else {
                linesContent += '<td ' + styleTags + '">';
              }
              break;
          }

          linesContent += coluna;
        }
        linesContent += '</td>';
      }
      linesContent += '</tr>';

      // if (fechaDivAgrupamento)
      // {
      //   linesContent += '</div>';
      // }
    });

    lineTitles += '<tr ' + styleTagsTitulo + '">';
    nomesColunas.forEach((nomeColuna: string) => {

      lineTitles += '<th ' + styleTagsTitulo + 'width:';
      switch (nomeColuna) {
        case 'ID Pedido':
        case 'Foto':
          lineTitles += "6%";
          break;
        case 'SKU':
          lineTitles += "8%";
          break;
        case 'Titulo':
          lineTitles += "47%";
          break;
        default:
          lineTitles += "11%";
          break;
      }

      lineTitles += ';">';
      lineTitles += nomeColuna;
      lineTitles += '</th>';
    });
    lineTitles += '</tr>';

    tableHtml += lineTitles;
    tableHtml += linesContent;
    tableHtml += '</table>';
    tableHtml += barcode.base64;
    tableHtml += barcode.lote;

    let htmlBlob = new Blob([tableHtml], { type: 'text/html' });
    return htmlBlob;
  }


  public exibeImagemComHttps(imagem) {
    imagem = imagem.replace('http://127.0.0.1:8000', 'https://apiv1.webermaq.com');
    imagem = imagem.replace('homologacao.', '');
    imagem = imagem.replace('http:', 'https:');

    return imagem;
  }

  public arrayUnique(arrayDuplicate) {
    let distictArray = arrayDuplicate.filter((element, index) => {
      arrayDuplicate.indexOf(element) === index
    });
    return distictArray;
  }

}

export class ResponseListWithPagination {
  public links: any;
  public meta: any;
  public data: any[];

  constructor(data: any[], meta: any, links: any) {
    this.data = data;
    this.links = links;
    this.meta = meta;
  }
}

export class ResponseListWithPaginationDashboard extends ResponseListWithPagination {

  public totalGeral: any;
  public totalPorPagina: any;

  constructor(data: any[], meta: any, links: any, totalGeral: any, totalPorPagina: any) {
    super(data, meta, links);
    this.data = data;
    this.links = links;
    this.meta = meta;
    this.totalGeral = totalGeral;
    this.totalPorPagina = totalPorPagina;
  }
}

export class ResponseList {
  public TotalItems: number;
  public TotalPages: number;
  public Items: any[];

  constructor(totalItems: number, totalPages: number, items: any[]) {
    this.TotalItems = totalItems;
    this.TotalPages = totalPages;
    this.Items = items;
  }
}
