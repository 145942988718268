<div class="d-flex" *ngIf="isLoggedIn">

    <!-- Sidebar -->
    <div [ngClass]="{'toggled': mostraMenu === true}" class="bg-light border-right" id="sidebar-wrapper"
        (mouseover)="mostraMenu = true;" (mouseout)="mostraMenu = false;">
        <div class="sidebar-heading"> </div>
        <div class="list-group list-group-flush">
            <a href="/dashboard" class="list-group-item list-group-item-action bg-light linkAtivo">Dashboard</a>
            <a href="/pedidos" class="list-group-item list-group-item-action bg-light linkAtivo">Pedidos</a>
            <a href="/sac" class="list-group-item list-group-item-action bg-light linkAtivo">SAC</a>
            <span href="#" class="list-group-item list-group-item-action bg-light linkAtivo dropdown-btn"
                style="cursor: pointer;">Produtos
                <i class="fa fa-caret-down"></i>
            </span>
            <div class="list-group list-group-flush dropdown-container">
                <a href="/categoriasProdutos"
                    class="list-group-item list-group-item-action bg-light linkAtivo">Categorias Ml
                    produtos</a>
                <a href="/produtosAtributo" class="list-group-item list-group-item-action bg-light linkAtivo">Atributos
                    produtos</a>
                <a href="/produtos-edicao-massa"
                    class="list-group-item list-group-item-action bg-light linkAtivo">Listagem Edição em Massa</a>
                <a href="/produtos" class="list-group-item list-group-item-action bg-light linkAtivo">Listagem</a>
            </div>
        </div>

    </div>
    <!-- /#sidebar-wrapper -->

</div>
<!-- /#wrapper -->