import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helpers/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PacotesService } from 'src/app/_services/pedidos/pacotes.service';
import { Pacote } from 'src/app/models/pacote';
import { PacoteObservacao } from 'src/app/models/pacote-observacao';


@Component({
  selector: 'app-pedidos-detalhe',
  templateUrl: './pedidos-detalhe.component.html',
  styleUrls: ['./pedidos-detalhe.component.scss']
})
export class PedidosDetalheComponent implements OnInit {
  idPedido: string = '';
  pacote: any;
  loading: boolean;
  showSuccessMessage: boolean;
  successMessage: string = 'Observações salvas com sucesso.';
  pacoteObservacao: PacoteObservacao;

  constructor(
    private pacotesService: PacotesService,
    private router: Router,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.idPedido = this.route.snapshot.queryParamMap.get('pedido').toString();
    this.loading = true;
    this.pacoteObservacao = new PacoteObservacao();
    this.getDetalhesPacote();
    this.getObservacoes();
    this.loading = false;
  };

  public getDetalhesPacote() {
    this.pacotesService.getPedidoDetalhes(this.idPedido).subscribe((pacote: any) => {
      this.pacote = pacote;
    },
      (error: any) => {
        this.loading = false;
        console.log(error);
      });
  }

  getObservacoes() {
    this.pacotesService.getPacoteObservacao(this.idPedido).subscribe((pacoteObser: PacoteObservacao) => {
      this.pacoteObservacao = pacoteObser;
    },
      (error: any) => {
        this.loading = false;
        console.log(error);
      });
  }

  salvaObservacoes() {
    this.pacotesService.savePacoteObservacao(this.pacoteObservacao).subscribe((pacoteObs: any) => {
      this.pacoteObservacao = pacoteObs;
      this.showSuccessMessage = true;
    },
      (error: any) => {
        this.loading = false;
        console.log(error);
      });
  }

  fechaMensagemSuccess() {
    this.showSuccessMessage = false;
  }

}

