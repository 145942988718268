import { Component, OnInit } from '@angular/core';
import { ProdutoAtributo } from 'src/app/models/produto-atributo.model';
import { ProdutosService } from '../../_services/produtos/produtos.service';
@Component({
  selector: 'app-produto-atributo',
  templateUrl: './produto-atributo.component.html',
  styleUrls: ['./produto-atributo.component.scss']
})
export class ProdutoAtributoComponent implements OnInit {

  produtos: ProdutoAtributo[];
  loading: boolean = false;
  sku: string;
  filtro: string = 'Todos';
  filtrosDisponiveis = ['Todos', 'Nao preenchidos obrigatorios', 'Nao preenchidos opcionais', 'Preenchidos'];
  successMessage: string = '';
  showSuccessMessage: boolean = false;
  errorMessage: string = '';
  showErrorMessage: boolean = false;

  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 40;
  from: number = 0;
  to: number = this.qntPorPagina;
  ultimaPagina: number;
  qntTotalRegistros: number = this.qntPorPagina;

  constructor(protected produtoService: ProdutosService) { }

  ngOnInit() {
    this.getProdutosAtributos();
  }

  getProdutosAtributos() {
    this.loading = true;
    this.produtoService.getProdutosAtributos(this.sku, this.filtro, this.qntPorPagina, this.activePage).subscribe((resultado: any) => {
      this.produtos = resultado.resultado;

      this.qntTotalRegistros = resultado.paginacao.total;
      this.from = resultado.paginacao.from;
      this.to = resultado.paginacao.to;
      this.ultimaPagina = resultado.paginacao.last_page;

      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }

  filtrar(atrs: ProdutoAtributo[], opcional): ProdutoAtributo[] {
    var elementos = [];
    atrs.forEach(element => {
      if (element.opcional == opcional) {
        elementos.push(element);
      }
    });
    return elementos;
  }

  salvar() {
    this.loading = true;
    this.produtoService.saveProdutosAtributos(this.produtos).subscribe((response: any) => {
      console.log(response);
      if (response == 200) {
        this.showSuccessMessage = true;
        this.successMessage = 'Atributos salvos com sucesso!';
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'Ocorreu um erro ao salvar os atributos.';
      }
      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }


  displayActivePage(activePageNumber: number) {
    document.getElementById("containerTodo").scroll(0, 0);
    this.activePage = activePageNumber;
    this.getProdutosAtributos();
  }

  displayPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
    this.getProdutosAtributos();
  }

  // Mensagens
  fechaMensagemErro() {
    this.errorMessage = "";
    this.showErrorMessage = false;
  }

  fechaMensagemSuccess() {
    this.successMessage = "";
    this.showSuccessMessage = false;
  }



}
