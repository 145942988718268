
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ConfigMasterEnvironment } from '../../../environments/config.master.environment';

import { Pedido } from '../../models/pedido';
import { Cliente } from '../../models/cliente';

// import { ClientesService } from '../pessoa/clientes.service';
// import { MarketplacesService } from '../marketplace/marketplaces.service';
import { Marketplace } from '../../models/marketplace';

const TOTAL_PAGES = 7;


@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  public apiHost: string = ConfigMasterEnvironment.environment.baseUrlApi;
  private endpointPedidos: string = 'pedido';

  constructor(
    private http: HttpClient,
    // private clientesServices: ClientesService,
    //private marketplacesService: MarketplacesService
  ) {
  }

  load(page: number, pageSize: number): Observable<any[]> {
    const startIndex = ((page - 1) % TOTAL_PAGES) * pageSize;

    return this.http.get(this.apiHost + this.endpointPedidos)
      .pipe(
        map((pedidosArray: any[]) =>
          pedidosArray.splice(startIndex, pageSize)
        ),
      );
  }

  public convertArrayInPedidoList(pedidosArray: any[]): Observable<Pedido[]> {
    return new Observable((observable) => {

      let pedidos: Pedido[] = [];

      pedidosArray.forEach(pedidoArray => {
        let pedido = new Pedido();
        pedido.id = pedidoArray.id ? pedidoArray.id : null;
        pedido.possuiNota = pedidoArray.idNotaEnviada != null;
        pedido.codigo = pedidoArray.codigo;
        pedido.situacao = { codigo: pedidoArray.situacao.codigo, descricao: pedidoArray.situacao.descricao };
        pedido.dataAprovacao = pedidoArray.dataAprovacao;
        pedido.dataCompra = pedidoArray.dataCompra;
        // pedido.cliente = this.clientesServices.convertObjectInClienteModel(pedidoArray.cliente);
        //pedido.marketplace = this.marketplacesService.convertObjectInMarketplaceModel(pedidoArray.marketplace);
        pedido.totalParcelas = pedidoArray.totalParcelas;
        pedido.valorTotalPedido = pedidoArray.valorTotal;
        pedido.valorTotalProdutos = pedidoArray.custoTotal;
        pedido.valorTotalFrete = pedidoArray.valorFrete;

        pedidos.push(pedido);
      });

      observable.next(pedidos);
    });
  }


  public getPerguntas(): Observable<any[]> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidos + '/getPerguntas').subscribe((response : any[]) => {
        observable.next(response);
      },
      (error: any) => {
        console.log(error);
      });
    });
  }

}
