<div style="height: 100vh;" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="boxContainer">
      <mat-card-header>
        <mat-card-title class="tituloCadastro">Cadastro de Usuário</mat-card-title>
      </mat-card-header>
      <mat-card-header>
        <mat-card-title class="subTituloCadastro">Insira os seus dados para cadastro na Heimber.</mat-card-title>
    </mat-card-header>
  
      <form fxLayout="column" fxLayoutAlign="space-around center" *ngIf="!isLoggedIn" name="form"
      (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  
        <mat-card-content class="boxInputs">
          <mat-form-field fxLayout="column" appearance="outline">
            <mat-label>Nome de Usuário</mat-label>
            <input matInput placeholder="nomeDeUsuário" type="text" class="form-control" name="nome"
              [(ngModel)]="form.nome" required #nome="ngModel">
          </mat-form-field>
          <mat-form-field fxLayout="column" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="email@exemplo.com" type="text" class="form-control" name="email"
              [(ngModel)]="form.email" required #email="ngModel">
          </mat-form-field>
          <mat-form-field fxLayout="column" appearance="outline">
            <mat-label>Senha</mat-label>
            <input matInput type="text" class="form-control" name="senha"
              [(ngModel)]="form.senha" required #senha="ngModel">
          </mat-form-field>
          <mat-form-field fxLayout="column" appearance="outline">
            <mat-label>Confirmar Senha</mat-label>
            <input matInput type="text" class="form-control" name="confirmaSenha"
              [(ngModel)]="form.confirmaSenha" required #confirmaSenha="ngModel">
          </mat-form-field>
  
          <!-- <mat-form-field fxLayout="column">
            <mat-label>Escolha função...</mat-label>
            <mat-select [(value)]="selected">
              <mat-option [value]="roles" *ngFor="let roles of Roles">{{roles}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
  
        </mat-card-content>

        <mat-container *ngIf="!senhasBatem && mensagemRetorno != ''" class="alert alert-danger alert-dismissible fade show">
          <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </mat-container>

        <mat-container *ngIf="isSuccessful && mensagemRetorno != ''" class="alert alert-success alert-dismissible fade show">
          <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </mat-container>

        <button mat-stroked-button class="btn-block btnPadrao">Cadastrar</button>
  
      </form>
    </mat-card>
  </div>
  