import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Produto, ProdutoPorSku } from '../../models/produto';
import { ConfigMasterEnvironment } from '../../../environments/config.master.environment';
import { ResponseListWithPagination } from '../helpers/helper.service';
import { ProdutoAtributo } from 'src/app/models/produto-atributo.model';
import { ProdutoCategoria } from 'src/app/models/produto-categoria.model';

export interface FiltrosListagemProdutos {
  pesquisaGeral: string,
  categoria: string
}

@Injectable({
  providedIn: 'root'
})
export class ProdutosService {

  public apiHost: string = ConfigMasterEnvironment.environment.baseUrlApi;
  private endpointProdutos: string = 'produto';

  constructor(
    private http: HttpClient
  ) {
  }

  public getProdutosList(filtros: FiltrosListagemProdutos, pageSize: number, paginaAtual: number): Observable<ResponseListWithPagination> {
    let queryString = '?limit=' + pageSize;
    queryString += '&page=' + paginaAtual;
    queryString += (filtros.pesquisaGeral !== "") ? '&todos=' + filtros.pesquisaGeral : '';

    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointProdutos + queryString).subscribe((pacotesArray: any) => {
        let pacotesResponse = new ResponseListWithPagination(pacotesArray['data'], pacotesArray['meta'], pacotesArray['links']);
        if (pacotesResponse.data.length == 0) {
          observable.next(pacotesResponse);
        }
        this.convertArrayInProdutoList(pacotesResponse.data).subscribe((pacotes: any[]) => {
          pacotesResponse.data = pacotes;
          observable.next(pacotesResponse);
        });
      });
    });
  }

  public getProdutoBySKU(sku: string): Observable<ProdutoPorSku> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointProdutos + '/' + sku).subscribe((produtoObject: ProdutoPorSku) => {
        if (produtoObject[0] != null) {
          let produto = this.convertObjectInProdutoPorSkuModel(produtoObject[0]);
          observable.next(produto);
        } else if (produtoObject[0] == null) {
          observable.next(null);
        }
        else {
          observable.error(null);
        }
      });
    });
  }

  public salvaProduto(produto: Produto): Observable<Produto> {
    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointProdutos + '/' + produto.sku, produto).subscribe((produtoAtualizado: any) => {
        let pacotesResponse = new ResponseListWithPagination(produtoAtualizado['data'], produtoAtualizado['meta'], produtoAtualizado['links']);
        if (pacotesResponse.data.length == 0) {
          observable.next(produtoAtualizado);
        }
        this.convertArrayInProdutoList(pacotesResponse.data).subscribe((pacotes: any[]) => {
          pacotesResponse.data = pacotes;
          observable.next(produtoAtualizado);
        });
      });
    });
  }

  public convertArrayInProdutoList(produtosArray: any[]): Observable<Produto[]> {
    let produtos: Produto[] = [];
    return new Observable((observable) => {

      produtosArray.forEach(produtoObject => {

        let produto = this.convertObjectInProdutoModel(produtoObject);

        produtos.push(produto);
      });

      observable.next(produtos);
    })
  }

  public getProdutosAtributos(sku, filtro, qntPorPagina, activePage): Observable<ProdutoAtributo> {
    let queryString = '?';
    if (sku) {
      queryString += 'sku=' + sku + '&';
    }
    if (filtro) {
      queryString += 'filtro=' + filtro + '&';
    }

    queryString += 'limit=' + qntPorPagina + '&';
    queryString += 'actualPage=' + activePage;

    return new Observable((observable) => {
      this.http.get(this.apiHost + '/produtoatributo' + queryString).subscribe((response: ProdutoAtributo) => {
        observable.next(response);
      });
    });
  }

  public buscaCategoriaMl(categoriaRaiz, proximo) {
    return new Observable((observable) => {
      this.http.get(this.apiHost + 'GetCategoriaMl?categoriaId=' + categoriaRaiz + '&proximo=' + proximo).subscribe((response: ProdutoCategoria) => {
        observable.next(response);
      });
    });
  }

  public buscaCategoriaMlPorTexto(categoria) {
    return new Observable((observable) => {
      this.http.get(this.apiHost + 'GetCategoriaMlPorTexto?categoria=' + categoria).subscribe((response: any) => {
        observable.next(response);
      });
    });
  }

  public salvaCategoriaMl(categoriaProduto) {
    return new Observable((observable) => {
      this.http.put(this.apiHost + 'SalvarCategoriaProdutoMl', categoriaProduto).subscribe((result: any) => {
        observable.next(result);
      },
        (error: any) => {
          console.log('error: ', error);
          observable.next('');
        })
    });;
  }

  public getProdutosCategorizacao(sku, filtro, qntPorPagina, activePage): Observable<ProdutoCategoria> {
    let queryString = '?';
    if (sku) {
      queryString += 'sku=' + sku + '&';
    }
    if (filtro) {
      queryString += 'filtro=' + filtro + '&';
    }

    queryString += 'limit=' + qntPorPagina + '&';
    queryString += 'actualPage=' + activePage;

    return new Observable((observable) => {
      this.http.get(this.apiHost + 'produtosCategorizacao' + queryString).subscribe((response: ProdutoCategoria) => {
        observable.next(response);
      });
    });
  }

  public saveProdutosAtributos(produtosAtributos): Observable<any> {

    return new Observable((observable) => {
      this.http.put(this.apiHost + '/produtoatributo', produtosAtributos).subscribe((response: any) => {
        observable.next(response);
      });
    });
  }

  public convertObjectInProdutoModel(produtoObject: any): Produto {
    let produto = new Produto();
    produto.id = produtoObject.id;
    produto.sku = produtoObject.sku;
    produto.titulo = produtoObject.titulo;
    produto.imagens = produtoObject.imagens;
    produto.precoBase = produtoObject.precoBase;
    produto.descricao = produtoObject.descricao;
    produto.referencia = produtoObject.referencia;
    produto.estoque = produtoObject.estoque;
    produto.peso = produtoObject.peso;
    produto.ean = produtoObject.ean;
    produto.ncm = produtoObject.ncm;
    produto.editado = false;

    produto.idLoja = produtoObject.idLoja;
    produto.idCategoria = produtoObject.idCategoria;
    produto.custo = produtoObject.custo;
    produto.largura = produtoObject.largura;
    produto.comprimento = produtoObject.comprimento;
    produto.composto = produtoObject.composto;
    produto.idSituacao = produtoObject.idSituacao;
    produto.idTributacaoIcms = produtoObject.idTributacaoIcms;
    produto.cfop = produtoObject.cfop;
    produto.cest = produtoObject.cest;
    produto.unidadeComercial = produtoObject.unidadeComercial;
    produto.tempoProducao = produtoObject.tempoProducao;
    produto.altura = produtoObject.altura;

    return produto;
  }

  public convertObjectInProdutoPorSkuModel(produtoObject: any): ProdutoPorSku {
    let produto = new ProdutoPorSku();
    produto.id = produtoObject.id;
    produto.sku = produtoObject.sku;
    produto.titulo = produtoObject.titulo;
    produto.imagens = produtoObject.imagens;
    produto.precos = produtoObject.precos;
    produto.descricao = produtoObject.descricao;
    produto.referencia = produtoObject.referencia;
    produto.estoque = produtoObject.estoque;
    produto.peso = produtoObject.peso;
    produto.ean = produtoObject.ean;
    produto.ncm = produtoObject.ncm;

    produto.idLoja = produtoObject.idLoja;
    produto.idCategoria = produtoObject.idCategoria;
    produto.largura = produtoObject.largura;
    produto.comprimento = produtoObject.comprimento;
    produto.composto = produtoObject.composto;
    produto.idSituacao = produtoObject.idSituacao;
    produto.idTributacaoIcms = produtoObject.idTributacaoIcms;
    produto.cfop = produtoObject.cfop;
    produto.cest = produtoObject.cest;
    produto.unidadeComercial = produtoObject.unidadeComercial;
    produto.tempoProducao = produtoObject.tempoProducao;
    produto.altura = produtoObject.altura;

    produto.atributos = produto.atributos;
    produto.precosMarketplaces = produto.precosMarketplaces;
    produto.sinonimos = produto.sinonimos;
    produto.imagens = produto.imagens;

    return produto;
  }

  public convertObjectSinonimoInProdutoModel(produtoObject: any, sinonimo: any): Produto {
    let produto = new Produto();
    produto.id = sinonimo.id;
    produto.sku = produtoObject.sku;
    produto.skuSinonimo = sinonimo.sku;
    produto.titulo = sinonimo.titulo;
    produto.imagens = produtoObject.imagens;
    produto.precoBase = sinonimo.precoBase;
    produto.descricao = produtoObject.descricao;
    produto.referencia = produtoObject.referencia;
    produto.estoque = produtoObject.estoque;
    produto.peso = produtoObject.peso;
    produto.editado = false;

    produto.idLoja = produtoObject.idLoja;
    produto.idCategoria = produtoObject.idCategoria;
    produto.custo = produtoObject.custo;
    produto.largura = produtoObject.largura;
    produto.comprimento = produtoObject.comprimento;
    produto.composto = produtoObject.composto;
    produto.idSituacao = produtoObject.idSituacao;
    produto.idTributacaoIcms = produtoObject.idTributacaoIcms;
    produto.cfop = produtoObject.cfop;
    produto.cest = produtoObject.cest;
    produto.unidadeComercial = produtoObject.unidadeComercial;
    produto.tempoProducao = produtoObject.tempoProducao;
    produto.altura = produtoObject.altura;

    return produto;
  }
}
