import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  achouEmail: boolean;
  mensagemRetorno: string = '';
  emailToReset: string;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService) { }

  ngOnInit() {

  }

  
  resetPassword() {
    this.authService.resetPassword(this.form.emailToReset).subscribe(
      data => {
        this.achouEmail = true;
        this.mensagemRetorno = "Email para redefinir senha enviado!";
        
        //descomentar quando tiver localhost o codigo de redefinição
        //this.reloadPage();
      },
      err => {
        this.achouEmail = false;
        this.mensagemRetorno = "Email não corresponde a nenhum usuário!";
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }

}
