<div style="height: 100vh;" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="boxContainer">
    <mat-card-header>
      <img class="logoImg" src="./../../assets/img/logo-sem-fundo.png">
    </mat-card-header>

    <form fxLayout="column" fxLayoutAlign="space-around center" *ngIf="!isLoggedIn" name="form"
      (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <mat-card-content class="boxInputs">
        <mat-form-field fxLayout="column" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="email@exemplo.com" type="text" class="form-control" name="email"
            [(ngModel)]="form.email" required #email="ngModel">
        </mat-form-field>

        <mat-form-field fxLayout="column" appearance="outline">
          <mat-label>Senha</mat-label>
          <input matInput type="password" class="form-control" name="password"
            [(ngModel)]="form.password" required minlength="4" #password="ngModel">
        </mat-form-field>
        <section fxLayout="row">
          <mat-checkbox class="checkLembrar">
            <input type="checkbox" class="lembreMe" name="lembreMe" [(ngModel)]="form.lembreMe" #lembreMe="ngModel">
            Lembre-me
          </mat-checkbox>
          <span class="spacer"></span>
          <a routerLink="reset-password" routerLinkActive="active" class="forgetPassword">Esqueceu sua senha?</a>
        </section>
      </mat-card-content>


      <mat-container *ngIf="isLoginFailed && errorMessage != ''" class="alert alert-danger alert-dismissible fade show">
        <mat-card-content>{{ errorMessage }}</mat-card-content>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </mat-container>
      <button mat-stroked-button class="btn-block btnPadrao">Entrar</button>
    </form>

    <section aria-label="Cadastro" class="another-action boxCadastrar"> Ainda não tem uma conta?
      <a class="text-link" routerlink="../cadastro" href="/cadastro">Cadastrar</a>
    </section>
  </mat-card>

</div>

<div class="loading" *ngIf="loading">
  <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>
