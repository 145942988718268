import { Component, OnInit } from '@angular/core';
import { ProdutosService } from '../../_services/produtos/produtos.service';
import { ProdutoCategoria } from 'src/app/models/produto-categoria.model';

@Component({
  selector: 'app-produtos-categorias-ml',
  templateUrl: './produtos-categorias-ml.component.html',
  styleUrls: ['./produtos-categorias-ml.component.scss']
})
export class ProdutosCategoriasMlComponent implements OnInit {

  constructor(protected produtoService: ProdutosService) { }

  //dados
  produtosCategorias: ProdutoCategoria[];
  categoriasMl: any[];

  //Controles de tela
  sku: string = '';
  filtro: string = 'Todos';
  categoriaTexto: string = '';
  filtrosDisponiveis = ['Todos', 'Sem categorizacao sugerida', 'Categoria sugerida diferente da atual', 'Desativado por categoria errada'];
  loading: boolean = false;
  msgBuscandoCategorias: boolean = false;
  successMessage: string = '';
  showSuccessMessage: boolean = false;
  errorMessage: string = '';
  showErrorMessage: boolean = false;
  modalCategoria: boolean = false;
  produtoEmSelecaoDeCategoria: ProdutoCategoria = null;
  categoriaAtual: any = null;

  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 40;
  from: number = 0;
  to: number = this.qntPorPagina;
  ultimaPagina: number;
  qntTotalRegistros: number = this.qntPorPagina;

  ngOnInit() {
    this.getProdutosCategorias();
  }

  getProdutosCategorias() {
    this.loading = true;
    this.produtoService.getProdutosCategorizacao(this.sku, this.filtro, this.qntPorPagina, this.activePage).subscribe((resultado: any) => {
      this.produtosCategorias = resultado.resultado;
      resultado.paginacao.info.actualPage++;
      this.qntTotalRegistros = resultado.paginacao.total;
      this.from = resultado.paginacao.info.skip;
      this.to = resultado.paginacao.info.actualPage * resultado.paginacao.limit;
      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        this.qntTotalRegistros = 0;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }
  // Mensagens
  fechaMensagemErro() {
    this.errorMessage = "";
    this.showErrorMessage = false;
  }

  fechaMensagemSuccess() {
    this.successMessage = "";
    this.showSuccessMessage = false;
  }

  displayPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
    this.getProdutosCategorias();
  }

  public aplicarCategoria(produtoCategoria) {
    produtoCategoria.CategoriaAtualId = produtoCategoria.CategoriaSugeridaId;
    produtoCategoria.CategoriaAtual = produtoCategoria.CategoriaSugerida;
  }

  public salvaCategoria(produtoCategoria) {
    this.loading = true;
    this.produtoService.salvaCategoriaMl(produtoCategoria).subscribe((resultado: any) => {
      if (resultado != null) {
        if (resultado == true) {
          this.sucesso('Categoria vinculada ao produto.');
        } else {
          this.sucesso('Ocorreu um erro ao vincular a categoria ao produto.');
        }
        console.log(resultado);
      }
      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        this.qntTotalRegistros = 0;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }


  public sucesso(msg) {
    this.showSuccessMessage = true;
    this.successMessage = msg;
    setTimeout(() => {
      this.showSuccessMessage = false;
      this.successMessage = '';
    }, 5000);
  }

  public erro(msg) {
    this.showErrorMessage = true;
    this.errorMessage = msg;
  }

  public buscarCategoriaPorTexto() {
    console.log('testes');
    if (this.categoriaAtual != null) {
      if (this.categoriaAtual.PossuiME2 == false) {
        this.erro('A categoria informada nao possui mercado envios 2.');
        return;
      }

      this.produtoEmSelecaoDeCategoria.CategoriaAtual = this.categoriaTexto;
      this.produtoEmSelecaoDeCategoria.CategoriaAtualId = this.categoriaAtual.IdCategoria;
      this.sucesso('Categoria aplicada ao produto');

      this.fechaModalCategoria();
    } else {
      this.buscandoCategorias();
      this.produtoService.buscaCategoriaMlPorTexto(this.categoriaTexto).subscribe((resultado: any) => {
        this.finalizoubuscaCategorias();
        if (resultado != null) {
          if (resultado.PossuiME2 == false) {
            this.erro('A categoria informada nao possui mercado envios 2.');
            return;
          }
          this.produtoEmSelecaoDeCategoria.CategoriaAtual = resultado.Nome;
          this.produtoEmSelecaoDeCategoria.CategoriaAtualId = resultado.IdCategoria;
          this.fechaModalCategoria();
          this.sucesso('Categoria aplicada ao produto.');
        } else {
          this.categoriaTexto = '';
          this.erro('Não foi possivel encontrar a categoria informada.');
        }
      }),
        (error) => {
          this.loading = false;
          this.qntTotalRegistros = 0;
          this.from = 0;
          this.to = 0;
          this.ultimaPagina = 0;
          let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
        }
    }

  }

  public buscandoCategorias() {
    this.msgBuscandoCategorias = true;
  }

  public finalizoubuscaCategorias() {
    this.msgBuscandoCategorias = false;
  }

  public ajustarCategoriaTexto(produtoCategoria, proximo, setarIdCategoria) {
    if (produtoCategoria == null || produtoCategoria == '') {
      this.categoriaTexto = '';
    }
    if (proximo) {
      if (this.categoriaTexto.length > 0) {
        this.categoriaTexto += ' > ';
      }
      this.categoriaTexto += produtoCategoria.Nome;
    } else {
      let lastIndexCategoria = this.categoriaTexto.lastIndexOf(' > ');
      if (lastIndexCategoria == -1) {
        this.categoriaTexto = '';
      } else {
        this.categoriaTexto = this.categoriaTexto.slice(0, lastIndexCategoria);
      }
    }

    if (setarIdCategoria) {
      this.categoriaAtual = produtoCategoria;
    } else {
      this.categoriaAtual = null;
    }

  }

  public buscarCategorias(produtoCategoria, proximo) {
    this.ajustarCategoriaTexto(produtoCategoria, proximo, false);
    this.buscandoCategorias();
    this.produtoService.buscaCategoriaMl(produtoCategoria.IdCategoria, proximo).subscribe((resultado: any) => {
      this.categoriasMl = resultado;
      this.finalizoubuscaCategorias();

    }),
      (error) => {
        this.finalizoubuscaCategorias();
        this.qntTotalRegistros = 0;
        this.from = 0;
        this.to = 0;
        this.ultimaPagina = 0;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'
      }
  }

  public fechaModalCategoria() {
    this.modalCategoria = false;
    this.categoriasMl = null;
    this.produtoEmSelecaoDeCategoria = null;
    this.categoriaAtual = null;
    this.categoriaTexto = '';
  }

  public abreModalCategoria(produto) {
    this.produtoEmSelecaoDeCategoria = produto;
    let cat = new categoria();
    cat.IdCategoria = '';
    cat.Nome = '';
    this.buscarCategorias(cat, true);
    this.modalCategoria = true;
  }


  displayActivePage(activePageNumber: number) {
    document.getElementById("containerTodo").scroll(0, 0);
    this.activePage = activePageNumber;
    this.getProdutosCategorias();
  }

  displayPedidosPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
    this.getProdutosCategorias();
  }

}

class categoria {
  IdCategoria: string;
  Nome: string;
}
