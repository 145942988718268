import { Component, Inject, OnInit } from '@angular/core';
import { FiltrosListagemDashboard } from 'src/app/_services/dashboard/dashboard.service';
import { PacotesService } from 'src/app/_services/pedidos/pacotes.service';
import { Router } from '@angular/router';
import { NotaFiscalService } from 'src/app/_services/notaFiscal/nota-fiscal.service';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/_helpers/format-datepicker';;
import { ResponseListWithPagination, ResponseList, HelperService } from 'src/app/_services/helpers/helper.service';
import { Pacote } from 'src/app/models/pacote';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { FormControl } from '@angular/forms';
import { NotaFiscal, SituacaoNota } from 'src/app/models/nota-fiscal';
import { Marketplace } from 'src/app/models/marketplace';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from 'src/app/_services/clientes/cliente.service';
import { DefaultReturn } from 'src/app/models/default-return';
import { Observable } from 'rxjs';
import { PedidosService } from '../../../_services/pedidos/pedidos.service';
import { RepeatOne, RepeatOneSharp } from '@material-ui/icons';

export interface DialogData {
  inscricaoEstadual: string;
}

export class FiltroOutros {
  atrasados: boolean;
  impressos: boolean;
  naoImpressos: boolean;
  observacoes: boolean;

  constructor() {
    this.atrasados = this.impressos = this.naoImpressos = false;
  }
}

export class FiltroOutrosEnum {
  static atrasados = "Atrasados";
  static impressos = "Impressos";
  static naoImpressos = "Não Impressos";
  static observacoes = "Observações";
}

@Component({
  selector: 'app-pedidos-list',
  templateUrl: './pedidos-list.component.html',
  styleUrls: ['./pedidos-list.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class PedidosListComponent implements OnInit {
  isLoggedIn: boolean = false;
  loading = false;

  // IE do destinatario
  inscricaoEstadualCliente: string;

  // Paginação
  activePage: number = 1;
  qntPorPagina: number = 40;//40
  from: number = 0;
  to: number = this.qntPorPagina;
  ultimaPagina: number;
  qntTotalRegistros: number = this.qntPorPagina;

  // end Paginação

  //Pedidos
  pacotes: Pacote[];
  pedidosGerarEtiquetas = new Array();
  checkedAll: boolean = false;
  mostraFaturarPedido: boolean = false;
  mostraCancelarPedido: boolean = false;
  pedidosSelecionados = new Array();
  pedidoParaCancelar: Pacote;
  selecionados = 0;

  //Filtros
  dataFim = new Date();
  dataInicio = new Date();
  pesquisaGeral = '';
  mkpSelecionados = new FormControl();
  filtroOutros = new FormControl();
  filtroOutrosLista: string[] = [FiltroOutrosEnum.atrasados, FiltroOutrosEnum.impressos, FiltroOutrosEnum.naoImpressos, FiltroOutrosEnum.observacoes];
  filtroTipoData = 'dataAprovacao';
  filtroSituacao = 'all';
  vinculoCancelamento = 'todos';
  entrega = 'onlyToBeSentToday';
  mkpList: Marketplace[];
  ordenacao = 'menosrecentes';

  filtros: FiltrosListagemDashboard = {
    pesquisaGeral: this.pesquisaGeral,
    situacao: this.filtroSituacao,
    vinculoCancelamento: this.vinculoCancelamento,
    entrega: this.entrega,
    mkp: this.getMkpSelecionados(),
    periodo: [this.dataInicio, this.dataFim],
    dataDe: this.filtroTipoData,
    outros: this.getFiltroOutros(),
    ordenacao: this.ordenacao
  };

  entregas = [
    { nome: 'Somente para enviar hoje', value: 'onlyToBeSentToday' },    
    { nome: 'Somente para enviar posteriormente', value: 'onlyToBeSentLater' },
    { nome: 'Todos', value: 'all' }
  ]

  ordenacoes = [
    { nome: 'Mais Recentes', value: 'maisrecentes' },
    { nome: 'Menos Recentes', value: 'menosrecentes' }
  ];

  porDataDe = [
    { nome: 'Aprovação pagamento', value: 'dataAprovacao' },
    { nome: 'Compra', value: 'dataCompra' }
  ];

  vinculosCancelamento = [
    { nome: 'Todos', value: 'todos' },
    { nome: 'Sem nota de devolução', value: 'semnotadevolucao' },
    // { nome: 'Sem nota de cancelamento', value: 'semnotacancelamento' }
  ];

  situacoes = [
    { nome: 'Todas as Situações', value: 'all' },
    { nome: 'Aguardando Pagamento', value: 'aguardandopagamento' },
    { nome: 'Aguardando Faturamento', value: 'pago' },
    { nome: 'Aguardando Impressão de Etiqueta', value: 'faturado' },
    { nome: 'Aguardando Postagem', value: 'expedido' },
    { nome: 'Em Trânsito', value: 'emtransito' },
    { nome: 'Entregue', value: 'entregue' },
    { nome: 'Aguardando Cancelamento', value: 'aguardandocancelamento' },
    { nome: 'Cancelado', value: 'cancelado' },
  ];

  //Mensagens
  errorMessage: string = '';
  showErrorMessage: boolean = false;
  successMessage: string = '';
  showSuccessMessage: boolean = false;

  // NFe
  tipoNf = '55';
  ambiente = '1';
  nota: NotaFiscal;
  nfPedidoSelecionado = new NotaFiscal();
  numero: number = 0;
  serie: number = 0;
  dataEmissao = new Date();
  chaveAcesso: number = 0;
  statusNfeQnt: number;

  nfeExistente: any = {
    numero: this.numero,
    serie: this.serie,
    dataEmissao: this.dataEmissao,
    chaveAcesso: this.chaveAcesso
  }

  //Cancelamentos Nfe
  codigosCancelamento = [
    { value: 'OUT_OF_STOCK', nome: 'Sem estoque' },
    { value: 'UNPRINTED_LABEL', nome: 'Etiqueta não pode ser impressa' },
    { value: 'DENIED_PACKAGE', nome: 'Empresa de envio não aceita o pacote por causa do tamanho ou peso' },
    { value: 'UNABLE_TO_READ_LABEL', nome: 'Empresa de envio não consegue ler a etiqueta' },
    { value: 'WRONG_RECEIVER_ADDRESS', nome: 'Endereço de entrega errado' },
    { value: 'SHIPMENT_PROBLEM_OTHER', nome: 'Envio teve algum outro problema' },
    { value: 'OTHER_MY_RESPONSIBILITY', nome: 'Responsabilidade própria (outro motivo)' },
    { value: 'OTHER_THEIR_RESPONSIBILITY', nome: 'Responsabilidade da contraparte (outro motivo)' }
  ]

  motivoCancelamento: string = '';
  codigoCancelamento: string = '';

  cancelamento = {
    codigoCancelamento: this.codigoCancelamento,
    motivoCancelamento: this.motivoCancelamento,
  }

  //Modal Faturamento
  errorMessageEmitirNfe = '';
  showErroEmitirNfe: boolean = false;
  btnSairEmissaoNF: boolean = false;
  mostraAdicionarNf: boolean = false;
  devolucao: boolean = false;
  mostraNfEmitida: boolean = false;
  showStatusSuccess: boolean = false;
  showStatusErro: boolean = false;
  statusNfMessage: string = "";
  detalhesEmissao: any = {
    modeloDocumento: this.tipoNf,
    ambiente: this.ambiente,
    numero: 0,
    idsPedidos: []
  };

  tiposNf = [
    { nome: 'NF-e', value: '55' },
  ];

  ambientes = [
    { nome: 'Produção', value: '1' },
    { nome: 'Homologação - não funciona ainda', value: '0' },
  ];

  displayedColumns: string[] = ['pedido', 'mkp', 'cliente', 'valorTotal', 'geraNf', 'geraEtiqueta'];

  //Pedidos para faturar
  dataSource = this.pedidosSelecionados;

  btnEmitirNfe = true;
  btnGerarEtiqueta = true;
  btnImprimirEtiqueta = true;
  mostraInfoImpressao = false;
  urlsBaixarEtiquetas = [];
  lotesTrabalho = [];
  mostraMotivoCancelaNf: boolean = false;
  mostraEstornoNf: boolean = false;
  mostraCancelamentoNf: boolean = false;
  motivoCancelamentoNf: string = "";
  // end NFe

  constructor(
    private pacotesService: PacotesService,
    private notaFiscalService: NotaFiscalService,
    public datepipe: DatePipe,
    private helperService: HelperService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    public dialog: MatDialog,
    public clienteService: ClienteService,
    protected pedidosService: PedidosService
  ) {

  }

  ngOnChange() {
    this.escondeLongeActivePage();
  }

  ngAfterViewChecked() {
    this.escondeLongeActivePage();
  }

  ngOnInit() {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (!this.isLoggedIn) {
      this.router.navigate(['login']);
    }
    this.dataInicio.setDate(this.dataInicio.getDate() - 7);
    this.getMarketplaces();

    var filtros = this.pegaFiltrosSalvos();

    if (filtros != null) {
      this.filtros = filtros;
      this.buscaComFiltrosSalvos();
    }

    // console.log('topbar loaded');
    if (Notification.permission === 'default') {
      // console.log('Notification default');
      // Notification.requestPermission();
    }

    if (Notification.permission === 'denied') {
      // console.log('Notification denied');
    }

    if (Notification.permission === 'granted') {
      // console.log('Notification granted');
      // this.ativarNotificacoes();
    }
  }

  public ativarNotificacoes() {
    var self = this;
    setInterval(function () {
      self.getNotificacoes();
    }, 60000);
  }

  getNotificacoes() {
    this.pedidosService.getPerguntas().subscribe((perguntas: any[]) => {
      perguntas.forEach(pergunta => {
        this.notificar(pergunta);
      });
    },
      (error) => {
      }
    );
  }

  notificar(pergunta) {
    var notification = new Notification('Mercado livre', {
      icon: pergunta.linkImagem,
      body: pergunta.pergunta,
    });
    notification.onclick = function () {
      window.open(pergunta.linkAnuncio);
    };
  }

  // Busca padrão e Filtros
  salvaFiltros() {
    window.localStorage.removeItem('Filtros-Pedidos');
    window.localStorage.setItem('Filtros-Pedidos', JSON.stringify(this.filtros));
  }

  pegaFiltrosSalvos() {
    return JSON.parse(localStorage.getItem('Filtros-Pedidos'));
  }

  buscaComFiltrosSalvos() {
    if (this.filtros.mkp.length == 0) {
      this.filtros.mkp = this.getMkpSelecionados();
    } else {
      for (let i = 0; i < this.filtros.mkp.length; i++) {

      }
    }
    this.filtros.outros = this.getFiltroOutros();

    this.salvaFiltros();
    this.buscaPacotes();
  }

  buscaComFiltros() {
    this.pedidosSelecionados = [];
    this.filtros.mkp = this.getMkpSelecionados();
    this.filtros.outros = this.getFiltroOutros();

    this.salvaFiltros();
    this.buscaPacotes();
  }

  buscaPacotes() {
    this.loading = true;
    this.pacotesService.getPacotesPedidosList(this.filtros, this.qntPorPagina, this.activePage).subscribe((pacotesTotal: ResponseListWithPagination) => {
      this.pacotes = pacotesTotal.data;
      console.log(this.pacotes);
      this.qntTotalRegistros = pacotesTotal.meta.total;
      this.from = pacotesTotal.meta.from;
      this.to = pacotesTotal.meta.to;
      this.ultimaPagina = pacotesTotal.meta.last_page;
      localStorage.setItem('recordsPerPage', this.qntPorPagina.toString());
      this.loading = false;
    }),

      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'

      }
  }

  getMkpSelecionados() {
    var mkps = this.mkpSelecionados.value;
    var mkpList = [];
    if (mkps != null) {
      for (let i = 0; i < mkps.length; i++) {
        mkpList.push(mkps[i]);
      }
    }
    return mkpList;
  }

  getFiltroOutros(): FiltroOutros {
    var listaFiltroOutros = this.filtroOutros.value;
    var filtrosOutrosSelcionados = new FiltroOutros();
    if (listaFiltroOutros != null) {
      for (let i = 0; i < listaFiltroOutros.length; i++) {
        switch (listaFiltroOutros[i]) {
          case FiltroOutrosEnum.atrasados:
            filtrosOutrosSelcionados.atrasados = true;
            break;
          case FiltroOutrosEnum.impressos:
            filtrosOutrosSelcionados.impressos = true;
            break;
          case FiltroOutrosEnum.naoImpressos:
            filtrosOutrosSelcionados.naoImpressos = true;
            break;
          case FiltroOutrosEnum.observacoes:
            filtrosOutrosSelcionados.observacoes = true;
            break;
        }
      }

    }
    return filtrosOutrosSelcionados;

  }

  getMarketplaces() {
    this.pacotesService.getMarketplaces().subscribe((marketplaces: Marketplace[]) => {
      this.mkpList = marketplaces;
    }),

      (error) => {
        this.loading = false;
        let mensagemErro = 'Houve um erro inesperado, favor tente mais tarde.'

      }
  }

  // Paginação
  escondeLongeActivePage() {
    for (var i = 1; i <= this.ultimaPagina; i++) {
      var aux = document.getElementById(i.toString());
      if (aux != null) {
        if (i < this.activePage - 5 || i > this.activePage + 5) {
          aux.classList.add("hide");
        } else {
          aux.classList.add("show");
          aux.classList.remove("hide");
        }
      }
    }
  }

  displayActivePage(activePageNumber: number) {
    document.getElementById("containerTodo").scroll(0, 0);
    this.activePage = activePageNumber;
    this.buscaPacotes();
  }

  displayPedidosPage(qntPedidosPage: number) {
    this.qntPorPagina = qntPedidosPage;
    this.activePage = 1;
    this.buscaPacotes();
  }
  // end Paginação

  // Listagem ações
  filtrar() {
    this.loading = true;

    this.filtros.periodo[0] = this.dataInicio;
    this.filtros.periodo[1] = this.dataFim;
    //this.filtros.pesquisaGeral = this.helperService.removeAcentos(this.pesquisaGeral);
    this.filtros.situacao = this.filtroSituacao;

    this.buscaPacotes();
  }

  marcaTodosPedidos() {
    for (let i = 0; i < this.pacotes.length; i++) {
      if (this.checkedAll) {
        this.pacotes[i].checked = true;
      } else {
        this.pacotes[i].checked = false;
      }
    }

    this.getPedidosSelecionados();
  }

  imprimirPedidos() {
    // this.imprimirDanfeEtiquetas();
    let pacotesSelecionados = this.pacotes.filter((pacote: Pacote) => pacote.checked);

    if (pacotesSelecionados.length == 0) {
      this.errorMessage = "Selecione ao menos um pedido!";
      this.showErrorMessage = true;

      return;
    }

    let listaImpressaoPacotes: any[] = [];
    let idsPacotesImpressos: number[] = [];

    pacotesSelecionados.forEach((pacote: Pacote) => {
      if (pacote.fulfillment === true) {
        return;
      }
      pacote.produtos.forEach((pacoteProduto: any) => {
        let sku = pacoteProduto.skuSinonimo ? '[' + pacoteProduto.sku + '] ' + pacoteProduto.skuSinonimo : pacoteProduto.sku;
        let pacoteImpressao = new Object;
        pacoteImpressao['ID Pedido'] = pacote.id;
        pacoteImpressao['Cliente'] = pacote.nomeRecebedor;
        pacoteImpressao['SKU'] = sku;
        pacoteImpressao['Foto'] = this.helperService.exibeImagemComHttps(pacoteProduto.imagem);
        pacoteImpressao['Qtd'] = pacoteProduto.quantidadeVendida;
        pacoteImpressao['Titulo'] = pacoteProduto.titulo;
        pacoteImpressao['Kg Total'] = pacoteProduto.pesoTotal;
        if (pacoteProduto.composicao != null && Array.isArray(pacoteProduto.composicao)) {
          var composicoes = new Array();
          for (let i = 0; i < pacoteProduto.composicao.length; i++) {
            let composicao = new Object;
            composicao['SKU'] = pacoteProduto.composicao[i].sku;
            composicao['Foto'] = this.helperService.exibeImagemComHttps(pacoteProduto.composicao[i].imagem);
            composicao['Qtd'] = pacoteProduto.composicao[i].quantidadeVendida;
            composicao['Titulo'] = pacoteProduto.composicao[i].titulo;
            composicao['Kg Total'] = pacoteProduto.pesoTotal;
            composicoes.push(composicao);
          }

          pacoteImpressao['Composicoes'] = composicoes;
        }

        listaImpressaoPacotes.push(pacoteImpressao);
      });

      idsPacotesImpressos.push(pacote.id);
    });

    if (listaImpressaoPacotes.length == 0) {
      this.errorMessage = "Selecione ao menos um pedido!";
      this.showErrorMessage = true;
      return;
    }

    // let barcode = await this.pacotesService.GerarCodBarras(idsPacotesImpressos).toPromise();
    this.pacotesService.GerarCodBarras(idsPacotesImpressos).subscribe((barcode: any) => {
      let tableImpressaoPacotes = this.helperService.geraTabelaHtml(listaImpressaoPacotes, barcode);

      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      let dateTimeAtual = new Date().toLocaleDateString('pt-BR', options);
      let nomeArquivoImpressao = 'Tabela Pedidos - ' + dateTimeAtual;

      this.helperService.downloadHtml(tableImpressaoPacotes, nomeArquivoImpressao);

      this.pacotesService.marcaPacotesComoImpressos(idsPacotesImpressos).subscribe((observer: any) => {
        this.buscaPacotes();
      });
    })

    // let tableImpressaoPacotes = this.helperService.geraTabelaHtml(listaImpressaoPacotes, barcode);

    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    // let dateTimeAtual = new Date().toLocaleDateString('pt-BR', options);
    // let nomeArquivoImpressao = 'Tabela Pedidos - ' + dateTimeAtual;

    // this.helperService.downloadHtml(tableImpressaoPacotes, nomeArquivoImpressao);

    // // this.pacotesService.marcaPacotesComoImpressos(idsPacotesImpressos).subscribe((observer: any) => {
    // //   this.buscaPacotes();
    // // });
  }

  getPedidosSelecionados() {
    if (this.pedidosSelecionados.length > 0)
      this.pedidosSelecionados.length = 0;

    for (let i = 0; i < this.pacotes.length; i++) {
      if (this.pacotes[i].checked == true && this.pacotes[i].fulfillment == false) {
        this.pedidosSelecionados.push(this.pacotes[i]);
      }
    }

    this.selecionados = this.pedidosSelecionados.length;
  }

  verificaStatusCorreto(statusEsperado, pacotes = null) {
    if (pacotes !== null) {
      for (let i = 0; i < pacotes.length; i++) {
        if (pacotes[i].status.descricao != statusEsperado) {
          return false;
        }
      }
      return true;
    } else {
      for (let i = 0; i < this.pedidosSelecionados.length; i++) {
        if (this.pedidosSelecionados[i].status.descricao != statusEsperado) {
          return false;
        }
      }
      return true;
    }
  }

  abrirModalFaturaPedido(pedido = null) {

    this.btnEmitirNfe = true;
    this.btnSairEmissaoNF = false;

    if (pedido == null) {
      this.getPedidosSelecionados();
    } else {
      this.checkedAll = false;
      this.marcaTodosPedidos();
      this.pedidosSelecionados.push(pedido);
    }

    if (this.pedidosSelecionados.length == 0) {
      this.errorMessage = 'Selecione ao menos um pedido!';
      this.showErrorMessage = true;
      return;
    }
    var podeSeguir = this.verificaStatusCorreto("Aguardando faturamento");
    if (podeSeguir) {
      this.mostraFaturarPedido = true;
    } else {
      this.checkedAll = false;
      this.marcaTodosPedidos();
      this.errorMessage = 'Somente pedidos com status "Aguardando faturamento" podem ser faturados!';
      this.showErrorMessage = true;
    }

  }

  cancelarPedido(pedido) {
    this.mostraCancelarPedido = true;
    this.pedidoParaCancelar = pedido;
  }

  submitCancelamento() {

    if (this.cancelamento.codigoCancelamento == "") {
      return;
    }
    this.pacotesService.cancelaPacote(this.cancelamento, this.pedidoParaCancelar.id).subscribe((responseCancelamento: any) => {
      this.successMessage = responseCancelamento.Mensagem;
      this.mostraCancelarPedido = false;
      this.showSuccessMessage = true;
      this.pedidoParaCancelar = responseCancelamento.Pedido;
    },
      (err) => {
        this.mostraCancelarPedido = false;
        this.errorMessage = err.message;
        this.showErrorMessage = true;
      }
    );

  }

  abrirModalAdicionarNf(pedido) {
    this.mostraAdicionarNf = true;
    this.checkedAll = false;
    this.marcaTodosPedidos();
    this.pedidosSelecionados.push(pedido);
  }

  submitAdicionaNfeExistente() {
    var nfExistente = new NotaFiscal();
    nfExistente.idPacote = this.pedidosSelecionados[0].id;
    nfExistente.numero = this.nfeExistente.numero;
    nfExistente.serie = this.nfeExistente.serie;
    nfExistente.chave = this.nfeExistente.chaveAcesso;
    nfExistente.dataEmissao = this.nfeExistente.dataEmissao;
    nfExistente.ambiente = "1";
    nfExistente.modeloDocumento = "55";
    nfExistente.situacao = SituacaoNota.Autorizada;
    nfExistente.viaDevolucao = this.devolucao;

    this.notaFiscalService.adicionaNotaAoPedido(nfExistente).subscribe((response: any) => {
      if (this.devolucao) {
        this.successMessage = 'NF adicionada corretamente! Prosseguindo com a devolução.';
        this.showSuccessMessage = true;
        this.mostraAdicionarNf = false;
        this.nfeExistente.numero = '';
        this.nfeExistente.serie = '';
        this.nfeExistente.chaveAcesso = '';
        this.nfeExistente.dataEmissao = new Date();

        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 3000);

        this.submitNfeDevolucao();
        this.devolucao = false;
      } else {
        this.successMessage = 'NF adicionada corretamente!';

        this.showSuccessMessage = true;
        this.mostraAdicionarNf = false;
        this.nfeExistente.numero = '';
        this.nfeExistente.serie = '';
        this.nfeExistente.chaveAcesso = '';
        this.nfeExistente.dataEmissao = new Date();

        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 3000);
      }
    },
      (err) => {
        this.mostraAdicionarNf = false;
        this.errorMessage = 'Erro ao tentar adicionar NF existente. ' + err.message;
        this.showErrorMessage = true;
      },
      () => {

      }
    );
  }

  closeModalPadrao() {
    this.mostraFaturarPedido = false;
    this.mostraCancelarPedido = false;
    this.mostraAdicionarNf = false;
    this.mostraAdicionarNf = false;
    this.mostraInfoImpressao = false;
    this.pedidosSelecionados.length = 0;
  }

  // Mensagens
  fechaMensagemErro() {
    this.errorMessage = "";
    this.showErrorMessage = false;
  }

  fechaMensagemSuccess() {
    this.successMessage = "";
    this.showSuccessMessage = false;
  }


  // Faturamento
  verificaStatusNfe() {
    if (this.statusNfeQnt == this.pedidosSelecionados.length - 1) {
      setTimeout(() => {
        this.loading = false;
        this.btnSairEmissaoNF = false;
        this.btnEmitirNfe = false;
      }, 1500);
    } else {
      this.statusNfeQnt += 1;
    }
  }

  changeFiltros() {
    if (this.filtros.situacao != 'cancelado') {
      this.filtros.vinculoCancelamento = this.vinculoCancelamento;
    }
  }

  emitirNfeDevolucao(pacote) {
    this.pedidosSelecionados = [];
    if (pacote) {
      if (!pacote.nfe) {
        this.devolucao = true;
        this.abrirModalAdicionarNf(pacote);
        return;
      } else if (this.pedidosSelecionados.length == 0) {
        this.pedidosSelecionados.push(pacote);
      }
    }

    this.submitNfeDevolucao();
  }

  submitNfeDevolucao() {
    this.showErroEmitirNfe = false;
    this.errorMessageEmitirNfe = "";

    this.statusNfeQnt = 0;
    this.loading = true;

    if (this.pedidosSelecionados && this.pedidosSelecionados.length > 0) {
      this.detalhesEmissao.idsPedidos = this.pedidosSelecionados.map(function (pedido, index) {
        return pedido.id;
      });
    } else {
      this.detalhesEmissao.idsPedidos = [this.nfPedidoSelecionado.id];
    }

    if (this.pedidosSelecionados.length > 1) {
      this.errorMessageEmitirNfe = 'Atenção! Só é possivel emitir uma nota de devolução por vez.';
      this.showErroEmitirNfe = true;
      this.loading = false;
      return;
    }

    this.notaFiscalService.getProximoNumeroNota(this.detalhesEmissao).subscribe((numerosEmissaoPorPedido: DefaultReturn) => {
      if (numerosEmissaoPorPedido.success) {
        this.emitirNotaDevolucao(this.pedidosSelecionados[0], this.detalhesEmissao, numerosEmissaoPorPedido);
      }
      else {
        this.errorMessageEmitirNfe = numerosEmissaoPorPedido.mensagemErro;
        this.showErroEmitirNfe = true;
        this.loading = false;
      }
    },
      (error) => {
        this.errorMessageEmitirNfe = error;
        this.showErroEmitirNfe = true;
        this.loading = false;
      }
    );
  }

  emitirNotaDevolucao(pedido, detalhesEmissao, numerosEmissaoPorPedido) {
    detalhesEmissao.numero = numerosEmissaoPorPedido.retornoSucesso[pedido.id];
    this.notaFiscalService.emiteNotaDevolucaoPedido(detalhesEmissao, pedido.id).subscribe((responseNota: any) => {
      if (responseNota.Status == "Rejeitada") {
        this.showSuccessMessage = false;
        this.errorMessage = responseNota.Mensagem;
        this.showErrorMessage = true;
        this.loading = false;
        return true;
      }
      else if (responseNota.Status == 'Lote em processamento' || responseNota.Status == 'Em Processamento') {
        this.successMessage = 'NFe de devolução esta em processamento, por favor aguarde!';
        this.showSuccessMessage = true;
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 3000);
        this.ConsultarEmissaoDevolucao(responseNota, pedido);
      }
      else if (responseNota.Status == "Autorizada") {
        this.successMessage = 'NF de devolução emitida com sucesso!';
        this.showSuccessMessage = true;
        this.loading = false;
        setTimeout(() => {
          this.showSuccessMessage = false;
          window.location.reload();
        }, 3000);
      }
    },
      (error) => {
        this.errorMessageEmitirNfe = error;
        this.showErroEmitirNfe = true;
      }
    );
  }

  submitNfe() {
    this.showErroEmitirNfe = false;
    this.errorMessageEmitirNfe = "";

    this.statusNfeQnt = 0;
    this.loading = true;
    this.detalhesEmissao.idsPedidos = this.pedidosSelecionados.map(function (pedido, index) {
      return pedido.id;
    });

    this.notaFiscalService.getProximoNumeroNota(this.detalhesEmissao).subscribe((numerosEmissaoPorPedido: DefaultReturn) => {
      if (numerosEmissaoPorPedido.success) {
        this.emitirNotasRecursivamente(this.pedidosSelecionados, 0, this.detalhesEmissao, numerosEmissaoPorPedido);
      }
      else {
        this.errorMessageEmitirNfe = numerosEmissaoPorPedido.mensagemErro;
        this.showErroEmitirNfe = true;
        this.loading = false;
      }
    },
      (error) => {
        this.errorMessageEmitirNfe = error;
        this.showErroEmitirNfe = true;
        this.loading = false;
      }
    );
  }

  emitirNotasRecursivamente(pedidos, indexAtual = 0, detalhesEmissao, numerosEmissaoPorPedido) {
    detalhesEmissao.numero = numerosEmissaoPorPedido.retornoSucesso[pedidos[indexAtual].id];

    this.notaFiscalService.emiteNotaDoPedido(detalhesEmissao, pedidos[indexAtual].id).subscribe((responseNota: any) => {

      this.checkStatus(responseNota, pedidos, indexAtual);

      indexAtual++;
      if (indexAtual >= pedidos.length) {
        return;
      } else {
        detalhesEmissao.numero++;
        this.emitirNotasRecursivamente(pedidos, indexAtual, detalhesEmissao, numerosEmissaoPorPedido);
      }

    },
      (error) => {
        indexAtual++;
        this.errorMessageEmitirNfe = error;
        this.showErroEmitirNfe = true;
        if (indexAtual >= pedidos.length - 1) {
          this.loading = false;
          return;
        } else {
          this.detalhesEmissao.numero++;
          this.emitirNotasRecursivamente(pedidos, indexAtual, detalhesEmissao, numerosEmissaoPorPedido);
        }
      }
    );

  }

  imprimirEtiquetasMagaluB2w(pedido = null, withReload = true) {

    if (pedido == null && this.urlsBaixarEtiquetas.length > 0) {
      this.urlsBaixarEtiquetas.forEach((urlEtiquetas, index) => {
        window.open(urlEtiquetas, '_blank');
        if (this.urlsBaixarEtiquetas.length == (index + 1)) {
          this.urlsBaixarEtiquetas = [];
          this.loading = false;
          this.closeModalPadrao();
          if (withReload) {
            window.location.reload();
          }
        }
      });

    } else {
      let idsPacote = [];
      if (pedido == null) {
        this.getPedidosSelecionados();
        this.pedidosSelecionados.forEach(item => {
          idsPacote.push(item.id);

        });
        if (this.pedidosSelecionados.length == 0) {
          this.errorMessage = 'Selecione ao menos um pedido!';
          this.showErrorMessage = true;
          return;
        }
      } else {
        this.checkedAll = false;
        this.marcaTodosPedidos();
        this.pedidosSelecionados.push(pedido);
        idsPacote.push(pedido.id);
      }

      var podeSeguir = this.verificaStatusCorreto("Aguardando impressão de etiqueta");

      if (podeSeguir) {
        this.loading = true;
        this.pacotesService.getEtiquetasPacotesOld(idsPacote).subscribe((response: any) => {
          if (response.success) {
            this.urlsBaixarEtiquetas = response.retornoSucesso;
            this.imprimirEtiquetas();
          }

          if (response.mensagemErro != "") {
            this.errorMessage = response.mensagemErro + response.metaDados[0];
            this.showErrorMessage = true;

            this.marcaEtiquetaGerada(JSON.parse(response.metaDados));
            this.loading = false;
          }

        });

      } else {
        this.checkedAll = false;
        this.marcaTodosPedidos();
        this.errorMessage = 'Somente pedidos com status "Aguardando impressão de etiqueta" podem ter as etiquetas impressas!';
        this.showErrorMessage = true;
      }

    }
  }

  private checkStatus(responseNota, pedidos, indexAtual, tentativas = 0) {

    if (tentativas >= 10) {
      pedidos[indexAtual].nfeGerada = responseNota.Status;
      pedidos[indexAtual].status.descricao = "Em Processamento[Por favor consulte a nota mais tarde]";
      if (indexAtual >= pedidos.length - 1) {
        this.loading = false;
      }
      return;
    }

    if (responseNota.Status == "Rejeitada") {
      this.errorMessageEmitirNfe = responseNota.Mensagem;
      this.showErroEmitirNfe = true;
      pedidos[indexAtual].nfeGerada = responseNota.Status;
      if (indexAtual >= pedidos.length - 1) {
        this.loading = false;
      }
    } else if (responseNota.Status == "Autorizada") {
      pedidos[indexAtual].nfeGerada = responseNota.Status;
      pedidos[indexAtual].status.descricao = "Aguardando impressão de etiqueta";
      this.pedidosGerarEtiquetas.push(pedidos[indexAtual]);
      if (indexAtual >= pedidos.length - 1) {
        this.loading = false;
      }
    } else if (responseNota.Status == "Em Processamento") {
      // setTimeout(() => {
      tentativas++;
      pedidos[indexAtual].nfeGerada = responseNota.Status;
      this.ConsultarEmissao(responseNota, pedidos, indexAtual, tentativas);
      // }, 3000);
    }
  }

  geraEtiquetas(pedido = null) {
    let idsPacotes = [];
    this.loading = true;
    if (pedido == null && this.pedidosGerarEtiquetas.length == 0) {
      this.getPedidosSelecionados();
      this.pedidosSelecionados.forEach(item => {
        idsPacotes.push(item.id);
      });
    } else if (pedido != null) {
      this.checkedAll = false;
      this.marcaTodosPedidos();
      this.pedidosSelecionados.push(pedido);
      idsPacotes.push(pedido.id);
    }
    var podeSeguir: boolean;

    if (idsPacotes.length == 0 && this.pedidosGerarEtiquetas != null && this.pedidosGerarEtiquetas != []) {
      this.pedidosGerarEtiquetas.forEach(element => {
        idsPacotes.push(element.id);
      });
      podeSeguir = this.verificaStatusCorreto("Aguardando impressão de etiqueta", this.pedidosGerarEtiquetas);
    } else {
      podeSeguir = this.verificaStatusCorreto("Aguardando impressão de etiqueta");
    }


    if (podeSeguir) {
      this.pacotesService.getEtiquetasPacotes(idsPacotes).subscribe((response: any) => {
        this.pedidosGerarEtiquetas = [];
        idsPacotes = [];
        if (response.mensagemErro && response.mensagemErro.length > 0) {
          var msgErro = 'Atenção! Não foi possivel gerar as etiquetas dos pedidos [';
          for (var i = 0; i < response.mensagemErro.length; i++) {
            msgErro += response.mensagemErro[i];
            if (i < response.mensagemErro.length - 1) {
              msgErro += ', ';
            }
          }
          msgErro += '].';

          this.errorMessage = msgErro;
          this.showErrorMessage = true;
        }

        if (response.retornoSucesso && response.retornoSucesso.length > 0) {
          this.urlsBaixarEtiquetas = response.retornoSucesso;
          if (this.showErrorMessage) {
            this.imprimirEtiquetas(null, false);
            setTimeout(() => {
              window.location.reload();
            }, 8000);
          } else {
            this.imprimirEtiquetas();
          }
          this.btnGerarEtiqueta = false;
        }

        this.loading = false;
      });
    } else {
      this.checkedAll = false;
      this.marcaTodosPedidos();
      this.errorMessage = 'Somente pedidos com status "Aguardando impressão de etiqueta" podem ter as etiquetas impressas!';
      this.showErrorMessage = true;
      this.loading = false;
    }

  }

  marcaEtiquetaGerada(erros = null) {
    if (erros != null) {
      for (let i = 0; i < erros.length; i++) {
        var aux = erros[i].numPedido;
        for (let j = 0; j < this.pedidosGerarEtiquetas.length; j++) {
          if (this.pedidosGerarEtiquetas[j].id == aux) {
            this.pedidosGerarEtiquetas[j].etiquetaGerada = false;
          } else {
            this.pedidosGerarEtiquetas[j].etiquetaGerada = true;
          }
        }
      }
    } else {
      for (let j = 0; j < this.pedidosGerarEtiquetas.length; j++) {
        this.pedidosGerarEtiquetas[j].etiquetaGerada = true;
      }
    }
  }

  imprimirEtiquetas(pedido = null, withReload = true) {

    if (pedido == null && this.urlsBaixarEtiquetas.length > 0) {
      this.urlsBaixarEtiquetas.forEach((urlEtiquetas, index) => {
        window.open(urlEtiquetas, '_blank');
        if (this.urlsBaixarEtiquetas.length == (index + 1)) {
          this.urlsBaixarEtiquetas = [];
          this.loading = false;
          this.closeModalPadrao();
          if (withReload) {
            window.location.reload();
          }
        }
      });

    } else {
      let idsPacote = [];
      if (pedido == null) {
        this.getPedidosSelecionados();
        this.pedidosSelecionados.forEach(item => {
          if (item.fulfillment === false) {
            idsPacote.push(item.id);
          }
        });
        if (this.pedidosSelecionados.length == 0) {
          this.errorMessage = 'Selecione ao menos um pedido!';
          this.showErrorMessage = true;
          return;
        }
      } else {
        this.checkedAll = false;
        this.marcaTodosPedidos();
        this.pedidosSelecionados.push(pedido);
        if (pedido.fulfillment === false) {
          idsPacote.push(pedido.id);
        }
      }

      if (idsPacote.length == 0) {
        this.errorMessage = 'Selecione ao menos um pedido!';
        this.showErrorMessage = true;
        return;
      }

      var podeSeguir = this.verificaStatusCorreto("Aguardando impressão de etiqueta");
      if (podeSeguir) {
        this.loading = true;
        this.pacotesService.getEtiquetasPacotes(idsPacote).subscribe((response: any) => {
          idsPacote = [];
          if (response.mensagemErro && response.mensagemErro.length > 0) {
            var msgErro = 'Atenção! Não foi possivel gerar as etiquetas dos pedidos [';
            for (var i = 0; i < response.mensagemErro.length; i++) {
              msgErro += response.mensagemErro[i];
              if (i < response.mensagemErro.length - 1) {
                msgErro += ', ';
              }
            }
            msgErro += '].';

            this.errorMessage = msgErro;
            this.showErrorMessage = true;
          }

          if (response.retornoSucesso && response.retornoSucesso.length > 0) {
            this.urlsBaixarEtiquetas = response.retornoSucesso;
            if (this.showErrorMessage) {
              this.imprimirEtiquetas(null, false);
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } else {
              this.imprimirEtiquetas();
            }
            this.btnGerarEtiqueta = false;
          }

          this.loading = false;
        });

      } else {
        this.checkedAll = false;
        this.marcaTodosPedidos();
        this.errorMessage = 'Somente pedidos com status "Aguardando impressão de etiqueta" podem ter as etiquetas impressas!';
        this.showErrorMessage = true;
      }

    }
  }

  ConsultarEmissaoDevolucao(responseNota, pedido, numeroConsultas = 0) {
    if (numeroConsultas >= 10) {
      pedido.nfeGerada = responseNota.Status;
      this.successMessage = 'A SEFAZ esta demorando para processar a nota, por favor verifique o status mais tarde.';
      this.loading = false;
      return;
    }

    this.notaFiscalService.ConsultarEmissaoDevolucao(responseNota.Nota.id).subscribe((responseNota: any) => {
      if (responseNota.Status == "Rejeitada") {
        this.showSuccessMessage = false;
        this.errorMessage = responseNota.Mensagem;
        this.showErrorMessage = true;
        this.loading = false;
        return true;
      } else if (responseNota.Status == "Autorizada") {
        pedido.nfeGerada = responseNota.Status;
        this.loading = false;
        this.showSuccessMessage = false;
        setTimeout(() => {
          this.showSuccessMessage = false;
          window.location.reload();
        }, 3000);
      } else if (responseNota.Status == "Em Processamento") {
        this.successMessage = 'NFe de devolução esta em processamento, por favor aguarde!';
        this.showSuccessMessage = true;
        setTimeout(() => {
          numeroConsultas++;
          this.ConsultarEmissaoDevolucao(responseNota, pedido, numeroConsultas);
        }, 3000);
      }
    },
      (err) => {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
      }
    );
  }

  ConsultarEmissao(responseNota, pedidos, indexAtual, tentativas) {
    this.notaFiscalService.consultaStatusNota(responseNota.Nota.pedido).subscribe((response: any) => {
      let mensagem, status;
      switch (response.ResponstaConsultaSefaz.cStat) {
        case '100':
          status = 'Autorizada';
          mensagem = 'Autorizada o uso da NFe';
          // status = 'Em Processamento';
          // mensagem = 'Lote em processamento';
          break;
        case '105':
          status = 'Em Processamento';
          mensagem = 'Lote em processamento';
          break;
        default:
          status = 'Rejeitada';
          mensagem = response.ResponstaConsultaSefaz.xMotivo;
          break;
      }
      let repostaSefaz = { Mensagem: mensagem, Status: status, Nota: { id: responseNota.Nota.id } };
      this.checkStatus(repostaSefaz, pedidos, indexAtual, tentativas);
    },
      (err) => {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
      }
    );
  }
  emitirNfValorIntegral(idPedido) {
    this.loading = true;
    this.notaFiscalService.emiteNotaValorIntegral(idPedido).subscribe((response: any) => {
      console.log(response);
      this.loading = false;

      if (response.Status == 'Rejeitada' || response.Status == 'Erro') {
        this.errorMessage = response.Mensagem;
        this.showErrorMessage = true;
      } else if (response.Status == 'Autorizada') {
        this.successMessage = response.Mensagem;
        this.nfPedidoSelecionado.id = response.Nota.id;
        this.showSuccessMessage = true;
      }
    },
      (err) => {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
        this.showErrorMessage = true;
      }
    );
  }


  estornarNf(idPedido) {
    this.loading = true;
    this.notaFiscalService.emiteNotaEstorno(idPedido).subscribe((response: any) => {
      console.log(response);
      this.loading = false;

      if (response.Status == 'Rejeitada') {
        this.errorMessage = response.Mensagem;
        this.showErrorMessage = true;
      } else if (response.Status == 'Autorizada') {
        this.successMessage = response.Mensagem;
        this.showSuccessMessage = true;
      }
    },
      (err) => {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
        this.showErrorMessage = true;
      }
    );
  }


  buscarInfoLote(pedido) {
    this.loading = true;
    this.pacotesService.buscarInfoLote(pedido.id).subscribe((response: any) => {
      if (response == null || !response.success) {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar buscar informacoes de lote';
        this.showErrorMessage = true;
      } else {
        this.mostraInfoImpressao = true;
        this.lotesTrabalho = response.metaDados;
      }
      this.loading = false;
    },
      (err) => {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
        this.showErrorMessage = true;
      }
    );
  }

  reenviarNFMarketplace(pedido) {
    this.loading = true;
    this.pacotesService.reenviarNFMarketplace(pedido.id).subscribe((response: any) => {
      if (response == null) {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte!';
        this.showErrorMessage = true;
      } else {
        if (!response.success) {
          this.errorMessage = response.mensagemErro;
          this.showErrorMessage = true;
        } else {
          this.successMessage = 'A nota foi enviada ao marketplace.'
          this.showSuccessMessage = true;
        }
      }
      this.loading = false;
    },
      (err) => {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
        this.showErrorMessage = true;
      }
    );
  }

  // Status NFe
  consultaNf(pedido) {
    this.loading = true;
    this.pacotesService.getNotaPacote(pedido.nfe).subscribe((response: any) => {

      if (response != null) {
        this.nfPedidoSelecionado = response;
        this.loading = false;
        this.mostraNfEmitida = true;

        let dataEmissao = new Date(response.dataEmissao);
        let dataHoje = new Date();
        var diff = Math.abs(dataHoje.getTime() - dataEmissao.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        if (diffDays > 7) {
          this.mostraCancelamentoNf = false;
        } else {
          this.mostraCancelamentoNf = true;
        }

        if (diffDays > 7 && response.PossuiEstorno == false) {
          this.mostraEstornoNf = true;
        } else {
          this.mostraEstornoNf = false;
        }

      } else {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte!';
        this.showErrorMessage = true;
      }

    },
      (err) => {
        this.loading = false;
        this.errorMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
        this.showErrorMessage = true;
      }
    );
  }

  consultaStatusNf(idPacoteConsulta = null) {
    this.loading = true;
    let idPacote = null;
    if (idPacoteConsulta) {
      idPacote = idPacoteConsulta;
    } else {
      idPacote = this.nfPedidoSelecionado.id;
    }

    this.notaFiscalService.consultaStatusNota(idPacote).subscribe((response: any) => {
      if (response.Status == "Sucesso") {
        if (response.ApagadaNotaRejeitada) {
          this.errorMessage = 'Atenção, o status do pedido estava incongruente com o status da nota [Em rejeição], o status foi modificado para [Aguardando faturamento].';
          this.showErrorMessage = true;
        }
        this.statusNfMessage = response.ResponstaConsultaSefaz.xMotivo;
        this.showStatusSuccess = true;
        this.loading = false;
      } else {
        this.loading = false;
      }

    },
      (err) => {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar consultar NF. Consulte o suporte! ' + err.message;
      }
    );
  }

  fechaStatusNfMessage() {
    this.showStatusErro = false;
    this.showStatusSuccess = false;
    this.statusNfMessage = "";
  }

  baixarDanfeNf() {
    this.loading = true;

    this.notaFiscalService.baixarDanfe(this.nfPedidoSelecionado.id).subscribe((responseNota: any) => {
      if (responseNota.Status == "Sucesso")
        this.helperService.downloadPDF(responseNota['PdfBase64'], responseNota['Filename']);

      this.loading = false;

    },
      error => {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar baixar DANFE da NF. Consulte o suporte!';
      }
    );
  }

  baixarXmlNf() {
    this.loading = true;

    this.notaFiscalService.baixarXml(this.nfPedidoSelecionado.id).subscribe((responseNota: any) => {
      if (responseNota.Status == "Sucesso")
        this.helperService.downloadXMLFile(responseNota['XML'], responseNota['Filename']);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar baixar XML da NF. Consulte o suporte!';
      }
    );
  }

  submitCancelamentoNf() {
    if (this.motivoCancelamentoNf == "") {
      return;
    }
    this.loading = true;

    this.notaFiscalService.cancelaNotaDoPedido(this.nfPedidoSelecionado.id, this.motivoCancelamentoNf).subscribe((responseNota: any) => {
      console.log('responseNota: ', responseNota);
      if (responseNota.Status == 'Erro') {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar cancelar NF, verifique se a nota foi emitida em menos de 7 dias!';
      } else {
        this.showStatusSuccess = true;
        this.statusNfMessage = 'Nota cancelada com sucesso!';
        this.loading = false;
      }

    },
      error => {
        this.loading = false;
        this.showStatusErro = true;
        this.statusNfMessage = 'Erro ao tentar cancelar NF. Consulte o suporte!';
      }
    );
  }

  closeModalNf() {
    this.statusNfMessage = "";
    this.showStatusSuccess = false;
    this.showStatusErro = false;
    this.nfPedidoSelecionado = new NotaFiscal();
    this.mostraNfEmitida = false;
    this.motivoCancelamentoNf = "";
    this.mostraMotivoCancelaNf = false;
    this.mostraEstornoNf = false;
  }

  montaTextoImpressaoPedido(pacote) {
    return "Impresso " + pacote.impressao.numeroImpressoes + " vez(es) ----------------------------- Última Impressão em " + pacote.impressao.dataUltimaImpressao + " --- Primeira Impressão em " + pacote.impressao.dataPrimeiraImpressao;
  }

  abrirModalInformarIECliente(idCliente) {
    const dialogRef = this.dialog.open(DialogOverviewIECliente, {
      width: '400px',
      data: { inscricaoEstadual: this.inscricaoEstadualCliente }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.loading = true;

        this.clienteService.informaInscricaoEstadualCliente(idCliente, result).subscribe((response) => {
          this.loading = false;
          this.showStatusSuccess = true;
          this.statusNfMessage = response;
        }, (error) => {
          this.loading = false;
          this.showStatusErro = true;
          this.statusNfMessage = 'Erro ao tentar alterar a inscrição estadual do cliente. Consulte o suporte!';
        });
      }
    });
  }

  reimprimirEtiqueta(idPacote = null) {
    this.loading = true;
    let idsPacotes = '';

    if (idPacote == null) {
      this.getPedidosSelecionados();
      if (this.pedidosSelecionados.length == 0) {
        this.errorMessage = "Selecione ao menos um pedido!";
        this.showErrorMessage = true;
        this.loading = false;
        return;
      }
      else {
        var possuiStatusAguardandoPostagem = this.verificaStatusCorreto("Aguardando postagem");
        var possuiStatusEmTransito = this.verificaStatusCorreto("Em trânsito");
        if (possuiStatusAguardandoPostagem || possuiStatusEmTransito) {
          this.getIDsPacotesSelecionados().subscribe((idsPacotesString) => {
            idsPacotes = idsPacotesString;
          });
        }
        else {
          this.errorMessage = "Os pedidos devem ter status de Aguardando Postagem ou Em trânsito!";
          this.showErrorMessage = true;
          this.loading = false;
          return;
        }
      }
    }
    else {
      idsPacotes = idPacote.toString();
    }

    this.pacotesService.reimprimirEtiquetas(idsPacotes).subscribe((responseEtiquetas) => {
      if (responseEtiquetas.success && responseEtiquetas.retornoSucesso.length > 0) {
        this.urlsBaixarEtiquetas = responseEtiquetas.retornoSucesso;
        this.imprimirEtiquetas(null, false);
      }
      if (responseEtiquetas.mensagemErro != "" && responseEtiquetas.mensagemErro != null && responseEtiquetas.mensagemErro != undefined) {
        this.errorMessage = responseEtiquetas.mensagemErro;
        this.showErrorMessage = true;
      }

      this.loading = false;
    }, error => {
      console.error(error);
      this.errorMessage = "Erro ao tentar reimprimir etiquetas.";
      this.showErrorMessage = true;
      this.loading = false;
    });
  }

  private getIDsPacotesSelecionados(): Observable<string> {
    return new Observable((observable) => {
      let listaIds = '';
      this.pedidosSelecionados.forEach((pedido, index) => {
        if (index + 1 == this.pedidosSelecionados.length) {
          listaIds += pedido.id + "";
          observable.next(listaIds);
        }
        else {
          listaIds += pedido.id + ","
        }
      });
    });
  }

  public faturarEGerarImpressaoZebra() {
    let idsPacotes = [];
    this.loading = true;
    if (this.pedidosGerarEtiquetas.length == 0) {
      this.getPedidosSelecionados();
      this.pedidosSelecionados.forEach(item => {
        idsPacotes.push(item.id);
      });
    }

    let podeSeguir: boolean;
    if (idsPacotes.length == 0 && this.pedidosGerarEtiquetas != null && this.pedidosGerarEtiquetas != []) {
      this.pedidosGerarEtiquetas.forEach(element => {
        idsPacotes.push(element.id);
      });
      podeSeguir = this.verificaStatusCorreto("Aguardando impressão de etiqueta", this.pedidosGerarEtiquetas);
    } else {
      podeSeguir = this.verificaStatusCorreto("Aguardando impressão de etiqueta");
    }

    if (podeSeguir == false) {
      return;
    }

    this.imprimirDanfeEtiquetas(idsPacotes);

  }

  public imprimirDanfeEtiquetas(pacotesIds = null) {
    if (this.loading == false) {
      this.loading = true;
    }
    //primeiro pego os selecionados
    // let pacotesSelecionados = this.pacotes.filter((pacote: Pacote) => pacote.checked);
    if (pacotesIds == null || pacotesIds.length == 0) {
      pacotesIds = [];
      this.getPedidosSelecionados();
      this.pedidosSelecionados.forEach(item => {
        pacotesIds.push(item.id);
      });
    }

    if (pacotesIds == null || pacotesIds.length == 0) {
      this.loading = false;
      this.errorMessage = "Selecione ao menos um pedido!";
      this.showErrorMessage = true;
      return;
    }

    console.log('pacotesIds: ', pacotesIds);

    this.pacotesService.gerarDanfeEEtiqueta(pacotesIds).subscribe((response: any) => {
      if (response.pacotesEtiquetasNaoGeradas.length > 0) {
        let mensagemErro = 'Os seguintes pacotes nao puderam ter suas impressoes geradas (verifique se o status da venda no marketplace esta correto): ';
        for (var i = 0; i < response.pacotesEtiquetasNaoGeradas.length; i++) {
          mensagemErro += response.pacotesEtiquetasNaoGeradas[i];
          if (i < response.pacotesEtiquetasNaoGeradas.length - 1) {
            mensagemErro += ', ';
          }
        }

        this.errorMessage = mensagemErro;
        this.showErrorMessage = true;
      } else {
        this.successMessage = 'Todas as solicitacoes foram enviadas ao serviço de impressao.';
        this.showSuccessMessage = true;
        this.closeModalPadrao();
      }
      this.loading = false;

    },
      (err) => {
        console.log(err);
        this.loading = false;
        this.errorMessage = 'Erro ao tentar gerar Danfe e etiqueta.';
        this.showErrorMessage = true;
      }
    );
  }

  // public enviarSolicitacaoImpressao(requestReport) {
  //   this.pacotesService.solicitarImpressaoDanfeEtiqueta(requestReport).subscribe((response: any) => {
  //     this.loading = false;

  //     this.successMessage = "A solicitacao de impressao foi realizada com sucesso."
  //     this.showSuccessMessage = true;
  //     setTimeout(() => {
  //       this.showSuccessMessage = false;
  //     }, 3000);
  //   },
  //     (err) => {
  //       console.log(err);
  //       this.errorMessage = 'Erro ao enviar solicitacao para serviço de impressão.';
  //       this.showErrorMessage = true;
  //     }
  //   );
  // }
}

@Component({
  selector: 'popup-inscricao-estadual',
  templateUrl: 'popup-inscricao-estadual.html',
})
export class DialogOverviewIECliente {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewIECliente>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onIsento(data): void {
    data = 'ISENTO'
    this.dialogRef.close(data);
  }

}
