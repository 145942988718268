<div class="mat-elevation-z8 boxContainer" id="containerTodo">

  <!-- FILTROS -->
  <ng-container>
    <form name="filtros" (ngSubmit)="buscaComFiltros()" #f="ngForm" novalidate class="filtros">
      <mat-card-content class="boxInputs" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-form-field fxLayout="column" appearance="outline" fxFlex="35">
          <mat-label>Id, Nome, Documento, Marketplace</mat-label>
          <input matInput type="text" class="form-control" name="pesquisaGeral" [(ngModel)]="filtros.pesquisaGeral"
            minlength="3" #pesquisaGeral="ngModel">
        </mat-form-field>

        <mat-form-field fxFlex="20">
          <mat-label>Situação</mat-label>
          <mat-select [(selected)]="filtros.situacao" [(ngModel)]="filtros.situacao" required #situacao="ngModel"
            name="situacao">
            <mat-option *ngFor="let situacao of situacoes" [value]="situacao.value">
              {{situacao.nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="20">
          <mat-label>Logistica</mat-label>
          <mat-select [(selected)]="filtros.entrega" [(ngModel)]="filtros.entrega" required #situacao="ngModel"
            name="entrega">
            <mat-option *ngFor="let situacao of entregas" [value]="situacao.value">
              {{situacao.nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxLayout="column" fxFlex="20">
          <input matInput [matDatepicker]="dataInicio" placeholder="Data Inicial" name="C"
            [(ngModel)]="filtros.periodo[0]" required #dataInicio="ngModel">
          <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
          <mat-datepicker #dataInicio></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxLayout="column" fxFlex="20">
          <input matInput [matDatepicker]="dataFim" placeholder="Data Final" name="dataFim"
            [(ngModel)]="filtros.periodo[1]" required #dataFim="ngModel">
          <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
          <mat-datepicker #dataFim></mat-datepicker>
        </mat-form-field>
      </mat-card-content>

      <button mat-stroked-button class="btn-block btnPadrao">Filtrar</button>
    </form>
  </ng-container>

  <!-- Listagem -->
  <table class="table table-borderless">
    <thead>
      <tr class="tableHeader">
        <th>Id</th>
        <th>Loja</th>
        <th>Cliente</th>
        <th>Mkp</th>
        <th>Custo</th>
        <th>Produtos</th>
        <th>Frete</th>
        <th>Com. Heimber</th>
        <th>Com. Mkp</th>
        <th>Desconto</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody *ngFor="let pedido of pacotes" class="boxPedido" [ngClass]="{'pedidoCancelado': pedido.cancelado === true}">
      <tr>
        <td><a [routerLink]="['/pedidos/detalhe']" [queryParams]="{ pedido: pedido.pacoteId }" class="pedidoId">{{
            pedido.pacoteId }}</a></td>
        <td title="Loja">{{ pedido.pacoteLoja.fantasia }}</td>
        <td title="Cliente">{{ pedido.cliente.nome }}</td>
        <td title="Marketplace">{{ pedido.fulfillment ? pedido.pedidoMarketplace.nome + ' - Fulfillment' :
          pedido.pedidoMarketplace.nome }}</td>
        <td title="Custo">{{ pedido.custoTotal.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' }) }}</td>
        <td title="Produtos">{{ pedido.valorProdutos.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td title="Frete">{{ pedido.valorFrete.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}</td>
        <td title="Comissão Heimber">
          {{ pedido.valorComissaoHeimber.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}</td>
        <td title="Comissão Marketplace">
          {{ pedido.valorComissaoMarketplace.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}</td>
        <td title="Desconto">{{ pedido.valorDesconto.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td title="Total">{{ pedido.valorTotal.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}</td>
      </tr>
      <tr>
        <td></td>
        <td style="padding: 0 .75rem;">
          <div style="overflow-x: hidden;">
            <img *ngFor="let imagem of pedido.produtos" src="{{ imagem.thumbnail }}" class="imgProduct">
          </div>
        </td>
        <td>
          <p class="nomeProdutoLista" *ngFor="let sku of pedido.produtos">{{ sku.skuSinonimo ? '[' + sku.sku + '] ' +
            sku.skuSinonimo : sku.sku}}</p>
        </td>
        <td><b>Calculados:</b></td>
        <td title="Custo">{{ pedido.custoTotal.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' }) }}</td>
        <td title="Produtos">
          {{ pedido.valorProdutosEstimado.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}</td>
        <td title="Frete">{{ pedido.valorFreteEstimado.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td title="Comissão Heimber">
          {{ pedido.valorComissaoHeimberEstimado.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}</td>
        <td title="Comissão Marketplace">
          {{ pedido.valorComissaoMarketplaceEstimado.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td>

        </td>
        <td title="Total">{{ pedido.valorTotalEstimado.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
      </tr>
      <tr>
        <td></td>
        <td><b>{{pedido.cancelado ? "Valor Perdido: " : ""}}</b></td>
        <td>{{pedido.cancelado ? (pedido.valorPerdido == null ? 0: pedido.valorPerdido.toLocaleString('br-BR', { style:
          'currency', currency: 'BRL' })) : "" }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>

    <tbody class="boxPedidoTotais">
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td><b>Totais Página Efetivados: </b></td>
        <td title="Custo">{{ totalPorPagina.efetivo.custo.toLocaleString('br-BR', { style: 'currency', currency: 'BRL'
          }) }}</td>
        <td title="Produtos">{{ totalPorPagina.efetivo.produtos.toLocaleString('br-BR', { style: 'currency', currency:
          'BRL' })}}</td>
        <td title="Frete">{{ totalPorPagina.efetivo.frete.toLocaleString('br-BR', { style: 'currency', currency: 'BRL'
          })}}</td>
        <td title="Comissão Heimber">{{ totalPorPagina.efetivo.comissaoHeimber.toLocaleString('br-BR', { style:
          'currency', currency: 'BRL' })}}</td>
        <td title="Comissão Marketplace">{{ totalPorPagina.efetivo.comissaoMarketplace.toLocaleString('br-BR', { style:
          'currency', currency: 'BRL' })}}</td>
        <td></td>
        <td title="Total">{{ totalPorPagina.efetivo.total.toLocaleString('br-BR', { style: 'currency', currency: 'BRL'
          })}}
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td style="border-bottom: outset;"><b>Totais Página Calculados: </b></td>
        <td style="border-bottom: outset;" title="Custo">{{ totalPorPagina.calculado.custo.toLocaleString('br-BR', {
          style: 'currency', currency: 'BRL' }) }}</td>
        <td style="border-bottom: outset;" title="Produtos">{{ totalPorPagina.calculado.produtos.toLocaleString('br-BR',
          { style: 'currency', currency: 'BRL' })}}</td>
        <td style="border-bottom: outset;" title="Frete">{{ totalPorPagina.calculado.frete.toLocaleString('br-BR', {
          style: 'currency', currency: 'BRL' })}}</td>
        <td style="border-bottom: outset;" title="Comissão Heimber">{{
          totalPorPagina.calculado.comissaoHeimber.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td style="border-bottom: outset;" title="Comissão Marketplace">{{
          totalPorPagina.calculado.comissaoMarketplace.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td style="border-bottom: outset;"></td>
        <td style="border-bottom: outset;" title="Total">{{ totalPorPagina.calculado.total.toLocaleString('br-BR', {
          style: 'currency', currency: 'BRL' })}}</td>
      </tr>
    </tbody>
    <tbody class="boxPedidoTotais">
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td><b>Totais Gerais Efetivados: </b></td>
        <td title="Custo">{{ totalGeral.efetivo.custo.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' }) }}
        </td>
        <td title="Produtos">{{ totalGeral.efetivo.produtos.toLocaleString('br-BR', { style: 'currency', currency: 'BRL'
          })}}</td>
        <td title="Frete">{{ totalGeral.efetivo.frete.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
        <td title="Comissão Heimber">{{ totalGeral.efetivo.comissaoHeimber.toLocaleString('br-BR', { style: 'currency',
          currency: 'BRL' })}}</td>
        <td title="Comissão Marketplace">{{ totalGeral.efetivo.comissaoMarketplace.toLocaleString('br-BR', { style:
          'currency', currency: 'BRL' })}}</td>
        <td></td>
        <td title="Total">{{ totalGeral.efetivo.total.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })}}
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td><b>Totais Gerais Calculados: </b></td>
        <td title="Custo">{{ totalGeral.calculado.custo.toLocaleString('br-BR', { style: 'currency', currency: 'BRL' })
          }}</td>
        <td title="Produtos">{{ totalGeral.calculado.produtos.toLocaleString('br-BR', { style: 'currency', currency:
          'BRL' })}}</td>
        <td title="Frete">{{ totalGeral.calculado.frete.toLocaleString('br-BR', { style: 'currency', currency: 'BRL'
          })}}</td>
        <td title="Comissão Heimber">{{ totalGeral.calculado.comissaoHeimber.toLocaleString('br-BR', { style:
          'currency', currency: 'BRL' })}}</td>
        <td title="Comissão Marketplace">{{ totalGeral.calculado.comissaoMarketplace.toLocaleString('br-BR', { style:
          'currency', currency: 'BRL' })}}</td>
        <td></td>
        <td title="Total">{{ totalGeral.calculado.total.toLocaleString('br-BR', { style: 'currency', currency: 'BRL'
          })}}</td>

      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="paginator">
      <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
        (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPedidosPage($event)">
      </app-pagination>
    </div>
  </div>


</div>
<div class="loading" *ngIf="loading">
  <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
</div>