import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { ConfigMasterEnvironment } from 'src/environments/config.master.environment';
import { NotaFiscal, SituacaoNota } from 'src/app/models/nota-fiscal';
import { DefaultReturn } from 'src/app/models/default-return';

@Injectable({
  providedIn: 'root'
})
export class NotaFiscalService {
  public apiHost: string = ConfigMasterEnvironment.environment.baseUrlApi;
  private endpointPedidoNota: string = 'pedidonota';
  private endpointEmitir: string = 'emitir';
  private endpointDevolucao: string = 'devolucao';
  private endpointEstorno: string = 'estorno';
  private endpointEmitirValorIntegral: string = 'emitirValorIntegral';
  private endpointCancelar: string = 'cancelar';
  private endpointConsultaStatus: string = 'consultastatus';
  private endpointConsultaNFe: string = 'ConsultarEmissao';
  private endpointConsultaNFeDevolucao: string = 'ConsultarEmissaoDevolucao';
  private endpointBaixarXml: string = 'baixarxml';
  private endpointBaixarDanfe: string = 'baixardanfe';

  constructor(
    private http: HttpClient
  ) { }


  public getProximoNumeroNota(dadosEmissao: any): Observable<any> {
    return new Observable((observable) => {
      this.http.post(this.apiHost + this.endpointPedidoNota + '/getnumero', dadosEmissao).subscribe((response: DefaultReturn) => {
        observable.next(response);
      },
        (error: any) => {
          console.log(error);
        });
    });
  }

  public emiteNotaDoPedido(nota: NotaFiscal, idPacote: string): Observable<string> {
    return new Observable((observable) => {
      this.http.post(this.apiHost + this.endpointPedidoNota + '/' + idPacote + '/' + this.endpointEmitir, nota).subscribe((response: string) => {

        observable.next(response);

      },
        (error: any) => {
          observable.error(error);
        });
    });
  }


  public consultarNota(idPacote: string): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idPacote + '/' + this.endpointConsultaNFe).subscribe((response: any) => {
        observable.next(response);
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public emiteNotaDevolucaoPedido(nota: NotaFiscal, idPacote: string): Observable<string> {
    return new Observable((observable) => {
      this.http.post(this.apiHost + this.endpointPedidoNota + '/' + idPacote + '/' + this.endpointDevolucao, nota).subscribe((response: string) => {
        observable.next(response);
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public emiteNotaEstorno(idPacote: string): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idPacote + '/' + this.endpointEstorno).subscribe((response: string) => {
        observable.next(response);
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public emiteNotaValorIntegral(idPacote: string): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idPacote + '/' + this.endpointEmitirValorIntegral).subscribe((response: string) => {
        observable.next(response);
      },
        (error: any) => {
          observable.error(error);
        });
    });
  }

  public adicionaNotaAoPedido(nota: NotaFiscal): Observable<string> {
    return new Observable((observable) => {
      this.http.post(this.apiHost + this.endpointPedidoNota, nota).subscribe((response: string) => {

        observable.next(response);

      });
    });
  }

  public cancelaNotaDoPedido(idNota: number, motivoCancelamento: string): Observable<string> {
    let justificativaCancelamento = {
      'justificativa': motivoCancelamento
    }
    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointPedidoNota + '/' + idNota + '/' + this.endpointCancelar, justificativaCancelamento).subscribe((response: string) => {

        observable.next(response);

      },
        (error: any) => {
          console.log(error);
        });
    });
  }

  public ConsultarEmissaoDevolucao(idNota: number): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idNota + '/' + this.endpointConsultaNFeDevolucao).subscribe((response: string) => {
        observable.next(response);
      },
        (error: any) => {
          console.log(error);
        });
    });
  }

  public ConsultarEmissao(idNota: number): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idNota + '/' + this.endpointConsultaNFe).subscribe((response: string) => {
        observable.next(response);
      },
        (error: any) => {
          console.log(error);
        });
    });
  }

  public consultaStatusNota(idNota: number): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idNota + '/' + this.endpointConsultaStatus).subscribe((response: string) => {

        observable.next(response);

      },
        (error: any) => {
          console.log(error);
        });
    });
  }

  public baixarDanfe(idNota: number): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idNota + '/' + this.endpointBaixarDanfe).subscribe((response: string) => {

        observable.next(response);

      },
        (error: any) => {
          console.log(error);
        });
    });
  }

  public baixarXml(idNota: number): Observable<string> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointPedidoNota + '/' + idNota + '/' + this.endpointBaixarXml).subscribe((response: string) => {

        observable.next(response);

      },
        (error: any) => {
          console.log(error);
        });
    });
  }


}

