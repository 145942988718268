<nav aria-label="page navigation">
    <div class="qntPorPageBox">
        <label for="recordsPerPage">Por Pag: </label>
        <input type="text" class="form-control qntPorPage" placeholder="Qnt. Por Página" name="recordsPerPage"
            [(value)]="recordsPerPage" (change)="recordsPerPageChanged($event.target.value)">
    </div>
    <div class="paginationBox">
        <ul class="pagination">
            <li class="page-item" (click)="onClickPage(activePage - 1)">
                <a class="page-link" href="javascript:void(0);">Anterior</a>
            </li>
            <li id="{{item}}" class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"
                (click)="onClickPage(item)">
                <a class="page-link" href="javascript:void(0);">{{item}}</a>
            </li>
            <li class="page-item" (click)="onClickPage(activePage + 1)">
                <a class="page-link" href="javascript:void(0);">Próxima</a>
            </li>
        </ul>
    </div>
    <div class="qntMostrada">
        <p>{{ from }} - {{ to }} de {{ totalRecords }}</p>
    </div>
</nav>
