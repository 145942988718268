import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-politica-privacidade-termos',
  templateUrl: './politica-privacidade-termos.component.html',
  styleUrls: ['./politica-privacidade-termos.component.scss']
})
export class PoliticaPrivacidadeTermosComponent implements OnInit {

  constructor(protected router: Router) { }

  ngOnInit() {
  }

}
