<div style="height: 100vh;" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="boxContainer">
        <mat-card-header>
            <mat-card-title class="tituloResetarSenha">Resetar a senha</mat-card-title>
        </mat-card-header>
        <mat-card-header>
            <mat-card-title class="subTituloResetarSenha">Insira seu email e enviaremos um link para resetar sua senha.</mat-card-title>
        </mat-card-header>

        <form fxLayout="column" fxLayoutAlign="space-around center" name="form" (ngSubmit)="resetPassword()" #f="ngForm"
            novalidate>
            <mat-card-content class="boxInputs">
                <mat-form-field fxLayout="column" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="email@exemplo.com" type="text" class="form-control" name="emailToReset"
                        [(ngModel)]="form.emailToReset" required #emailToReset="ngModel">
                </mat-form-field>

            </mat-card-content>

            <mat-container *ngIf="achouEmail && mensagemRetorno != ''"
                class="alert alert-success alert-dismissible fade show">
                <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </mat-container>

            <mat-container *ngIf="!achouEmail && mensagemRetorno != ''"
                class="alert alert-danger alert-dismissible fade show">
                <mat-card-content>{{ mensagemRetorno }}</mat-card-content>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </mat-container>

            <button mat-stroked-button class="btn-block btnPadrao" *ngIf="!achouEmail">Enviar email</button>
        </form>

    </mat-card>

</div>