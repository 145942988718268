import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { SACPreSaleQuestionDTO, SACPostSaleQuestionHeaderDTO, SACPostSaleQuestionMessageDTO } from '../../models/question-sac.model';
import { ConfigMasterEnvironment } from '../../../environments/config.master.environment';
@Injectable({
  providedIn: 'root'
})
export class SacService {

  public apiHost: string = ConfigMasterEnvironment.environment.baseUrlApi;
  private endpointSAC: string = 'sac/';

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe
  ) {
  }

  public RefreshConversation(headerId): Observable<SACPostSaleQuestionMessageDTO> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointSAC + 'RefreshConversations/' + headerId).subscribe((response: any) => {
        observable.next(response);
      });
    });
  }

  public DeletePreSaleQuestion(questionId): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointSAC + 'DeletePreSaleQuestion?questionId=' + questionId).subscribe((response: any) => {
        observable.next(response);
      });
    });
  }

  public AnswerQuestion(questionId, response): Observable<boolean> {
    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointSAC + 'AnswerQuestion/' + questionId, response).subscribe((response: any) => {
        observable.next(response);
      });
    });
  }

  public AnswerPostSaleQuestion(questionId, response): Observable<boolean> {
    return new Observable((observable) => {
      this.http.put(this.apiHost + this.endpointSAC + 'AnswerPostSaleQuestion/' + questionId, response).subscribe((response: any) => {
        observable.next(response);
      });
    });
  }
  public getMarketplacesSac(): Observable<any> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointSAC + 'GetMarkteplacesSac').subscribe((response: any) => {
        observable.next(response);
      });
    });
  }
  public buildFiltersQueryString(estado, sku, dataInicial, dataFinal, marketplace, ordem, qntPorPagina, activePage) {
    let queryString = '?';
    if (estado) {
      queryString += 'estado=' + estado + '&';
    }
    if (sku) {
      queryString += 'sku=' + sku + '&';
    }
    if (dataInicial) {
      queryString += 'dataInicial=' + this.datepipe.transform(dataInicial, 'yyyy/MM/dd') + '&';
    }
    if (dataFinal) {
      queryString += 'dataFinal=' + this.datepipe.transform(dataFinal, 'yyyy/MM/dd') + '&';
    }
    if (marketplace) {
      queryString += 'marketplace=' + marketplace + '&';
    }
    if (ordem) {
      queryString += 'ordem=' + ordem + '&';
    }

    queryString += 'limit=' + qntPorPagina + '&';
    queryString += 'actualPage=' + activePage;
    return queryString;
  }
  public getPostSaleQuestion(queryString): Observable<SACPostSaleQuestionHeaderDTO> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointSAC + 'GetPostSaleQuestions' + queryString).subscribe((response: SACPostSaleQuestionHeaderDTO) => {
        observable.next(response);
      });
    });
  }
  public getPreSaleQuestion(queryString): Observable<SACPreSaleQuestionDTO> {
    return new Observable((observable) => {
      this.http.get(this.apiHost + this.endpointSAC + 'GetPreSaleQuestions' + queryString).subscribe((response: SACPreSaleQuestionDTO) => {
        observable.next(response);
      });
    });
  }
}
