export class Produto {

  public id: number;
  public sku: string;
  public skuSinonimo: string;
  public imagens: string[];
  public titulo: string;
  public categoria: string;
  public referencia: string;
  public descricao: string;
  public estoque: number;
  public precoBase: number;
  public peso: number;
  public ean: number;
  public ncm: number;

  public editado: boolean = false;

  public idLoja: number;
  public idCategoria: number;
  public custo: number;
  public largura: number;
  public altura: number;
  public comprimento: number;
  public composto: number;
  public idSituacao: number;
  public idTributacaoIcms: number;
  public cfop: number;
  public cest: string;
  public unidadeComercial: string;
  public tempoProducao: number;

}

export class ProdutoPorSku {

  public id: number;
  public sku: string;
  public skuSinonimo: string;
  public imagens: Array<string>;
  public titulo: string;
  public categoria: string;
  public referencia: string;
  public descricao: string;
  public estoque: number;
  public precos: Preco;
  public peso: number;
  public ean: number;
  public ncm: number;

  public idLoja: number;
  public idCategoria: number;
  public largura: number;
  public altura: number;
  public comprimento: number;
  public composto: number;
  public idSituacao: number;
  public idTributacaoIcms: number;
  public cfop: number;
  public cest: string;
  public unidadeComercial: string;
  public tempoProducao: number;

  public atributos: Array<any>[];
  public precosMarketplaces: Array<PrecoMkp>[];
  public sinonimos: Array<ProdutoSinonimo>;

}

export class Preco {
  public custo: number;
  public precoBase: number;
  public precoBaseDolar: number;
}

export class PrecoMkp {
  public idMarketplace: number;
  public precoBase: number;
  public precoFinal: number;
}

export class ProdutoSinonimo {
  public ean: number;
  public id: number;
  public precoBase: number;
  public precosMarketplaces: Array<PrecoMkp>[];
  public sku: string;
  public titulo: string;

}
