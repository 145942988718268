import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { format } from 'url';
import { ConfigMasterEnvironment } from './../../environments/config.master.environment';

const AUTH_API = ConfigMasterEnvironment.environment.baseUrlApi;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(credentials): Observable<any> {
    return this.http.post(AUTH_API + 'login', {
      email: credentials.email,
      password: credentials.password
    }, httpOptions);
  }

  register(user): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    }, httpOptions);
  }

  resetPassword(email): Observable<any> {
    return this.http.post(AUTH_API + 'request-pass', {
      email: email
    }, httpOptions);
  }

  verificaCodigo(codigos): Observable<any> {
    return this.http.post(AUTH_API + 'check-Code', {
      code: codigos.codigo,
      data: codigos.emailCripto
    }, httpOptions);
  }

  salvaNovaSenha(codigos): Observable<any> {
    return this.http.post(AUTH_API + 'save-Password', {
      code: codigos.codigo,
      criptoMail: codigos.emailCripto,
      newPassword: codigos.novaSenha
    }, httpOptions);
  }

  pegaListagemdDashboardPedidos (): Observable<any> {
    return this.http.get(AUTH_API + 'pacote?limite=' + '10' +  'created_at[start]=' + '2020/07/24' + '&created_at[end]=' + '2020/08/24',
     httpOptions);
  }

}