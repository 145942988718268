<div class="mat-elevation-z8 boxContainer" id="containerTodo">

    <!-- Filtros Listagem -->
    <ng-container>
        <form name="filtros" class="filtros">
            <mat-card-content class="boxInputs" fxLayout="row">
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>Sku</mat-label>
                    <input matInput type="text" class="form-control" name="pesquisa" [(ngModel)]="sku" minlength="3"
                        #pesquisa="ngModel">
                </mat-form-field>
                <mat-form-field style='padding-left: 10px;width: 350px;'>
                    <mat-select [(selected)]="filtro" [(ngModel)]="filtro" required #codigoCancelamento="ngModel"
                        name="cancelamento" class="mdb-select md-form colorful-select dropdown-danger">
                        <mat-option *ngFor="let item of filtrosDisponiveis" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-stroked-button class="btn-block btnPadrao"
                    (click)="getProdutosCategorias()">Filtrar</button>
                <!-- <button mat-stroked-button class="btn-block btnPadrao" style="background-color: #426481;"
                    (click)="salvar()">Salvar</button> -->
            </mat-card-content>
        </form>


    </ng-container>
    <!-- Final filtros -->

    <!-- Listagem -->
    <mat-card *ngFor="let produtoCategoria of produtosCategorias" class="boxPedido">

        <mat-card-content>
            <table class="table">
                <thead>
                    <tr style="text-align: center;" class="tableHeader">
                        <th>Nome</th>
                        <th>SKU</th>
                        <th>Imagem</th>
                        <th>Categorias</th>
                        <th></th>
                    </tr>
                </thead>
                <td data-toggle="tooltip" data-placement="top" title="Nome">{{ produtoCategoria.Nome }}</td>
                <td data-toggle="tooltip" data-placement="top" title="SKU">{{ produtoCategoria.Sku }}</td>
                <td data-toggle="tooltip" data-placement="top" title="Imagem">
                    <img src="{{'https://apiv1.webermaq.com/imagens/' + produtoCategoria.UrlImagem }}"
                        class="imgProduct">
                </td>
                <td data-toggle="tooltip" data-placement="top" title="Categorias" style="text-align: center;">
                    <ul style="list-style: none;">
                        <li>
                            <div style="float: left;">
                                Categoria sugerida:
                            </div>
                            {{produtoCategoria.CategoriaSugerida}}
                        </li>
                        <li>
                            <div style="float: left;">
                                Categoria atual:
                            </div>
                            <a style=" cursor: pointer;"
                                (click)="abreModalCategoria(produtoCategoria)">{{produtoCategoria.CategoriaAtual }}</a>
                        </li>
                    </ul>
                </td>
                <td style="border-top: none" data-toggle="tooltip" data-placement="top">
                    <button
                        [disabled]="produtoCategoria.CategoriaSugeridaId == null || (produtoCategoria.CategoriaSugeridaId == produtoCategoria.CategoriaAtualId)"
                        title="Aplicar categoria sugerida" mat-icon-button (click)="aplicarCategoria(produtoCategoria)">
                        <mat-icon>autorenew</mat-icon>
                    </button>
                    <button [disabled]="produtoCategoria.CategoriaAtualId == null" title="Salvar" mat-icon-button
                        (click)="salvaCategoria(produtoCategoria)">
                        <mat-icon>save</mat-icon>
                    </button>
                </td>
            </table>
        </mat-card-content>
    </mat-card>

    <!-- Paginator -->
    <div class="row">
        <div class="paginator">
            <app-pagination [totalRecords]="qntTotalRegistros" [recordsPerPage]="qntPorPagina" [from]="from" [to]="to"
                (onPageChange)="displayActivePage($event)" (recordsPerPageChange)="displayPage($event)">
            </app-pagination>
        </div>
    </div>


    <!-- Loading Spinner -->
    <div class="loading" *ngIf="loading">
        <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
    </div>

</div>

<mat-container *ngIf="modalCategoria" class="boxModal">
    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;"></div>
    <button mat-icon-button (click)="fechaModalCategoria()" class="buttonCloseModal">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <div class="modalPadrao">
        <div style="margin-top: 25px;" class="corpoModalPadrao" id="idCorpoModal">
            <div *ngIf="msgBuscandoCategorias == false">

                <mat-card *ngFor="let categoriaMl of categoriasMl;let indexOfelement=index;">
                    <div *ngIf="indexOfelement == 0" class="headerModalPadrao"
                        style="text-align: center; padding-top: 10px;">
                        <h2 mat-dialog-title>{{categoriaMl.NomeCategoriaPai}}</h2>
                    </div>
                    <mat-card-content>
                        <ul style="list-style: none; display: inline-flex;width: 100%;">
                            <li *ngIf="indexOfelement == 0">
                                <button [disabled]="categoriaMl.PossuiAnterior == false" title="Anterior"
                                    mat-icon-button (click)="buscarCategorias(categoriaMl, false)">
                                    <mat-icon>navigate_before</mat-icon>
                                </button>
                            </li>
                            <li style="margin-left: 40px;" *ngIf="indexOfelement > 0">

                            </li>
                            <li *ngIf="categoriaMl.PossuiProximo == true"
                                style="text-align: center; padding-top: 10px;">
                                {{categoriaMl.Nome}}
                            </li>
                            <li *ngIf="categoriaMl.PossuiProximo == false"
                                style="text-align: center; padding-top: 10px;">
                                <a style="cursor: pointer;" (click)="ajustarCategoriaTexto(categoriaMl, true, true)">
                                    {{categoriaMl.Nome}}</a>
                            </li>
                            <li>
                                <button [disabled]="categoriaMl.PossuiProximo == false" title="Próximo" mat-icon-button
                                    (click)="buscarCategorias(categoriaMl, true)">
                                    <mat-icon>navigate_next</mat-icon>
                                </button>
                            </li>
                        </ul>
                    </mat-card-content>
                </mat-card>
            </div>
            <div style="height: 100%;" *ngIf="msgBuscandoCategorias">
                <mat-card>
                    <div class="loadingModalCategoria">
                        <mat-spinner class="loadingSpinner" mode="indeterminate"></mat-spinner>
                    </div>
                </mat-card>
            </div>

        </div>
        <mat-form-field style="margin-left: 20px;width: 75%;font-size: 10px;" appearance="outline">
            <mat-label>Aplicar categoria por texto</mat-label>
            <input [disabled]="msgBuscandoCategorias" matInput type="text" class="form-control"
                [(ngModel)]="categoriaTexto" minlength="15">
        </mat-form-field>
        <button title="OK" mat-icon-button (click)="buscarCategoriaPorTexto()">
            <mat-icon style="font-size: 18px;">check icon</mat-icon>
        </button>
    </div>
</mat-container>


<!-- Mensagem Erro -->
<mat-container style="z-index: 99999999;" *ngIf="showErrorMessage"
    class="alert alert-danger alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ errorMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemErro()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>

<!-- Mensagem Sucesso -->
<mat-container style="z-index: 99999999;" *ngIf="showSuccessMessage"
    class="alert alert-success alert-dismissible fade show mensagemPadrao">
    <mat-card-content>{{ successMessage }}</mat-card-content>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fechaMensagemSuccess()">
        <span aria-hidden="true">&times;</span>
    </button>
</mat-container>